/**
 * Loadest - функция отображения всплывающего сообщения об успешной операции.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";

const showSuccess = (text:string) => {
    document["handler"].log("showSuccess()");
    try {
        if (document["reduxStore"].getState().main.addNotification) {
            document["reduxStore"].getState().main.addNotification({
                title: <span data-notify="icon" className="pe-7s-info"/>,
                message: <div> {text} </div>,
                level: "success",
                position: "tr",
                autoDismiss: 5
            });
        } else {
            window.alert(text);
        }
    }catch(e){
        document["handler"].error("showSuccess() -> "+e.message);
        window.alert(text);
    }
}

export default showSuccess;