/**
 * Loadest - TestingsTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {
    Grid,
    Row,
    Col,
    Table,
    NavDropdown,
    MenuItem
} from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import html2canvas from "html2canvas";

import PlayArrowOutlined from '@material-ui/icons/PlayArrowOutlined';
import PauseOutlined from '@material-ui/icons/PauseOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import ShareOutlined from '@material-ui/icons/ShareOutlined';

import { Card } from "../Card/Card";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ITestingsTableProps, ITestingsTableState } from "./TestingsTable.props";
import "./TestingsTable.css";
import { ERROR_CODE, TASK_STATE } from "../../constants";
import Button from "../Button/Button";
import showSuccess from "../../functions/showSuccess";
import showError from "../../functions/showError";
import intToTime from "../../functions/intToTime";
// @ts-ignore
import spinner from "../../assets/img/load.gif";

class TestingsTableComponent extends React.Component<ITestingsTableProps, ITestingsTableState>{
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("TestingsTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            details: "Details",
            info: "Info",
            label: "Label",
            host: "Host",
            status: "Status",
            date: "Started",
            duration: "Duration",
            action: "Action"
        };
        this.state = {
            details: [],
            results: [],
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    expandResult(id) {
        document["handler"].log(`TestingsTableComponent.expandResult(${id})`);
        if (this.state.results.indexOf(id) < 0) {
            let list = this.state.results;
            list.push(id);
            this.setState({
                results: list,
            })
        } else {
            let list = [];
            this.state.results.forEach(item => {
                if (item !== id) {
                    list.push(item);
                }
            });
            this.setState({
                results: list,
            })
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    expandDetails(id) {
        document["handler"].log(`TestingsTableComponent.expandDetails(${id})`);
        if (this.state.details.indexOf(id) < 0) {
            let list = this.state.details;
            list.push(id);
            this.setState({
                details: list,
            })
        } else {
            let list = [];
            this.state.details.forEach(item => {
                if (item !== id) {
                    list.push(item);
                }
            });
            this.setState({
                details: list,
            })
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    continueTesting(id) {
        document["handler"].log(`TestingsTableComponent.continueTesting(${id})`);
        document["dataSource"].continueTesting(id).then(result => {
            if (result) {
                showSuccess("Testing is continued");
                this.props.load();
            } else {
                showError("An error occurred while reactivating the testing");
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    pauseTesting(id) {
        document["handler"].log(`TestingsTableComponent.pauseTesting(${id})`);
        let list = [];
        this.state.results.forEach(item => {
            if (item !== id) {
                list.push(item);
            }
        });
        this.setState({
            results: list,
        })
        document["dataSource"].pauseTesting(id).then(result => {
           if (result) {
               showSuccess("Testing is paused");
               this.props.load();
           } else {
               showError("An error occurred while stopping the testing");
           }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    saveTesting(id:number, mode = false) {
        document["handler"].log(`TestingsTableComponent.saveTesting(${id}, ${mode})`);
        if (mode) {
            const details = this.state.details;
            const results = this.state.results;
            this.setState({
                details: [id],
                results: [id],
            });
            setTimeout(() => {
                // @ts-ignore
                document.getElementsByClassName("line").forEach(item => {
                    item.style.display = "none";
                });
                document.getElementById("tr_" + id).style.display = "table-row";
                document.getElementById("tr2_" + id).style.display = "table-row";
                document.getElementById("tr3_" + id).style.display = "table-row";
                const content = document.getElementsByClassName("testingData")[0];
                // @ts-ignore
                html2canvas(content).then(data => {
                    const jpg = data.toDataURL("image/jpg");
                    document.getElementById("save_"+id).setAttribute("href", jpg);
                    document.getElementById("save_"+id).click();
                    this.setState({
                        results: results,
                        details: details
                    });
                    // @ts-ignore
                    document.getElementsByClassName("line").forEach(item => {
                        item.style.display = "table-row";
                    });
                });
            }, 1);
        } else {
            const details = this.state.details;
            const results = this.state.results;
            this.setState({
                details: [id],
                results: []
            });
            // @ts-ignore
            document.getElementsByClassName("line").forEach(item => {
                if (item.id !== `tr_${id}`) {
                    item.style.display = "none";
                }
            });
            setTimeout(() => {
                const content = document.getElementsByClassName("testingData")[0];
                // @ts-ignore
                html2canvas(content).then(data => {
                    const jpg = data.toDataURL("image/jpg");
                    document.getElementById("save_"+id).setAttribute("href", jpg);
                    document.getElementById("save_"+id).click();
                    this.setState({
                        details: details,
                        results: results
                    });
                    // @ts-ignore
                    document.getElementsByClassName("line").forEach(item => {
                        item.style.display = "table-row";
                    });
                });
            }, 1);
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("TestingsTableComponent.print()");
        let fout = [];
        this.props.testings &&
        this.props.testings.length &&
        this.props.testings.forEach((prop, key) => {
            let date = '';
            if (prop.result[0] && prop.result[0].date) {
                date = (new Date(prop.result[0].date).toLocaleString());
            }
            let duration = '—';
            if(prop.result && prop.result.length > 1) {
                duration = intToTime((
                    (new Date(prop.result[prop.result.length - 1].date).getTime()) -
                    (new Date(prop.result[0].date).getTime())
                ) / 1000);
            }
            let testing_date = date ? date : '';
            let background = "#fff";
            if (key%2) {
                background = "#fafafa";
            }
            let stress = String(prop.requests_done);
            if (Number(stress) > 1000) {
                stress = parseInt(String(Number(stress) / 1000), 10) + "k";
            }
            if (stress == "null") stress = "";
            let started = '—';
            let avarge_response = 0;
            const labels = [];
            const values = [];
            const response = [];
            let isResponseChart = false;
            let isRequestChart = false;
            if (prop.result) {
                prop.result.forEach((item) => {
                    if (item.requests_per_minute > 0) {
                        isRequestChart = true;
                    }
                    if (item.response_time > 0) {
                        isResponseChart = true;
                    }
                    if (item.response_time > 0 && item.requests_per_minute > 0) {
                        labels.push(item.date.substr(11, 8).replace('T', ' '));
                        values.push(item.requests_per_minute);
                        response.push(item.response_time);
                    }
                });
                if (prop.result[0] && prop.result[0].date) {
                    started = new Date(prop.result[0].date).toLocaleString();
                }
                prop.result.forEach(item => {
                    avarge_response += item.response_time;
                });
                if (prop.result.length) {
                    avarge_response /= prop.result.length;
                }
            }
            let status = TASK_STATE[prop.status];
            if (prop.error_code) {
                status = ERROR_CODE[prop.error_code];
            }
            let links = [];
            prop.links.split(',').forEach(link => {
                if (link.trim().length) {
                    let url = '';
                    if (prop.port === 443) {
                        url = 'https://'+prop.host+link;
                    } else {
                        url = 'http://'+prop.host+link;
                    }
                    links.push(<a href={url} target="_blank">{link}</a>);
                    links.push(<br/>);
                }
            });

            let loader_errors = prop.loader_errors ? prop.loader_errors.toFixed(2) : 0;
            if (loader_errors >= 1000000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000000) + "M";
            } else if (loader_errors >= 1000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000) + "K";
            }
            let spider_errors = prop.spider_errors > 0 ? prop.spider_errors.toFixed(2) : 0
            let percent = prop.percent_done ? (
                prop.percent_done > 100 ? 100 : prop.percent_done.toFixed(2)
            ) : 0;

            let chart = null;
            let chartSize = 0;
            let flag = prop.result.some((el) => el.requests_per_minute != 60);
            prop.result.forEach((item) => {
                if (isResponseChart) {
                    if (item.requests_per_minute > 0 /* && flag */) {
                            chartSize++;
                    }
                } else {
                    if (item.requests_per_minute > 0 /* && flag */) {
                        chartSize++;
                    }
                }
            });
            if (chartSize && this.state.results.indexOf(prop.id) >= 0) {
                const labels = [];
                const values = [];
                const response = [];
                prop.result.forEach((item) => {
                    if (isResponseChart) {
                        if (item.requests_per_minute > 0 && item.response_time > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                            response.push(item.response_time);
                        }
                    } else {
                        if (item.requests_per_minute > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                        }
                    }
                });
                const yAxes = [];
                if (isResponseChart) {
                    yAxes.push({
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: `y-axis-1-${key}`,
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Target response time (sec)'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        }
                    });
                }
                if (flag) {
                    yAxes.push({
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: `y-axis-2-${key}`,
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Requests per minute'
                        },
                    });
                }
                const datasets = [];
                if (isResponseChart) {
                    datasets.push({
                        label: 'Target response time',
                        type: 'line',
                        fill: false,
                        borderColor: '#3bc6ca',
                        backgroundColor: '#3bc6ca',
                        borderWidth: 2,
                        pointRadius: 1,
                        pointHoverRadius: 2,
                        data: response,
                        yAxisID: `y-axis-1-${key}`
                    });
                }
                if (flag) {
                    datasets.push({
                        label: 'Requests per minute',
                        type: 'line',
                        fill: false,
                        borderColor: '#ffc904',
                        backgroundColor: '#ffc904',
                        borderWidth: 2,
                        pointRadius: 1,
                        pointHoverRadius: 2,
                        data: values,
                        yAxisID: `y-axis-2-${key}`
                    });
                }
                chart = (
                    <tr id={"tr3_"+prop.id} className="details" style={{ background:background }}>
                        <td className="mobileData" colSpan={7}>
                            <div className="data">
                                <div className="panel">
                                    <div className="chart" id={`chart-details-${prop.id}`} style={{ background:background }}>
                                        <span className="panel-caption">
                                            Load testing process chart
                                        </span>
                                        <div className="pe-7s-close iconBtn closeBtn"
                                             style={{float:'right'}}
                                             title="Hide detailed load testing process chart"
                                             onClick={() => {
                                                 this.expandResult(prop.id);
                                             }}
                                        />
                                        <Bar
                                            redraw={false}
                                            height={400}
                                            data={{
                                                labels: labels,
                                                datasets: datasets
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                tooltips: {
                                                    mode: 'label'
                                                },
                                                elements: {
                                                    line: {
                                                        fill: false
                                                    }
                                                },
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            display: true,
                                                            gridLines: {
                                                                display: true
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                labelString:
                                                                    prop.status < 4 ?
                                                                        'Objective charts of load testing will be available upon completion' :
                                                                        isResponseChart ?
                                                                            'Ratio of the load level to the response rate' :
                                                                            'The chart of the load level'
                                                            }
                                                        }
                                                    ],
                                                    yAxes: yAxes
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }
            fout.push (
                <tr id={"tr_"+prop.id} key={key} className={
                    this.state.results.indexOf(prop.id) >= 0 ? "expanded line" : "line"
                } style={{background:background}}>
                    <td className="fullscreen selectable" id={"buttons_"+prop.id}>
                        {
                            <div
                                className={"iconBtn materialIconBtn "+(this.state.details.indexOf(prop.id) >= 0 ? 'active' : '')}
                                onClick={() => this.expandDetails(prop.id)}
                                title="Toggle detailed testing information"
                            >
                                <DescriptionOutlinedIcon />
                            </div>
                        }
                        {
                            <i
                                className={"pe-7s-graph2 iconBtn iIcon " + ((flag || isResponseChart) ? "" : " unactive ") + (this.state.results.indexOf(prop.id) >= 0 && chartSize? ' active' : '')}
                               onClick={() => {
                                   if (flag || isResponseChart) {
                                       this.expandResult(prop.id);
                                       setTimeout(() => {
                                           window.dispatchEvent(new Event('resize'));
                                       }, 1);
                                   }
                               }}
                                style={{ opacity: ((flag || isResponseChart) ? 1 : 0.5) }}
                               title="Toggle detailed load testing process chart"
                            />
                        }
                    </td>
                    <td className="hidable selectable">
                        <table style={{ width: "100%", lineHeight: "2.0", textAlign: "left" }}>
                            <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                <td className="long selectable"  style={{
                                    padding: "7px",
                                    paddingRight: "15px",
                                    width: "auto",
                                    minWidth: "80px",
                                }}>Label</td>
                                <td style={{
                                    fontWeight: 500,
                                    width: "70%",
                                    textAlign: "left",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-all"
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        marginTop: "-7px",
                                        right: "calc(5% + 10px)"
                                    }}>
                                        <NavDropdown
                                            eventKey={3}
                                            title={
                                                <i
                                                    className={"pe-7s-more iconBtn iIcon"}
                                                />
                                            }
                                            noCaret
                                            id="basic-nav-dropdown"
                                        >
                                            {
                                                TASK_STATE[prop.status] !== "Completed" &&
                                                TASK_STATE[prop.status] !== "Paused" &&
                                                TASK_STATE[prop.status] !== "Terminated" &&
                                                !prop.readonly &&
                                                <MenuItem onClick={e => this.pauseTesting(prop.id)}>Pause testing process</MenuItem>
                                            }
                                            {
                                                TASK_STATE[prop.status] === "Paused" &&
                                                !prop.readonly &&
                                                <MenuItem onClick={e => this.continueTesting(prop.id)}>Continue testing process</MenuItem>
                                            }
                                            {
                                                <MenuItem onClick={e => {
                                                    this.expandDetails(prop.id);
                                                    setTimeout(() => {
                                                        window.dispatchEvent(new Event('resize'));
                                                    }, 1);
                                                }}>{ this.state.details.indexOf(prop.id) < 0 ? "Show" : "Hide" } detailed information</MenuItem>
                                            }
                                            {
                                                TASK_STATE[prop.status] !== "Paused" &&
                                                (flag || isResponseChart) &&
                                                <MenuItem onClick={e => {
                                                    this.expandResult(prop.id);
                                                    setTimeout(() => {
                                                        window.dispatchEvent(new Event('resize'));
                                                    }, 1);
                                                }}>{this.state.results.indexOf(prop.id) < 0 ? "Show" : "Hide"} detailed process chart</MenuItem>
                                            }
                                            {
                                                (
                                                    TASK_STATE[prop.status] === "Completed" ||
                                                    TASK_STATE[prop.status] === "Terminated"
                                                ) &&
                                                <MenuItem eventKey={4.1} onClick={e => {
                                                    this.saveTesting(prop.id)
                                                }}>Save testing information</MenuItem>
                                            }
                                            {
                                                (
                                                    TASK_STATE[prop.status] === "Completed" ||
                                                    TASK_STATE[prop.status] === "Paused" ||
                                                    TASK_STATE[prop.status] === "Terminated"
                                                ) &&
                                                !prop.readonly &&
                                                !prop.demo &&
                                                <MenuItem eventKey={4.1} onClick={e => this.props.delete(prop.id)}>Delete testing information</MenuItem>
                                            }
                                        </NavDropdown>
                                    </div>
                                    <div style={{ paddingRight: "40px" }}>
                                        { prop.label }
                                    </div>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                <td style={{  padding: "7px",  paddingRight: "15px" }}>Status</td>
                                <td style={{ fontWeight: 500, textAlign: "left" }}>{ status + (status === "In progress" ? " ("+percent+"%)" : "") }</td>
                            </tr>
                            <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                <td style={{ padding: "7px",  paddingRight: "15px" }}>Started</td>
                                <td style={{ fontWeight: 500, textAlign: "left" }}>{ started ? started : "—" }</td>
                            </tr>
                            <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                <td style={{ padding: "7px",  paddingRight: "15px" }}>Duration</td>
                                <td style={{ fontWeight: 500, textAlign: "left" }}>{ duration ? duration : '—' }</td>
                            </tr>
                            <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                <td style={{ padding: "7px",  paddingRight: "15px" }}>Host</td>
                                <td className="long" style={{
                                    fontWeight: 500,
                                    textAlign: "left",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-all" }}
                                >{ prop.host }</td>
                            </tr>
                            {
                                this.state.details.indexOf(prop.id) >= 0 &&
                                [
                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px", paddingRight: "15px" }}>Target</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}>{ prop.ip }:{ prop.port }</td>
                                    </tr>,
                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Method</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}>{ prop.request }</td>
                                    </tr>,
                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Load type</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}>{ true ? "Linear bump" : '' }</td>
                                    </tr>,
                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Threads</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}>{ prop.threads_per_node }x{ prop.nodes_assigned }</td>
                                    </tr>,

                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Requests</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}>
                                            { prop.max }
                                        </td>
                                    </tr>,
                                    <tr
                                        style={{ borderBottom: "#efefef 1px dotted" }}
                                        title="Successfully sent (Total attempts)"
                                    >
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>
                                            Actually sent
                                        </td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }} title="Successfully sent (Total attempts)">
                                            <span style={{  cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                                { prop.requests_done > 0 ? prop.requests_done : 0 }
                                                { prop.requests_total > 0 ? " ("+prop.requests_total+")" : "" }
                                            </span>
                                        </td>
                                    </tr>,
                                    <tr
                                        style={{ borderBottom: "#efefef 1px dotted" }}
                                        title="Client errors / Agent errors"
                                    >
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Errors</td>
                                        <td style={{ fontWeight: 500, textAlign: "left" }}  title="Client errors / Agent errors">
                                            <span style={{  cursor: "help", borderBottom: "#dfdfdf 1px dotted"  }}>
                                                <b style={{fontWeight: 500}}>
                                                        { spider_errors }%
                                                    </b> / <b style={{fontWeight: 500}}>
                                                    { loader_errors }%
                                                </b>
                                            </span>
                                        </td>
                                    </tr>,
                                    <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                        <td style={{ padding: "7px",  paddingRight: "15px" }}>Link(s)</td>
                                        <td className="long" style={{
                                            fontWeight: 500,
                                            textAlign: "left",
                                            whiteSpace: "break-spaces",
                                            wordBreak: "break-all"}}
                                        >{ links }</td>

                                    </tr>
                                ]
                            }
                        </table>
                    </td>
                    <td className="fullscreen selectable" style={{
                        wordBreak: "break-all",
                        whiteSpace: "break-spaces",
                        maxWidth: "20%"
                    }}>{ prop.label }</td>
                    <td className="fullscreen selectable" style={{
                        wordBreak: "break-all",
                        whiteSpace: "break-spaces",
                        maxWidth: "20%"
                    }}>{ prop.host }</td>
                    <td className="fullscreen selectable">
                        {
                            !prop.error_code ? (
                                TASK_STATE[prop.status] === "In progress" ?
                                Math.round(prop.percent_done)+"%" :
                                TASK_STATE[prop.status]
                            ) : ERROR_CODE[prop.error_code]
                        }
                    </td>
                    <td className="fullscreen selectable">{ testing_date ? testing_date : "—" }</td>
                    <td className="fullscreen selectable">{ duration ? duration : "—" }</td>
                    <td className="fullscreen selectable" id={"actions_"+prop.id} >
                        <a download={prop.label} id={"save_"+prop.id} />
                        {
                            (
                                TASK_STATE[prop.status] === "Completed" ||
                                TASK_STATE[prop.status] === "Terminated"
                            ) &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => {
                                    this.saveTesting(prop.id, true)
                                }}
                                title="Save testing info"
                            >
                                <SaveOutlined />
                            </div>
                        }
                        {
                            TASK_STATE[prop.status] !== "Completed" &&
                            TASK_STATE[prop.status] !== "Paused" &&
                            TASK_STATE[prop.status] !== "Terminated" &&
                            !prop.readonly &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => this.pauseTesting(prop.id)}
                                title="Pause testing"
                            >
                                <PauseOutlined />
                            </div>
                        }
                        {
                            TASK_STATE[prop.status] === "Paused" &&
                            !prop.readonly &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => {
                                    if (!prop.demo) {
                                        this.continueTesting(prop.id);
                                    }
                                }}
                                title="Continue testing"
                            >
                                <PlayArrowOutlined />
                            </div>
                        }
                        {
                            TASK_STATE[prop.status] === "Completed" &&
                            <div
                                className={`iconBtn materialIconBtn ${prop.demo && 'unactive'}`}
                                onClick={() => {
                                    if (!prop.demo) {
                                        this.props.share(prop.id);
                                    }
                                }}
                                title="Share testing info"
                                style={{ opacity: (!prop.demo ? 1 : 0.5) }}
                            >
                                <ShareOutlined />
                            </div>
                        }
                        {
                            (
                                TASK_STATE[prop.status] === "Completed" ||
                                TASK_STATE[prop.status] === "Paused" ||
                                TASK_STATE[prop.status] === "Terminated"
                            ) &&
                            !prop.readonly &&
                            <div
                                className={`iconBtn materialIconBtn ${prop.demo && 'unactive'}`}
                                onClick={() => {
                                    if (!prop.demo) {
                                        this.props.delete(prop.id)
                                    }
                                }}
                                title="Delete testing info"
                                style={{ opacity: (!prop.demo ? 1 : 0.5) }}
                            >
                                <DeleteOutlined />
                            </div>
                        }
                    </td>
                </tr>
            );
            fout.push(chart);
            if (this.state.details.indexOf(prop.id) >= 0) {
                let avarge_response = 0;
                prop.result.forEach(item => {
                    avarge_response += item.response_time;
                });
                if (prop.result.length) {
                    avarge_response /= prop.result.length;
                }
                if (window.innerWidth > 900) {
                    fout.push(
                        <tr id={"tr2_"+prop.id} className="details" style={{background: background}}>
                            <td colSpan={7}>
                                <div className="data">
                                <span className="panel-caption">
                                    Detailed testing information
                                </span>
                                    <div className="pe-7s-close iconBtn closeBtn"
                                         style={{float: 'right'}}
                                         title="Hide detailed testing information"
                                         onClick={() => {
                                             this.expandDetails(prop.id);
                                         }}
                                    />
                                    <div style={{clear: "both"}}/>
                                    <div className={"selectable"} style={{
                                        display: "table",
                                        width: "100%",
                                        lineHeight: 2.5,
                                    }}>
                                        <div style={{
                                            display: "table-cell",
                                            width: "32%",
                                            textAlign: "left",
                                            padding: "10px"
                                        }}>
                                            Target: <b style={{fontWeight: 500}}>{prop.ip}:{prop.port}</b><br/>
                                            Method: <b style={{fontWeight: 500}}>{ prop.request }</b><br/>
                                            Threads: <b style={{fontWeight: 500}}>{ prop.threads_per_node }x{ prop.nodes_assigned }</b>
                                        </div>
                                        <div style={{
                                            display: "table-cell",
                                            width: "35%",
                                            textAlign: "left",
                                            padding: "10px"
                                        }}>
                                            Number of requests: <b style={{fontWeight: 500}}>{ prop.max }</b><br/>
                                            <span title="Successfully sent (Total attempts)">
                                                Actually sent: <b style={{ fontWeight: 500, cursor: "help", borderBottom: "#dfdfdf 1px dotted"  }} >
                                                { prop.requests_done > 0 ? prop.requests_done : 0 }
                                                { prop.requests_total > 0 ? " ("+prop.requests_total+")" : "" }
                                                </b>
                                            </span>
                                            <br/>
                                            Average response: <b style={{fontWeight: 500}}>{avarge_response.toFixed(2)} sec.</b><br/>
                                        </div>
                                        <div style={{
                                            display: "table-cell",
                                            width: "30%",
                                            textAlign: "left",
                                            padding: "10px",
                                            overflow: "hidden",
                                            maxWidth: (window.innerWidth / 4) + "px"
                                        }}>
                                            <span title="Client errors / Agent errors">
                                            Errors: <b style={{fontWeight: 500, cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                                    { spider_errors }% / { loader_errors }%
                                                </b>
                                            </span>
                                            <br/>
                                            Load type: <b style={{fontWeight: 500}}>Linear bump</b><br/>
                                            Link(s): <b style={{fontWeight: 500, wordBreak: "break-all"}}>{ links  }</b>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                }
            }
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("TestingsTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            let arrow = '';
            if(thKey === this.props.sortKey){
                if(this.props.sortMethod){
                    arrow = " \u2193";
                }else{
                    arrow = " \u2191";
                }
            }
            tHead.push(
                <th key={thKey} className={ (thKey === "info" ? "hidable" : "fullscreen") } ><span /* className="link" onClick={() => {
                    document["handler"].info("TestingsTableComponent.th("+this.tableHead[thKey]+").click(method="+!(this.props.sortMethod)+")");
                    this.props.sort(thKey, !(this.props.sortMethod));
                }} */>{this.tableHead[thKey]}</span>{arrow}</th>
            )
        }
        let disabled = false;
        this.props.testings &&
        this.props.testings.length &&
        this.props.testings.forEach((prop, key) => {
            if (prop === null || prop.status < 5) {
                disabled = true;
            }
        });
        return(
            <Grid fluid className="TestingsTable">
                <Row>
                    <Col lg={10} md={12} lgOffset={1}>
                        <Card
                            plain
                            title={
                                this.props.isDashboard ?
                                "Load testing" :
                                "Evaluation of testings"
                            }
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <div style={{ overflowX: "auto" }}>
                                    <Button
                                        color="primary"
                                        round
                                        className="btn newTest"
                                        disabled={disabled}
                                        onClick={e => {
                                            if (!this.props.templatesLoaded) {
                                                this.props.loadTemplates(() => {
                                                    this.props.newTest(this.props.templates);
                                                });
                                            } else {
                                                this.props.newTest(this.props.templates);
                                            }
                                        }}
                                    >
                                        <i className="pe-7s-gleam" style={{
                                            fontWeight: "bold",
                                            paddingLeft: "3px",
                                            paddingRight: "7px"
                                        }}/>
                                        <span className="btnText">
                                            New testing &nbsp;
                                        </span>
                                    </Button>
                                    <br/>
                                    {
                                        this.props.loaded ?
                                        (
                                            body.length?
                                            (
                                                <Table className="testingData">
                                                    <thead>
                                                    <tr>{ tHead }</tr>
                                                    </thead>
                                                    <tbody>{ body }</tbody>
                                                </Table>
                                            ):(
                                                <div className="notFound">
                                                    <i className="pe-7s-gleam emptyState"/>
                                                    <br/>
                                                    <br/>
                                                    Testings not found.
                                                </div>
                                            )
                                        ) : (
                                            <div className="loader app-loader">
                                                <img height={150} src={spinner} />
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default TestingsTableComponent;
