/**
 * Loadest - CustomTabs component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Footer.style";
// @ts-ignore
const useStyles = makeStyles(styles);

export default function Footer(props) {
    document["handler"].log("Footer.render()");
    const classes = useStyles();
    const { whiteFont } = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    return (
        <footer className={footerClasses} style={{
            background: props.linearGradient ?
             "linear-gradient(180deg, rgba(246,246,246,1) 50%, rgba(230,230,230,1) 100%)":
            "transparent",
        }}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <p className="siteAbout" style={props.color ? {color: props.color} : null}>
                                Loadest is a tool for checking endpoint overload resistance.
                            </p>
                        </ListItem>
                    </List>
                </div>
                <div className={classes.right}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <NavLink
                                to="/api"
                                className={classes.block}
                                color="transparent"
                                onClick={() => {
                                    window.scrollTo(0,0);
                                }}
                            >
                                API
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <NavLink
                                to="/stop"
                                className={classes.block}
                                color="transparent"
                                onClick={() => {
                                    window.scrollTo(0,0);
                                }}
                            >
                                Emergency stop
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <NavLink
                                to="/privacy_policy"
                                className={classes.block}
                                color="transparent"
                                onClick={() => {
                                    window.scrollTo(0,0);
                                }}
                            >
                                Privacy Policy
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <NavLink
                                to="/terms_and_conditions"
                                className={classes.block}
                                color="transparent"
                                onClick={() => {
                                    window.scrollTo(0,0);
                                }}
                            >
                                Terms & Conditions
                            </NavLink>
                        </ListItem>
                    </List>
                </div>
            </div>
            {
                !props.cookies &&
                <div style={{ height: document.documentElement.clientWidth > 500 ? "90px" : "200px" }}>&nbsp;</div>
            }
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool,
    linearGradient: PropTypes.bool,
    cookies: PropTypes.any,
    color: PropTypes.string
};
