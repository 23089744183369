/**
 * Loadest - Video section.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";

class Video extends React.Component<{}, { loaded:boolean }> {
    constructor(props) {
        super(props);
        this.state = { loaded: false };
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.setState({
                loaded: true
            })
        }, 1);
    }

    render() {
        const background = require("../../assets/img/background.jpeg");
        return (
            <video id="video" style={{
                width: "100%",
                height: "auto",
                minHeight: "700px",
            }} loop muted autoPlay poster={background} className="fullscreen-bg__video">
                {
                    this.state.loaded &&
                    <source src={require("../../assets/img/background.webm")} type="video/webm"/>
                }
            </video>
        )
    }
}

export default Video;