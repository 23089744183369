/**
 * Loadest - GridContainer Props.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import PropTypes from "prop-types";

const GridContainerDefaultProps = {
    className: ""
};

const GridContainerPropTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    justify: PropTypes.string,
    style: PropTypes.object,
};

export { GridContainerDefaultProps, GridContainerPropTypes }
