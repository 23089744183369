/**
 * Loadest - GridContainer CSS properties.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

const gridContainerStyles = {
    grid: {
        marginRight: "-15px",
        marginLeft: "-15px",
        width: "auto"
    }
};

export default gridContainerStyles;
