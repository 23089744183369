/**
 * Loadest - Landing Page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React, {useEffect, useState} from "react";
import cookie from 'react-cookies';
import classNames from "classnames";
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Dns from "@material-ui/icons/Dns";
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import { makeStyles } from "@material-ui/core/styles";
import {
    SYSTEM_CAPTION,
    SYSTEM_DESCRIPTION,
} from "../../variables/language";
import Video from "../../components/Video/Video";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import GridContainer from "../../components/Grid/GridContainer";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridItem from "../../components/GridItem/GridItem";
import InfoArea from "../../components/InfoArea/InfoArea";
import ExpressTest from "../../components/ExpressTest/ExpressTest";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
//@ts-ignore
const useStyles = makeStyles(styles);
import "./LandingPage.css";
import {CustomButton} from "../../components/CustomButton/CustomButton";
import Button from "../../components/Button/Button";
const dashboardRoutes = [];

export default function LandingPage(props) {
    const background = require("../../assets/img/background.jpeg");
    const [renderId, setRenderId] = useState(0);
    const [cookies, setCookies] = useState(cookie.load('cookies'));
    const Ok = () => {
        cookie.save('cookies', true, { path: '/', maxAge: 2628000 });
        setCookies(true);
    }
    let resizeLanding = () => {
        try {
            const el2 = document.getElementById("video");
            const width2 = document.documentElement.clientWidth;
            if (width2 < 1260) {
                el2.style.width = "auto";
            } else {
                el2.style.width = "100%";
            }
        } catch (e) { /* ... */ }
        try {
            if (window.innerWidth != renderId) {
                setRenderId(window.innerWidth);
            }
        } catch (e) { /* ... */ }
    };
    setTimeout(() => document.title = 'Load Testing', 100);
    useEffect( () => {
        try {
            window.addEventListener("resize", resizeLanding);
            resizeLanding();
        } catch (e) { /* ... */ }
        return () => {
            try {
                resizeLanding = () => {};
                window.removeEventListener("resize", resizeLanding);
            } catch (e) { /* ... */}
        }
    });
  const classes = useStyles();
  const { ...rest } = props;
  const sid = cookie.load('sid');
  let uri = '/signup';
  if (sid && sid.length) {
      uri = '/payment';
  }
  return (
    <div className="landingPage">
        <Header
            color="transparent"
            routes={dashboardRoutes}
            brand={
                <div className="siteLogo">
                    <i className="pe-7s-gleam siteIcon" />
                    <div className="siteName">
                        loadest
                    </div>
                </div>
            }
            rightLinks={<HeaderLinks />}
            absolute
            style={{ position:"absolute" }}
            changeColorOnScroll={{
              height: 400,
              color: "white"
            }}
            {...rest}
        />
        {
            !document.documentElement.clientWidth ||
            document.documentElement.clientWidth > 600 ?
            (
                <div className="fullscreen-bg" style={{
                    width:"100%",
                    height:"700px",
                    overflow:"hidden",
                }}>
                    <Video />
                </div>
            ) : (
                <div className="fullscreen-bg" style={{
                    width:"100%",
                    height:"700px",
                    overflow:"hidden",
                }}>
                    <img src={background} style={{ marginTop: "0px", height: "700px", marginLeft: "-33%" }} />
                </div>
            )
        }
        <div style={{marginTop:"-700px", height:"700px"}}>
            <div className="landingBlock">
                <div>
                    <div style={{
                        textAlign:"center",
                        margin:"0px auto",
                        width: "350px",
                        color: "#ddd",
                    }}>
                        <h1 style={{
                            color: "#fff",
                            marginTop: "10px",
                            letterSpacing: "1px",
                        }}>{ SYSTEM_CAPTION }</h1>
                        <p style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            fontWeight: 300,
                            fontSize: 18
                        }}>{ SYSTEM_DESCRIPTION }</p>
                    </div>
                </div>
                <ExpressTest history={props.history} />
            </div>
        </div>
        <div className={classNames(classes.main, classes.mainRaised)} style={{
            maxWidth: "1600px",
            margin: "0px auto",
            marginTop: "-60px"
        }}>
            <div className={classes.container} id="screen">
                <div>
                    <h2>
                        Overload Resistance Testing
                    </h2>
                    <p style={{
                        color: "#333",
                        margin: "0px auto",
                        textAlign: "center",
                        paddingBottom: "60px"
                    }}>
                        Online service for determination an endpoint behavior under both normal and at stress conditions
                    </p>
                    <GridContainer style={{ paddingBottom: "50px" }}>
                        <GridItem xs={12} sm={6} md={3}>
                            <InfoArea
                                title="Software solutions quality testing"
                                // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                                icon={CloudQueueIcon}
                                iconColor="info"
                                vertical
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                            <InfoArea
                                title="Hardware perfomance comparison"
                                // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                                icon={Dns}
                                iconColor="danger"
                                vertical
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                            <InfoArea
                                title="Web servers benchmarking"
                                // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                                icon={VerifiedUser}
                                iconColor="success"
                                vertical
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                            <InfoArea
                                title="10 tokens welcome bonus"
                                // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                                icon={BatteryCharging90Icon}
                                iconColor="primary"
                                vertical
                            />
                        </GridItem>
                    </GridContainer>
                    <div>
                        <GridContainer style={{paddingBottom: "50px", overflow: "hidden", textAlign: "center"}}>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3 style={{ color: "#111" }}>How it works?</h3>
                                <video controls={true} width="100%" height="auto" poster={require("../../assets/img/video.jpeg")} >
                                    <source src={require("../../assets/img/video.mp4")} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                                    <source src={require("../../assets/img/video.webm")} type='video/webm; codecs="vp8, vorbis"' />
                                </video>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="cryto">
                        <img src={require("../../assets/img/crypto.png")} style={{ width: "calc(100% - 40px)", maxWidth: 900 }} />
                    </div>
                </div>
            </div>
        </div>
        <Footer linearGradient={true} cookies={cookies} />
        {
            <div style={{
                position: "fixed",
                bottom: "0px",
                left: "0px",
                right: "0px",
                width: "100%",
                padding: "10px",
                display: cookies ? "none" : "table",
                textAlign: "left",
                background: "#333",
                color: "#fff",
                zIndex: 3
            }}>
                <div style={{
                    display: "table-cell",
                    padding: "5px",
                    paddingRight: "10px",
                    textAlign: window.innerWidth > 800 ? "center" : "left"
                }}>
                    Like many websites, ours uses cookies. Cookies are small text files that we put on your computer, that allow us to provide you with a better browsing experience. &nbsp;
                    {
                        window.innerWidth > 800 &&
                        <span style={{ whiteSpace: "nowrap" }}>By using this website, you agree to let us use cookies.</span>
                    }
                </div>
                <div style={{
                    display: "table-cell",
                    verticalAlign: "middle"
                }}>
                    <CustomButton style={{ zoom: "0.7" }} fill onClick={e => Ok()}>Ok</CustomButton>
                </div>
            </div>
        }
    </div>
  );
}
