/**
 * Loadest - Application constants.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

export const TASK_STATE = {
    '0': 'Loading',
    '1': 'Pending',
    '2': 'Starting',
    '3': 'In progress',
    '4': 'Stopping',
    '5': 'Completed',
    '6': 'Terminated',
    '7': 'In queue',
    '-1': 'Paused'
};

export const PROCESS_STATE = {
    '0': 'pending',
    '1': 'in progress',
    '2': 'finished',
};

export const ERROR_CODE = {
    '1': 'Timeout',
    '2': 'Terminated',
    '3': 'Interrupted'
}

export const PAYMENT_STATUS = {
    '-1': "Payment is cancelled",
    '0': "Awaiting payment",
    '1': "Pending confirmations",
    '2': "Transaction completed",
    '3': "Payment is finished",
}

export const ERROR_MESSAGE = 'To conduct this load testing, you must confirm the rights to the domain';