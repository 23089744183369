/**
 * Loadest - DAO document component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import ReactHtmlParser from 'react-html-parser';
import marked from "marked";
import "./DAO.css";
// @ts-ignore
import spinner from "../../assets/img/load.gif";

class DAO extends React.Component {
    renderId: number;
    state: {
        data: any
    }
    //------------------------------------------------------------------------------------------------------------------
    constructor(props){
        super(props);
        document["handler"].log("DAO.constructor()");
        this.renderId = 0;
        this.state = {
            data: null
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("DAO.componentDidMount()");
        document["dataSource"].getDaoInfo().then(result => {
           this.setState({
               data: ReactHtmlParser(marked(result))
           })
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("DAO.componentWillUnmount()");
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("DAO.render() №"+(++this.renderId));
        return (
            <div className="DAO">
                {
                    !this.state.data?
                    (
                        <div className="loader">
                            <img height={150} src={spinner} />
                        </div>
                    ) :
                    this.state.data
                }
            </div>
        );
    }
}

export default DAO;
