/**
 * Loadest - TestingList container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import DomainList from "./DomainList.component";
import {resetDomainList} from "../../actions";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        loaded: state.domains.loaded,
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = dispatch => {
    return {
        load: (callback) => {
            dispatch(resetDomainList());
            document["dataSource"].getDomains().then((result) => {
                setTimeout(callback, 300, result);
            });
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainList));