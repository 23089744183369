/**
 * Loadest - Express testing form component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { InferProps } from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Requireable } from "react";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TouchApp from '@material-ui/icons/TouchApp';
import { withStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {
    ExpressTestDefaultProps,
    ExpressTestPropTypes,
    IExpressTestState
} from "./ExpressTest.props";
import CustomInput from "../CustomInput/CustomInput";
import {
    SYSTEM_EMAIL_ADDRESS,
    SYSTEM_SITE_ADDRESS_PLACEHOLDER
} from "../../variables/language";
import Button from "../Button/Button";
import isValidURL from "../../functions/isValidURL";
import isValidEmail from "../../functions/isValidEmail";
import extractHostname from "../../functions/extractHostname";
import styles from "./ExpressTest.style";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import ConfirmDomain from "../ConfirmDomain/ConfirmDomain";
import {hideDomainConfirmation, showDomainConfirmation} from "../../actions";

class ExpressTest extends React.Component<InferProps<typeof ExpressTestPropTypes>, IExpressTestState> {
    renderId: number;
    nameInput: any;
    onKeyPress: (e:any) => void;
    static defaultProps: { classes: any[] };
    static propTypes: { classes: Requireable<any>; history: Requireable<any> };
    //------------------------------------------------------------------------------------------------------------------
    static mapStateToProps = (state) => {
        return {
            magic: state.user.magic,
            domainConfirmation: state.main.domainConfirmation,
            confirmed: state.user.confirmed
        }
    };
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("ExpressTest.constructor()");
        this.renderId = 0;
        this.state = {
            step: 0,
            url: "",
            email: "",
            dialog: false,
            alert: false,
            alertOnOk: () => {},
            urlError: false,
            loading: false,
            forward: false,
            domainConfirm: null,
        };
        this.nameInput = null;
        this.onKeyPress = function (e) {
            document["handler"].log("ExpressTest.onKeyPress()");
            if ((e.code === "Enter" || e.code === "NumpadEnter") && this.state.dialog) {
                try {
                    this.start();
                } catch (e) { /* ... */ }
            } else if ((e.code === "Enter" || e.code === "NumpadEnter") && this.state.alert) {
                try {
                    this.forward();
                } catch (e) { /* ... */ }
            } else if (e.code === "Esc" && this.state.dialog) {
                this.setState({
                    ...this.state,
                    dialog: false,
                });
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    forward() {
        if (this.state.forward) {
            this.setState({
                alert: false,
                dialog: false,
                forward: false,
            });
            this.props.history.push(`/app/dashboard`);
        } else {
            this.setState({
                alert: false,
                dialog: false,
                url: "",
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    start() {
        document["handler"].log("ExpressTest.start()");
        if (!this.state.loading) {
            this.setState({
                ...this.state,
                loading: true,
            })
            document["dataSource"].expressTest(
                this.state.url.trim().toLowerCase(),
                this.state.email.trim() ?
                    this.state.email.trim().toLowerCase() :
                    document["reduxStore"].getState().user.email.trim().toLowerCase()
            ).then(message => {
                if (!document["initInterval"]) {
                    document["initInterval"] = window.setInterval(() => document["dataSource"].initData(), 30000);
                }
                if (message) {
                    this.setState({
                        dialog: false,
                        alert: message,
                        alertOnOk: this.forward.bind(this),
                        forward: true,
                        step: 0,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        dialog: false,
                        loading: false,
                        step: 0,
                    });
                }
            }).catch(err => {
                if (err) {
                    if (
                        err == "To conduct this load testing, you must confirm the rights to the domain" ||
                        err == "The domain name is already assigned to another user" ||
                        err == "To conduct this load testing, you must verify this domain rights"
                    ) {
                        let domain = extractHostname(this.state.url.trim().toLowerCase());
                        if (document["dataSource"].domain) {
                            domain = document["dataSource"].domain;
                            document["dataSource"].domain = null;
                        }
                        this.setState({
                            dialog: false,
                            alert: err,
                            alertOnOk: () => {
                                document["reduxStore"].dispatch(
                                    showDomainConfirmation(
                                        domain,
                                        () => {
                                            this.start();
                                        },
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                domainConfirm: null
                                            });
                                            document["reduxStore"].dispatch(hideDomainConfirmation());
                                        })
                                );
                                this.setState({
                                    dialog: false,
                                    alert: false,
                                    forward: false,
                                    step: 0,
                                    loading: false,
                                    domainConfirm: domain,
                                })
                            },
                            forward: true,
                            step: 0,
                        });
                    } else if (err == "You need to confirm your email address to continue") {
                        this.setState({
                            dialog: false,
                            alert: err,
                            alertOnOk: () => this.props.history.push('/confirmation'),
                            forward: false,
                            url: "",
                            step: 0,
                            loading: false,
                        });
                    } else if (err == "There are not enough tokens on your account balance to conduct this load testing") {
                        this.setState({
                            dialog: false,
                            alert: err,
                            alertOnOk: () => this.props.history.push('/payment'),
                            forward: false,
                            url: "",
                            step: 0,
                            loading: false,
                        })
                    } else {
                        this.setState({
                            dialog: false,
                            alert: err,
                            alertOnOk: this.forward.bind(this),
                            forward: false,
                            url: "",
                            step: 0,
                            loading: false,
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        dialog: false,
                        loading: false,
                        url: "",
                        step: 0,
                    });
                }
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount() {
        document["handler"].log("ExpressTest.componentDidMount()");
        if (window.innerWidth >= 600) {
            try {
                const el = document.getElementById("express_test");
                if (el) {
                    el.focus();
                }
                document["appLoader"].addHandler(e => {
                    const el = document.getElementById("express_test");
                    if (el) {
                        el.focus();
                    }
                });
            } catch (e) { /* ... */ }
        }
        document.addEventListener('keypress', e => this.onKeyPress(e), true);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("ExpressTest.componentWillUnmount()");
        this.onKeyPress = e => {};
        this.setState({
            ...this.state,
            dialog: false,
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    onClick() {
        if (!this.state.step && this.state.url) {
            if (isValidURL(this.state.url)) {
                if (document["reduxStore"].getState().user.sid) {
                    setTimeout(() => this.setState({ ...this.state, dialog: true }), 100);
                } else {
                    this.setState({ ...this.state, step: 1 });
                    setTimeout(() => {
                        const el = document.getElementById("email_id");
                        if (el) {
                            el.focus();
                        }
                    })
                }
                try {
                    document["dataSource"].expressRequest(this.state.url);
                } catch (e) { /* ... */ }
            } else {
                if (
                    this.state.url.indexOf("localhost") >= 0 ||
                    this.state.url.indexOf("127.0.0.1") >= 0
                ) {
                    this.setState({ ...this.state, urlError: "Unable to test your localhost" });
                } else {
                    this.setState({ ...this.state, urlError: "Invalid URL address" });
                }
            }
        } else if (this.state.email) {
            if (isValidEmail(this.state.email)) {
                setTimeout(() => this.setState({  ...this.state, dialog: true }), 100);
            } else {
                this.setState({ ...this.state, urlError: "Invalid email address" });
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("ExpressTest.render() №" + (++this.renderId));
        return (
            <div style={{display:"table", margin:"0px auto"}}>
                <div style={{display:"table-cell", verticalAlign:"middle"}}>
                    {
                        !this.state.step ?
                        (
                            <CustomInput
                                id="express_test"
                                labelText={ this.state.urlError ? this.state.urlError : SYSTEM_SITE_ADDRESS_PLACEHOLDER }
                                error={this.state.urlError != false}
                                placeholder="https://example.com/"
                                bigFont={true}
                                width={270}
                                onChange={e => {
                                    this.setState({
                                        ...this.state,
                                        url: e.target.value,
                                        urlError: false
                                    });
                                }}
                                inputProps={{
                                    value: this.state.url
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        this.onClick();
                                    }
                                }}
                            />
                        ) : (
                            <CustomInput
                                labelText={ this.state.urlError ? this.state.urlError : SYSTEM_EMAIL_ADDRESS }
                                error={this.state.urlError != false}
                                placeholder="admin@example.com"
                                bigFont={true}
                                width={270}
                                onChange={e => {
                                    this.setState({
                                        ...this.state,
                                        email: e.target.value,
                                        urlError: false
                                    });
                                }}
                                id="email_id"
                                inputProps={{
                                    value: this.state.email
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        this.onClick();
                                    }
                                }}
                            />
                        )
                    }
                </div>
                <div style={{
                    display:"table-cell",
                    verticalAlign:"middle",
                    paddingTop: "15px",
                    paddingLeft:"20px"
                }}
                >
                    <Button disabled={
                        ( !this.state.step && (this.state.urlError != false || this.state.url.length <= 0 )) ||
                        ( this.state.step && (this.state.urlError != false || this.state.email.length <= 0 ))
                    }
                            justIcon
                            round
                            color="primary"
                            onClick={() => this.onClick()}
                    >
                        {
                            !this.state.step ?
                            <ArrowForwardIcon className={this.props.classes.icons} />  :
                            <TouchApp className={this.props.classes.icons} />
                        }

                    </Button>
                </div>
                {
                    this.state.dialog &&
                    <Dialog
                        open={true}
                        keepMounted
                        onClose={() => this.setState({
                            ...this.state,
                            dialog: false,
                        })}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                    >
                        {
                            this.state.loading ?
                            (
                                <div style={{
                                    textAlign: "center",
                                    width: (window.innerWidth >= 620 ? "600px" : (window.innerWidth - 40) + "px"),
                                    marginTop: "80px",
                                    marginBottom: "50px"
                                }}>
                                    <img src={spinner} height={150} />
                                </div>
                            ) : (
                                <>
                                    <DialogTitle
                                        id="classic-modal-slide-title"
                                        disableTypography
                                    >
                                        <h1 style={{
                                            display: "block",
                                            textAlign: "center",
                                            fontSize: "24px",
                                            fontFamily: "Open Sans",
                                            marginTop: "20px",
                                        }}>
                                            Attention!
                                        </h1>
                                        <br/>
                                        You are about to start load testing,
                                        what may cause a temporary performance issues or
                                        unavailability of target endpoint.
                                        <br/>
                                        <br />
                                        By clicking "Ok" you confirm that you are familiar and agree with&nbsp;
                                        <NavLink
                                            target="_blank"
                                            to="/terms_and_conditions"
                                            color="transparent"
                                        >
                                            Terms & Conditions
                                        </NavLink>
                                        <br />
                                        <br />
                                    </DialogTitle>
                                    <DialogActions >
                                        <Button
                                            onClick={() => this.start()}
                                            color="transparent"
                                            simple
                                        >
                                            Ok
                                        </Button>
                                        <Button
                                            onClick={() => { this.setState({ ...this.state, dialog: false }) }}
                                            color="danger"
                                            simple
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </>
                            )
                        }
                    </Dialog>
                }
                {
                    this.state.alert &&
                    <Dialog
                        open={true}
                        keepMounted
                        onClose={() => this.setState({
                            ...this.state,
                            alert: false,
                            alertOnOk: () => {},
                            forward: false,
                        })}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                    >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                        >
                            <h1 style={{
                                display: "block",
                                textAlign: "center",
                                fontSize: "24px",
                                fontFamily: "Open Sans",
                                marginTop: "20px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                lineHeight: "1.5"
                            }}>
                                { this.state.alert }
                            </h1>
                        </DialogTitle>
                        <DialogActions >
                            <Button
                                onClick={() => {
                                    this.state.alertOnOk();
                                }}
                                color="transparent"
                                simple
                            >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {
                    this.state.domainConfirm &&
                    <ConfirmDomain
                        domain={this.props.domainConfirmation.domain}
                        magic={this.props.magic}
                        onOk={() => this.props.domainConfirmation.onOk()}
                        onClose={() => this.props.domainConfirmation.onClose()}
                    />
                }
            </div>
        );
    }
}

ExpressTest.defaultProps = ExpressTestDefaultProps;
ExpressTest.propTypes = ExpressTestPropTypes;

export default withStyles(styles)(connect(ExpressTest.mapStateToProps, null)(ExpressTest));