/**
 * Loadest - ErrorsTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import {connect} from "react-redux";
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { Card } from "../Card/Card";
import "./ErrorsTable.css";
import Button from "../Button/Button";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import {hideModal, showModal} from "../../actions";

interface IErrorsTableProps {
    alert: (caption:string, text:React.ReactElement) => void
}

class ErrorsTable extends React.Component<IErrorsTableProps, { errors: any[], loaded: boolean }>{
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("NodesTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            ip: "IP",
            date: "Date",
            act: "",
        }
        this.state = {
            loaded: false,
            errors: []
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    load():void {
        document["dataSource"].getErrors().then(results => {
            setTimeout(() => {
                if (results.length) {
                    this.setState({
                        errors: results.sort((a, b) => {
                            const aa = new Date(a.date);
                            const bb = new Date(b.date);
                          return aa > bb ? -1 : 1
                        }),
                        loaded: true
                    });
                } else {
                    this.setState({
                        loaded: false
                    });
                }
            }, 300);
        })
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        if (this.state.loaded) {
            this.setState({
                loaded: false
            });
        }
        this.load();
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("NodesTableComponent.print()");
        let fout = [];
        this.state.errors &&
        this.state.errors.length &&
        this.state.errors.forEach((prop, key) => {
            let date = new Date(prop.date);
            let log_date = date.toLocaleString();
            fout.push (
                <tr key={key}>
                    <td>{prop.name}</td>
                    <td>{log_date}</td>
                    <td style={{ width: "55px" }}>
                        <div
                            className="iconBtn materialIconBtn"
                            onClick={() => {
                                this.props.alert(
                                    prop.date,
                                    <div style={{textAlign: "left"}}>
                                        {
                                            prop.data.split('\n').map(item => {
                                                return <div>{item}</div>
                                            })
                                        }
                                    </div>
                                );
                            } }
                            title="Show tracestack"
                        >
                            <CommentOutlinedIcon />
                        </div>
                    </td>
                </tr>
            );
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("NodesTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            tHead.push(
                <th key={thKey}><span /* className="link" */>{this.tableHead[thKey]}</span></th>
            )
        }
        return(
            <Grid fluid className="ErrorsTable">
                <Row>
                    <Col md={8} mdOffset={2}>
                        <Card
                            plain
                            title="Exceptions"
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <>
                                    <Button
                                        color="primary"
                                        round
                                        className="btn newTest"
                                        onClick={e => {
                                            this.componentDidMount();
                                        }}
                                    >
                                        <i className="pe-7s-refresh" style={{
                                            fontWeight: "bold",
                                            paddingLeft: "3px",
                                            paddingRight: "7px"
                                        }}/>
                                    </Button>
                                    <br/>

                                    <div style={{ overflow: "auto", maxWidth: "600px", margin: "0px auto" }}>
                                    {
                                        this.state.loaded ? (
                                            body.length?
                                            (
                                                <Table hover>
                                                    <thead>
                                                    <tr>{ tHead }</tr>
                                                    </thead>
                                                    <tbody>{ body }</tbody>
                                                </Table>
                                            ):(
                                                <div className="notFound">
                                                    <i className="pe-7s-attention emptyState"/>
                                                    <br/>
                                                        Errors not found.
                                                    <br/>
                                                </div>
                                            )
                                        ) : (
                                            <div className="loader app-loader">
                                                <img height={150} src={spinner} />
                                            </div>
                                        )
                                    }
                                    </div>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alert: (caption, text) => {
            dispatch(
                showModal(
                    caption, text, () => {
                        dispatch(hideModal())
                    }
                )
            )
        }
    }
}

export default connect(null, mapDispatchToProps)(ErrorsTable);
