/**
 * Loadest - Stop page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import { withStyles } from '@material-ui/styles';
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/GridItem/GridItem";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import Footer from "../../components/Footer/Footer";
import {
    IStopPageProps,
    IStopPageState
} from "./StopPage.interface";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import "./StopPage.css";

class StopPage extends React.Component<IStopPageProps, IStopPageState> {
    renderId: number;
    onKeyPress: (e:any) => void;
    isLoaded: boolean;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("StopPage.constructor()");
        this.renderId = 0;
        this.isLoaded = false;
        this.state = {
            dialog: {
                display: false,
                title: '',
                onOk: () => {}
            },
            loading: false,
            url: "",
        };
        this.onKeyPress = function(e) {
            document["handler"].log(`StopPage.onKeyPress(${e.code})`);
            if (
                (e.code === "Enter" || e.code === "NumpadEnter") &&
                window.location.href.indexOf("/stop") >= 0
            ) {
                try {
                    this.onRequest();
                } catch (e) { /* ... */ }
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        document["handler"].log("StopPage.componentDidMount()");
        setTimeout(() => document.title = 'Stop Testing', 100);
        try {
            if (window.innerWidth >= 768) {
                const el = document.getElementById("url");
                if (el) {
                    el.focus();
                }
            }
        } catch (e) { /* ... */ }
        document.addEventListener('keypress', e => this.onKeyPress(e), false);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount(): void {
        document["handler"].log("StopPage.componentWillUnmount()");
        this.onKeyPress = e => {};
    }
    //------------------------------------------------------------------------------------------------------------------
    setDialog(title:string = null, onOk: () => void = null): void {
        document["handler"].log(`StopPage.setDialog(${title})`);
        this.setState({
            dialog: {
                display: title != null,
                title: title,
                onOk: onOk
             }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    setLoading(value:boolean): void {
        document["handler"].log(`StopPage.setLoading(${value})`);
        this.setState({ loading: value });
    }
    //------------------------------------------------------------------------------------------------------------------
    onRequest() {
        document["handler"].log("StopPage.onRequest()");
        const url_el = document.getElementById("url");
        const url = this.state.url.trim().toLowerCase();
        let flag = false;
        if (!url) {
             flag = true;
            try {
                // @ts-ignore
                url_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
        }
        if (!flag) {
            this.setLoading(true);
            document["dataSource"].stopTesting(url).then(result => {
                if (result) {
                    this.setDialog("Test is stopping",() => {
                        this.setLoading(false);
                    });
                } else {
                    this.setDialog("Test not found", () => {
                        this.setLoading(false);
                    });
                }
            });
        }
    };
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("StopPage.render() №" + (++this.renderId));
        document["handler"].log(this.state);
        if (!this.isLoaded) {
            this.isLoaded = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 1);
        }
        const { classes, ...rest } = this.props;
        return (
            <div className="restorePage">
                <Header
                    absolute
                    color="transparent"
                    brand={
                        <div className="siteLogo">
                            <i className="pe-7s-gleam siteIcon" />
                            <div className="siteName">
                                loadest
                            </div>
                        </div>
                    }
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <div
                    className={classes.pageHeader + " pageHeader"}
                    style={{ backgroundImage: "url(" + image + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6} style={{ maxWidth: "360px" }}>
                                <Card content={
                                    <form className={classes.form}>
                                        <h1 className={classes.loginCaption} style={{ fontSize: "28px" }}>Stop Testing</h1>
                                        {
                                            this.state.loading &&
                                            <div className="loader">
                                                <img src={spinner} height={150} />
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>
                                                <div style={{
                                                    color: "rgb(102, 102, 102)",
                                                    paddingTop: "20px",
                                                    paddingBottom: "10px",
                                                    textAlign: "center",
                                                    fontSize: "14px"
                                                }}>
                                                    Emergency stop of unauthorized testing
                                                </div>
                                                <br/>
                                                <CustomInput
                                                    labelText="Domain name"
                                                    id="url"
                                                    defaultValue=""
                                                    value={this.state.url}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    placeholder={"example.com"}
                                                    onChange={e => {
                                                        this.setState({
                                                            url: e.target.value,
                                                        })
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <br/>
                                                <br/>
                                                <div className="getStarted">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        style={{margin: "0px auto", marginBottom: "20px"}}
                                                        onClick={() => this.onRequest()}
                                                        disabled={
                                                            !this.state.url
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                }
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="h100" />
                    <Footer  cookies={true} linearGradient={false} />
                </div>
                {
                    this.state.dialog.display &&
                    <CustomDialog
                        open={true}
                        caption={this.state.dialog.title.toString()}
                        close={() => {
                            this.state.dialog.onOk();
                            this.setDialog();
                        }}
                    />
                }
            </div>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(StopPage);
