/**
 * Loadest - InfoArea CSS properties.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    title
} from "../../assets/jss/material-kit-react.js";

const infoStyle = {
    infoArea: {
        maxWidth: "360px",
        margin: "0 auto",
        padding: "0px",
        textAlign: "center"
    },
    iconWrapper: {
        float: "left",
        marginTop: "24px",
        marginRight: "10px"
    },
    primary: {
        color: primaryColor
    },
    warning: {
        color: warningColor
    },
    danger: {
        color: dangerColor
    },
    success: {
        color: successColor
    },
    info: {
        color: infoColor
    },
    rose: {
        color: roseColor
    },
    gray: {
        color: grayColor
    },
    icon: {
        width: "36px",
        height: "36px"
    },
    descriptionWrapper: {
        color: grayColor,
        overflow: "hidden"
    },
    title: {
        fontSize: "18px",
        color: "#666",
        fontWeight: "400"
    },
    description: {
        color: grayColor,
        overflow: "hidden",
        marginTop: "0px",
        fontSize: "14px"
    },
    iconWrapperVertical: {
        float: "none"
    },
    iconVertical: {
        width: "61px",
        height: "61px"
    }
};

export default infoStyle;
