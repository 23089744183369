/**
 * Loadest - Templates actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { Action } from 'redux';
import { TemplatesTypes } from "../variables/Types";
import ITemplate from "../interfaces/Template";

interface ILoadTemplatesAction extends Action {
    payLoad: {
        templates: ITemplate[],
        loaded: boolean,
    }
}

const loadTemplatesList = (data: ITemplate[]):ILoadTemplatesAction => ({
    type: TemplatesTypes.UpdateTemplatesAction,
    payLoad: {
        templates: data,
        loaded: true
    }
});

const reloadTemplatesList = ():ILoadTemplatesAction => ({
    type: TemplatesTypes.UpdateTemplatesAction,
    payLoad: {
        templates: [],
        loaded: false
    }
});

type ITemplatesSummaryAction =
    ILoadTemplatesAction;

export {
    ILoadTemplatesAction,
    ITemplatesSummaryAction,
    loadTemplatesList,
    reloadTemplatesList
};
