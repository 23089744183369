/**
 * Loadest - Dashboard actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { DashboardTypes } from "../variables/Types";
import { ITesting } from "../interfaces/Testing";
import { Action } from 'redux';
//----------------------------------------------------------------------------------------------------------------------
/* Loading task list */
interface ITaskListItem{
    text: string,
    checked: boolean
}
interface ILoadListAction extends Action {
    payLoad: {
        tasks: ITaskListItem[]
    }
}
const loadTaskList = (data: ITaskListItem[]):ILoadListAction => ({
    type: DashboardTypes.UpdateListAction,
    payLoad: {
        tasks: data
    }
});
//----------------------------------------------------------------------------------------------------------------------
/* Update tasks list size */
interface IUpdateListCountAction extends Action {
    payLoad: {
        count: number
    }
}
const updateListCount = (number:number):IUpdateListCountAction => ({
    type: DashboardTypes.ShowListCountAction,
    payLoad: {
        count: number
    }
});

//----------------------------------------------------------------------------------------------------------------------
/* Update tasks list size */
interface ILoadDashboardAction extends Action {
    payLoad: ITesting,
}
const loadDashboard = (data):ILoadDashboardAction => ({
    type: DashboardTypes.LoadDashboardAction,
    payLoad: data
});
//----------------------------------------------------------------------------------------------------------------------
type IDashboardSummaryAction =
    IUpdateListCountAction &
    ILoadListAction &
    ILoadDashboardAction;
//----------------------------------------------------------------------------------------------------------------------
export {
    ITaskListItem,
    IUpdateListCountAction,
    ILoadListAction,
    IDashboardSummaryAction,
    ILoadDashboardAction,
    loadTaskList,
    updateListCount,
    loadDashboard
};