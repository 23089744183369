/**
 * Loadest - Application index file.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import "core-js";
import * as React from "react";
import ReactDOM from "react-dom";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {createBrowserHistory} from "history";
import {Provider} from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ReduxStore from "./reduxStore";
import ErrorHandler from "./errorHandler";
import AppLoader from "./appLoader";
import DataSource from "./dataSource";
import LandingPage from "./views/LandingPage/LandingPage";
import LoginPage from "./views/LoginPage/LoginPage";
import SignupPage from "./views/SignupPage/SignupPage";
import TermsPage from "./views/TermsPage/TermsPage";
import PrivacyPage from "./views/PrivacyPage/PrivacyPage";
import ApiPage from "./views/ApiPage/ApiPage";
import DaoPage from "./views/DaoPage/DaoPage";
import RestorePage from "./views/RestorePage/RestorePage";
import StopPage from "./views/StopPage/StopPage";
import PaymentPage from "./views/PaymentPage/PaymentPage"
import DetailsPage from "./views/DetailsPage/DetailsPage";
import ConfirmationPage from "./views/ConfirmationPage/ConfirmationPage";
import Admin from "./views/Admin/Admin";
import "./assets/css/animate.min.css";
import "./assets/css/light-bootstrap-dashboard-react.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/glyphicons-halflings-regular.css";
import "./assets/css/main.css";
//----------------------------------------------------------------------------------------------------------------------
/* @init */
try {
    const rootComponent = "root";
    document["handler"] = new ErrorHandler(rootComponent);
    document["reduxStore"] = ReduxStore();
    document["history"] = createBrowserHistory();
    document["appLoader"] = new AppLoader();
    document["dataSource"] = new DataSource(document["reduxStore"], document["appLoader"]);
    try {
        window.onerror = e => {
            if (e.toString() !== 'ResizeObserver loop limit exceeded' ) {
                document["handler"].error(e);
                document["handler"].throw();
            }
        }
        const forceLoader = window.setTimeout(() => {
            if (
                document["appLoader"].load_state &&
                !document["appLoader"].is_dom_loaded
            ) {
                try {
                    window.onload.call(this);
                    document["appLoader"].load("window");
                } catch (e) { /* ... */ }
            }
        }, 20000);
        window.addEventListener("load", () => {
            window.clearTimeout(forceLoader);
            setTimeout(() => {
                document["appLoader"].load("window");
            }, 1000);
        });
    } catch(e) {
        document["handler"].error("Index.@listen -> "+e.message);
    }
    try {
        let interval = null;
        document["dataSource"].initData().then((success) => {
            if (success && !document["initInterval"]) {
                document["initInterval"] = window.setInterval(() => document["dataSource"].initData(), 30000);
            }
            ReactDOM.render(
                <div id="react">
                    <Provider store={document["reduxStore"]}>
                        <Router history={document["history"]}>
                            <Switch>
                                <Route path="//" component={LandingPage} />
                                <Route path="/terms_and_conditions" component={TermsPage} />
                                <Route path="/privacy_policy" component={PrivacyPage} />
                                <Route path="/api" component={ApiPage} />
                                <Route path="/dao" component={DaoPage} />
                                <Route path="/login" component={LoginPage} />
                                <Route path="/signup" component={SignupPage} />
                                <Route path="/reset" component={RestorePage} />
                                <Route path="/stop" component={StopPage} />
                                <Route path="/confirmation" component={ConfirmationPage} />
                                <Route path="/payment" component={PaymentPage} />
                                <Route path="/details" component={DetailsPage} />
                                <Route path="/app" render={props => <Admin {...props} /> } />
                                <Route component={document["handler"].notFound()} />
                                <Redirect from="/app" to="/app/dashboard" />
                                <Redirect from="/" to="//" />
                            </Switch>
                        </Router>
                    </Provider>
                </div>,
                document.getElementById(rootComponent)
            );
        });
    } catch(e) {
        document["handler"].error("Index.@react -> "+e.message);
    }
} catch(e) {
    document["handler"].error("Index.@init -> "+e.message);
}
