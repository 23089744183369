/**
 * Loadest - RightModal component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {IRightModalProps, IRightModalState} from "./RightModal.props";
import {CustomButton} from "../CustomButton/CustomButton";
import "./RightModal.css";
import showSuccess from "../../functions/showSuccess";
import showError from "../../functions/showError";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import showMessage from "../../functions/showMessage";
import extractHostname from "../../functions/extractHostname";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";

class RightModalComponent extends React.Component<IRightModalProps, IRightModalState> {
    state: IRightModalState;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("RightModal.constructor()");
        this.state = {
            label: props.label,
            target: 0,
            request: 'GET',
            url: '',
            host: '',
            port: 80,
            links: "",
            cluster: 1,
            nodes: 3,
            tps: 1000,
            min: 100000,
            max: 100000,
            errors: 0,
            loading: false,
            post: '',
            headers: [],
            dialog: null,
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount() {
        document["handler"].log("RightModal.componentDidMount()");
        try {
            // @ts-ignore
            document.getElementsByClassName("wrapper")[0].style.overflow = "hidden";
            if (this.props.template_id > 0) {
                this.props.templates.forEach(template => {
                    if (template.id === this.props.template_id) {
                        this.setState({
                            host: template.host.toString().toLowerCase(),
                            port: template.port,
                            tps: template.threads,
                            min: template.load,
                            max: template.load,
                            links: template.links,
                            nodes: template.nodes,
                            request: template.request,
                            cluster: template.nodes > 1 ? 1 : 0,
                            target: template.links === "/," ? 0 : 1,
                            url: template.host,
                            post: template.post,
                            headers: template.headers.split(',').filter(header => {
                                if (header) return true;
                                return false;
                            })
                        });
                    }
                });
            }
        } catch(e) { /* ... */ }
        try {
            if (this.props.action === "new_testing" && this.state.label.length) {
                const el = document.getElementById("inputURL");
                if (el) {
                    el.focus();
                }
            } else {
                const el = document.getElementById("inputLabel");
                if (el) {
                    el.focus();
                }
            }
        } catch (e) { /* ... */ }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("RightModal.componentWillUnmount()");
        try {
            // @ts-ignore
            document.getElementsByClassName("wrapper")[0].style.overflow = "auto";
        } catch(e) { /* ... */ }
    }
    //------------------------------------------------------------------------------------------------------------------
    validate() {
        document["handler"].log("RightModal.validate()");
        let flag = true;
        let delay = 0;
        if (!this.state.label.trim().length) {
            setTimeout(() => showError("Label is not defined"), delay);
            delay += 100;
            const el = document.getElementById("inputLabel");
            el.style.border = "#f00 1px solid";
            flag = false;
        }
        if (this.state.target === 0) {
            if (!this.state.url.trim().length) {
                setTimeout(() => showError("Target URL is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputURL");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
            if (!parseInt(String(this.state.port))) {
                setTimeout(() => showError("Target port is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputPort");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
        } else {
            if (!this.state.host.trim().length) {
                setTimeout(() => showError("Target host is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputHost");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
            if (!parseInt(String(this.state.port))) {
                setTimeout(() => showError("Target port is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputPort");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
            if (!this.state.links.trim().length) {
                setTimeout(() => showError("Target links is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputLinks");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
        }
        const min = parseInt(String(this.state.min));
        const limit = this.state.port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
        if (min <= 0) {
            setTimeout(() => showError("Number of requests can't be less than 1"), delay);
            delay += 100;
            const el = document.getElementById("inputMin");
            el.style.border = "#f00 1px solid";
            flag = false;
        } else if (min > limit) {
            setTimeout(() => showError("Number of requests can't exceed the max "+limit+". Add nodes or reduce amount of requests"), delay);
            delay += 100;
            const el = document.getElementById("inputMin");
            el.style.border = "#f00 1px solid";
            flag = false;
        }
        if (this.state.cluster === 1) {
            if (!this.state.nodes) {
                setTimeout(() => showError("Number of nodes is not defined"), delay);
                delay += 100;
                const el = document.getElementById("inputNodes");
                el.style.border = "#f00 1px solid";
                flag = false;
            } else if (this.state.nodes > 3) {
                setTimeout(() => showError("Number of nodes can't exceed the max 3 now"), delay);
                delay += 100;
                const el = document.getElementById("inputNodes");
                el.style.border = "#f00 1px solid";
                flag = false;
            }
        }
        return flag;
    }
    //------------------------------------------------------------------------------------------------------------------
    startTesting() {
        document["handler"].log("RightModal.startTesting()");
        if (this.validate()) {
            const tps = parseInt(String(this.state.tps));
            const min = parseInt(String(this.state.min));
            const post = this.state.post.toString().trim();
            let headers = "";
            this.state.headers.forEach(header => {
                if (header) {
                    headers += header + ",";
                }
            });
            let host;
            let links;
            if (this.state.target === 0) {
                host = this.state.url.trim().toLowerCase();
                if (this.state.url[this.state.url.length-1] === "/") {
                    host = this.state.url.substr(0, this.state.url.length-1);
                }
                links = '/,';
            } else {
                host = this.state.host.trim().toLowerCase();
                links = this.state.links.trim().toLowerCase();
            }
            const test = {
                label: this.state.label.toString().trim(),
                request: this.state.request.toString().trim(),
                host: host.trim(),
                port: this.state.port,
                tps: tps,
                min: min,
                links: links.trim(),
                nodes: this.state.cluster > 0 ? this.state.nodes : 1,
                headers: headers,
                post: post
            };
            this.setState({
                loading: true
            });
            document["dataSource"].createTest(
                test.label,
                test.request,
                test.host,
                test.port,
                test.tps,
                test.min,
                test.links,
                test.nodes,
                test.headers,
                test.post
            ).then(result => {
                this.setState({
                    loading: false
                });
                if (result) {
                    setTimeout(() => {
                        showSuccess(`New load testing is successfully created`);
                        this.props.reloadTestings();
                        this.props.hideRightModal();
                        setTimeout(e => showMessage(result && result.length ? result : "Load testing will be started within 30 seconds"), 500);
                    }, 500);
                }
            }).catch(reason => {
                this.setState({
                    loading: false
                });
               if (
                   reason == "To conduct this load testing, you must confirm the rights to the domain" ||
                   reason == "The domain name is already assigned to another user" ||
                   reason == "To conduct this load testing, you must verify this domain rights"
               ) {
                   if (document["dataSource"].domain) {
                       host = document["dataSource"].domain;
                       document["dataSource"].domain = null;
                   }
                   let domain = extractHostname(host.trim().toLowerCase());
                   this.setState({
                       dialog: {
                           text: reason,
                           action: () => {
                               this.props.showDomainConfirmation(
                                   domain,
                                   () => {
                                       this.startTesting.apply(this)
                                   }
                               )
                           }
                       }
                   });
               } else if (reason == "There are not enough tokens on your account balance to conduct this load testing") {
                   this.setState({
                       dialog: {
                           text: `Do you want to purchase the required tokens to conduct this load testing?`,
                           action: () => {
                               document["history"].push("/payment");
                           }
                       }
                   });
               }
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    saveAsTemplate() {
        document["handler"].log("RightModal.saveAsTemplate()");
        if (!this.state.label.length) {
            showError("Label is not defined");
            const el = document.getElementById("inputLabel");
            el.style.border = "#f00 1px solid";
        } else {
            const tps = parseInt(String(this.state.tps));
            const min = parseInt(String(this.state.min));
            const post = this.state.post.toString().trim();
            let headers = "";
            this.state.headers.forEach(header => {
                if (header) {
                    headers += header + ",";
                }
            });
            let host = "";
            let links = "";
            if (this.state.target === 0) {
                host = this.state.url.trim().toLowerCase();
                if (this.state.url[this.state.url.length-1] === "/") {
                    host = this.state.url.substr(0, this.state.url.length-1);
                }
                links = '/,';
            } else {
                host = this.state.host.trim().toLowerCase();
                links = this.state.links.trim().toLowerCase();
            }
            this.setState({
                loading: true
            });
            document["dataSource"].createTemplate(
                this.state.label.toString(),
                this.state.request.toString(),
                host,
                this.state.port,
                tps,
                min,
                links,
                this.state.cluster > 0 ? this.state.nodes : 1,
                headers,
                post
            ).then(result => {
                this.setState({
                    loading: false
                });
                if (result) {
                    setTimeout(() => {
                        this.props.hideRightModal();
                    }, 300);
                    setTimeout(() => {
                        showSuccess(`New template is successfully created.`);
                        this.props.hideRightModal();
                    }, 300);
                }
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    saveTemplate() {
        document["handler"].log("RightModal.saveTemplate()");
        if (!this.state.label.length) {
            showError("Label is not defined");
            const el = document.getElementById("inputLabel");
            el.style.border = "#f00 1px solid";
        } else {
            const tps = parseInt(String(this.state.tps));
            const min = parseInt(String(this.state.min));
            const post = this.state.post.toString().trim();
            let headers = "";
            this.state.headers.forEach(header => {
                if (header) {
                    headers += header + ",";
                }
            });
            let host = "";
            let links = "";
            if (this.state.target === 0) {
                host = this.state.url.trim().toLowerCase();
                if (this.state.url[this.state.url.length - 1] === "/") {
                    host = this.state.url.substr(0, this.state.url.length - 1);
                }
                links = '/,';
            } else {
                host = this.state.host.trim().toLowerCase();
                links = this.state.links.trim().toLowerCase();
            }
            this.setState({
                loading: true
            });
            if (this.props.template_id > 0) {
                document["dataSource"].updateTemplate(
                    this.props.template_id,
                    this.state.label.toString(),
                    this.state.request.toString(),
                    host,
                    this.state.port,
                    tps,
                    min,
                    links,
                    this.state.cluster > 0 ? this.state.nodes : 1,
                    headers,
                    post
                ).then(result => {
                    this.setState({
                        loading: false
                    });
                    if (result) {
                        setTimeout(() => {
                            this.props.hideRightModal();
                        }, 300);
                        setTimeout(() => {
                            showSuccess(`Template configuration is successfully updated.`);
                            this.props.reloadTemplates();
                            this.props.hideRightModal();
                        }, 300);
                    }
                });
            } else {
                document["dataSource"].createTemplate(
                    this.state.label.toString(),
                    this.state.request.toString(),
                    host,
                    this.state.port,
                    tps,
                    min,
                    links,
                    this.state.cluster > 0 ? this.state.nodes : 1,
                    headers,
                    post
                ).then(result => {
                    this.setState({
                        loading: false
                    });
                    if (result) {
                        setTimeout(() => {
                            this.props.hideRightModal();
                        }, 300);
                        setTimeout(() => {
                            showSuccess(`New template is successfully created.`);
                            if (window.location.toString().indexOf("app/templates") < 0) {
                                document["history"].push("/app/templates");
                            } else {
                                document["dataSource"].getTemplates();
                            }
                            this.props.hideRightModal();
                        }, 300);
                    }
                });
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        let details;
        details = (
            <div className="spinner" style={{ paddingTop: "35%" }}>
                <img src={spinner} alt="Загрузка" />
            </div>
        );
        if (!this.state.loading) {
            details = (
                <div>
                    <div className="infoBlock">
                        Label<br/>
                        <input
                            id="inputLabel"
                            type="text"
                            value={ this.state.label }
                            onChange={e => {
                                this.setState({ label: e.target.value });
                                e.target.style.border = "#c0c0c0 1px solid";
                            }}
                        />
                        <div className="h10" />
                        Target<br/>
                        <select onChange={e => this.setState({ target: parseInt(e.target.value) })}>
                            <option value="0" selected={ this.state.target === 0 ? true : false }>Single URL</option>
                            <option value="1" selected={ this.state.target === 1 ? true : false }>Multiple URLs</option>
                        </select>
                        <div className="h10" />
                        Method<br/>
                        <select onChange={e => this.setState({ request: e.target.value })}>
                            <option selected={this.state.request === "GET" ? true : false}>GET</option>
                            <option selected={this.state.request === "POST" ? true : false}>POST</option>
                        </select>
                        <div className="h10" />
                        {
                            this.state.target === 0 &&
                            (
                                <div>
                                    URL<br/>
                                    <input
                                        id="inputURL"
                                        placeholder="https://example.com/"
                                        type="text"
                                        value={this.state.url}
                                        onChange={e => {
                                            let url = e.target.value;
                                            let port = this.state.port;
                                            let value = this.state.min;
                                            if (e.target.value.indexOf("https") >= 0) {
                                                port = 443;
                                            }
                                            const limit = port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
                                            if (value > limit) {
                                                value = limit;
                                            }
                                            this.setState({
                                                port: port,
                                                url: url,
                                                min: value
                                            });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    <div className="h10" />
                                    Port<br/>
                                    <input
                                        id="inputPort"
                                        placeholder="80"
                                        type="number"
                                        min="1"
                                        step="1"
                                        value={this.state.port}
                                        onChange={e => {
                                            let port = parseInt(e.target.value);
                                            let value = this.state.min;
                                            const limit = port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
                                            if (value > limit) {
                                                value = limit;
                                            }
                                            this.setState({
                                                port: port,
                                                min: value
                                            });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    <div className="h10" />
                                </div>
                            )
                        }
                        {
                            this.state.target === 1 &&
                            (
                                <div>
                                    Host<br/>
                                    <input
                                        id="inputHost"
                                        placeholder="example.com"
                                        type="text"
                                        value={this.state.host}
                                        onChange={e => {
                                            let host = e.target.value;
                                            let port = this.state.port;
                                            let value = this.state.min;
                                            if (host.indexOf("https") >= 0) {
                                                port = 443;
                                            }
                                            const limit = port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
                                            if (value > limit) {
                                                value = limit;
                                            }
                                            this.setState({
                                                port: port,
                                                host: host,
                                                min: value
                                            });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    <div className="h10" />
                                    Port<br/>
                                    <input
                                        id="inputPort"
                                        placeholder="80"
                                        type="number"
                                        min="1"
                                        step="1"
                                        value={this.state.port}
                                        onChange={e => {
                                            let port = this.state.port;
                                            let value = this.state.min;
                                            const limit = port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
                                            if (value > limit) {
                                                value = limit;
                                            }
                                            this.setState({
                                                port: port,
                                                min: value
                                            });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    <div className="h10" />
                                    Links<br/>
                                    <textarea
                                        id="inputLinks"
                                        value={this.state.links}
                                        style={{ lineHeight: "1.2" }}
                                        onChange={e => {
                                            this.setState({ links: e.target.value });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    <div className="h10" />
                                </div>
                            )
                        }
                        {
                            this.state.request === "POST" &&
                            <>
                                Request body<br/>
                                <textarea
                                    id="postBody"
                                    placeholder=""
                                    rows={1}
                                    value={this.state.post}
                                    style={{ lineHeight: "1.2" }}
                                    onChange={e => {
                                        this.setState({ post: e.target.value });
                                        e.target.style.border = "#c0c0c0 1px solid";
                                    }}
                                />
                                <div className="h10" />
                            </>
                        }
                        {
                            <>
                                Headers<br/>
                                <div>
                                    <input
                                        id="header"
                                        placeholder=""
                                        type="text"
                                        value={`User-Agent: <${this.props.email}> via https://loadest.nodes-studio.com`}
                                        disabled={true}
                                        style={{
                                            width: `calc(100% - ${ this.state.headers.length ? '0px' : '45px' })`
                                        }}
                                        onChange={e => {
                                            this.setState({ headers: [e.target.value] });
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        }}
                                    />
                                    {
                                        !this.state.headers.length &&
                                        <CustomButton
                                            fill
                                            simple
                                            pullRight
                                            bsStyle="info"
                                            onClick={e => {
                                            this.setState({
                                                headers: this.state.headers.concat([""])
                                            })
                                        }}>+</CustomButton>
                                    }
                                </div>
                                {
                                    this.state.headers.map((header, index) => {
                                        return (
                                            <div style={{ paddingTop: "10px" }}>
                                                <input
                                                    id={"header"+index}
                                                    placeholder=""
                                                    type="text"
                                                    value={header}
                                                    style={{
                                                        width: `calc(100% - ${index !== this.state.headers.length - 1 ? "45px" : "88px"})`
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            headers:
                                                                this.state.headers.map((header, idx) => {
                                                                    if (idx === index) {
                                                                        return e.target.value;
                                                                    }
                                                                    return header
                                                                })
                                                        });
                                                        e.target.style.border = "#c0c0c0 1px solid";
                                                    }}
                                                />
                                                {
                                                    index === this.state.headers.length - 1 &&
                                                    <CustomButton
                                                        fill
                                                        simple
                                                        pullRight
                                                        bsStyle="info"
                                                        style={{
                                                            width: "40px",
                                                            marginLeft: "4px"
                                                        }}
                                                        onClick={e => {
                                                            this.setState({
                                                                headers: this.state.headers.concat([""])
                                                            })
                                                        }}>
                                                        +
                                                    </CustomButton>
                                                }
                                                <CustomButton
                                                    fill
                                                    simple
                                                    pullRight
                                                    bsStyle="danger"
                                                    style={{
                                                        width: "40px"
                                                    }}
                                                    onClick={e => {
                                                    this.setState({
                                                        headers: this.state.headers.filter((item, idx) => idx !== index)
                                                    })
                                                }}>
                                                    -
                                                </CustomButton>
                                            </div>
                                        )
                                    })
                                }
                                <div className="h10" />
                            </>
                        }
                        <div>
                            Number of nodes<br/>
                            <input
                                id="inputNodes"
                                type="number"
                                min="1"
                                max="10"
                                placeholder="1 - 10"
                                value={this.state.nodes}
                                onChange={e => {
                                    let nodes = parseInt(e.target.value);
                                    if (nodes > 10) {
                                        nodes = 10;
                                    }
                                    let port = this.state.port;
                                    let value = this.state.min;
                                    const limit = port === 443 ? nodes * 10000 : nodes * 100000;
                                    if (value > limit) {
                                        value = limit;
                                    }
                                    this.setState({
                                        min: value,
                                        nodes: nodes,
                                    });
                                    e.target.style.border = "#c0c0c0 1px solid";

                                    e.target.style.border = "#c0c0c0 1px solid";
                                }}
                            />
                            <div className="h10" />
                        </div>

                        <div>
                            Threads / node<br/>
                            <input
                                id="inputThreads"
                                type="number"
                                value={this.state.tps}
                                min="1"
                                max="1000"
                                placeholder="1 - 1000"
                                onChange={e => {
                                    this.setState({ tps: parseInt(e.target.value) });
                                    e.target.style.border = "#c0c0c0 1px solid";
                                }}
                            />
                            <div className="h10" />
                        </div>
                        <div>
                            Number of requests<br/>
                            <input
                                id="inputMin"
                                placeholder={"1 - " + (this.state.port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000)}
                                type="number"
                                min="1"
                                max={this.state.port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000}
                                step="1"
                                value={this.state.min}
                                onChange={e => {
                                    let value = parseInt(e.target.value);
                                    let port = this.state.port;
                                    const limit = port === 443 ? this.state.nodes * 10000 : this.state.nodes * 100000;
                                    if (value > limit) {
                                        value = limit;
                                    }
                                    this.setState({
                                        min: value
                                    });
                                    e.target.style.border = "#c0c0c0 1px solid";
                                }}
                            />
                            <div className="h10" />
                        </div>
                    </div>
                </div>
            );
        }
        let fout = [];
        fout.push(
            <div key="modalBackground" className="modalBackground">&nbsp;</div>
        );
        fout.push(
            <div key="RightModal" className="RightModal" >
                <div className="header">{
                    this.props.action === "edit_template" &&
                    <span>
                        Edit template
                    </span>
                }
                {
                    this.props.action === "new_template" &&
                    <span>
                        New template
                    </span>
                }
                {
                    this.props.action === "new_testing" &&
                    <span>
                        New testing
                    </span>
                } </div>
                <div className="modal_details">
                    <div className="modal_content">{ details } </div>
                </div>
                {
                    (
                        this.props.action === "new_testing"
                    ) &&
                    <CustomButton
                        bsStyle="primary"
                        className="saveButton btn-fill pull-right" style={{ marginRight:"15px" }}
                        onClick={() => {
                            document["handler"].info("RightModal.CustomButton(Start testing).click()");
                            this.setState({
                                dialog: {
                                    text: <div>
Attention!
<br/>
<div style={{ fontSize: 16, textAlign: "left", paddingTop: 25 }}>
You are about to start load testing, what may cause a temporary performance issues or unavailability of target endpoint.
</div>
<div style={{ fontSize: 16, textAlign: "left", paddingTop: 15 }}>
In addition, up to {parseInt(String(this.state.min))/1000} token(s) will be deducted from your account balance after testing (depending on the actual load).
</div>
                                    </div>,
                                    action: () => {
                                        this.startTesting.apply(this)
                                    }
                                }
                            });
                        }
                    }>
                        Start
                    </CustomButton>
                }
                {
                    (
                        this.props.action === "new_testing"
                    ) &&
                    <CustomButton bsStyle="info" pullRight className="saveButton btn-fill pull-right" onClick={()=>{
                        document["handler"].info("RightModal.CustomButton(Save as template).click()");
                        this.saveAsTemplate();
                    }}>
                        Save
                    </CustomButton>
                }
                {
                    (
                        this.props.action === "new_template" ||
                        this.props.action === "edit_template"
                    ) &&
                    <CustomButton bsStyle="info" pullRight className="saveButton btn-fill pull-right" style={{ marginRight:"15px" }} onClick={()=>{
                        document["handler"].info("RightModal.CustomButton(Save template).click()");
                        this.saveTemplate();
                    }}>
                        Save
                    </CustomButton>
                }
                <CustomButton bsStyle="danger" pullRight fill onClick={() => {
                    document["handler"].info("RightModal.CustomButton(Cancel).click()");
                    this.props.confirm("Are you sure?", "Please confirm your intention to quit without saving changes.", () => {
                        this.props.hideRightModal();
                        this.props.hideModal();
                    });
                }}>
                    Cancel
                </CustomButton>
                {
                    this.state.dialog &&
                    <Dialog
                        open={true}
                        keepMounted
                        onClose={() => this.setState({
                            ...this.state,
                            dialog: null,
                        })}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                    >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                        >
                            <h1 style={{
                                display: "block",
                                textAlign: "center",
                                fontSize: "24px",
                                fontFamily: "Open Sans",
                                marginTop: "20px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                lineHeight: "1.5"
                            }}>
                                { this.state.dialog.text }
                            </h1>
                        </DialogTitle>
                        <DialogActions >
                            <Button
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        dialog: null
                                    })
                                }}
                                color="danger"
                                simple
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    this.state.dialog.action();
                                    this.setState({
                                        ...this.state,
                                        dialog: null
                                    })
                                }}
                                color="transparent"
                                simple
                            >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </div>
        );
        return fout;
    }
}

export default RightModalComponent;
