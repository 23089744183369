/**
 * Loadest - Dashboard root component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import intToTime from "../../functions/intToTime";
import {Bar} from "react-chartjs-2";
import {Col, Grid, Row} from "react-bootstrap";
import {IDashboardProps, IDashboardState} from "./Dashboard.interface";
import {Card} from "../../components/Card/Card";
import "./Dashboard.css";
import { ERROR_CODE, TASK_STATE } from "../../constants";

import Button from "../../components/Button/Button";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import showSuccess from "../../functions/showSuccess";
import showError from "../../functions/showError";

class DashboardComponent extends React.PureComponent<IDashboardProps, IDashboardState>{
    renderId: number;
    interval: number;
    onResize: () => void;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props){
        super(props);
        document["handler"].log("DashboardComponent.constructor()");
        this.renderId = 0;
        this.state = {
            loaded: false,
            expanded: false
        }
        this.onResize = () => {
            this.forceUpdate();
            this.props.updateParent(true);
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("DashboardComponent.componentDidMount()");
        setTimeout(()=>{this.props.updateParent(true)}, 0);
        document.title = 'Loadest / Dashboard';
        if (!this.state.loaded) {
            this.props.load(result => {
                window.addEventListener("resize", () => this.onResize());
                setTimeout(() => {
                    this.setState({
                        loaded: result
                    });
                    this.interval = window.setInterval(() => this.props.load((result) => {
                        this.setState({
                            loaded: result
                        });
                    }), 10000);
                }, 300);
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("DashboardComponent.componentWillUnmount()");
        window.clearInterval(this.interval);
        this.onResize = () => {}
        this.props.updateParent(false);
    }
    //------------------------------------------------------------------------------------------------------------------
    pauseTesting(id) {
        document["handler"].log(`DashboardComponent.pauseTesting(${id})`);
        document["dataSource"].pauseTesting(id).then(result => {
            if (result) {
                showSuccess("Testing is paused");
                this.props.load(result => {
                    this.setState({
                        loaded: result
                    });
                });
            } else {
                showError("An error occurred while pausing the testing");
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    continueTesting(id) {
        document["handler"].log(`DashboardComponent.continueTesting(${id})`);
        document["dataSource"].continueTesting(id).then(result => {
            if (result) {
                showSuccess("Testing is continued");
                this.props.load(result => {
                    this.setState({
                        loaded: result
                    });
                });
            } else {
                showError("An error occurred while reactivating the testing");
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("DashboardComponent.render() №"+(++this.renderId));
        const prop = this.props.testing;
        let status = '';
        let started = '—';
        let info;
        const key = 1;
        const labels = [];
        const values = [];
        const response = [];
        const datasets = [];
        const yAxes = [];
        const isData = this.props.testing && this.props.testing.id;
        let isResponseChart = false;
        let isRequestChart = false;
        if (prop) {
            if (!prop.error_code) {
                status = TASK_STATE[prop.status];
            } else {
                status = ERROR_CODE[prop.error_code];
            }
            let duration = '—';
            if(prop.result && prop.result.length > 1) {
                duration = intToTime((
                    (new Date(prop.result[prop.result.length - 1].date).getTime()) -
                    (new Date(prop.result[0].date).getTime())
                ) / 1000);
            }
            let avarge_response = 0;
            if (prop.result) {
                prop.result.forEach((item) => {
                    if (item.response_time > 0) {
                        isResponseChart = true;

                        if (item.requests_per_minute > 0) {
                            isRequestChart = true;
                        }
                    }
                });
            }
            if (prop.result) {
                prop.result.forEach((item) => {
                    if (isResponseChart) {
                        if (item.response_time > 0 && item.requests_per_minute > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                            response.push(item.response_time);
                        }
                    } else {
                        if (item.requests_per_minute > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                        }
                    }
                });
                if (prop.result && prop.result.length && prop.result[0].date) {
                    started = new Date(prop.result[0].date).toLocaleString();
                }
                prop.result.forEach(item => {
                    avarge_response += item.response_time;
                });
                if (prop.result.length) {
                    avarge_response /= prop.result.length;
                }
            }
            let links = [];
            prop.links.split(',').forEach(link => {
                if (link.trim().length) {
                    let url = '';
                    if (prop.port === 443) {
                        url = 'https://'+prop.host+link;
                    } else {
                        url = 'http://'+prop.host+link;
                    }
                    links.push(<a href={url} target="_blank">{link}</a>);
                    links.push(<br/>);
                }
            });
            let loader_errors = prop.loader_errors ? prop.loader_errors.toFixed(2) : 0;
            if (loader_errors >= 1000000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000000) + "M";
            } else if (loader_errors >= 1000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000) + "K";
            }
            let spider_errors = prop.spider_errors > 0 ? prop.spider_errors.toFixed(2) : 0
            let percent = prop.percent_done ? (
                prop.percent_done > 100 ? 100 : prop.percent_done.toFixed(2)
            ) : 0;
            info = (
                <div style={{
                    textAlign: "left",
                    lineHeight: "2.7",
                    paddingLeft: "20px",
                    paddingBottom: "12px",
                    overflow: "hidden",
                    marginTop: "-8px",
                    paddingRight: "10px",
                }}>
                    <table style={{ width: "100%" }}>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td className="selectable" style={{ paddingRight: "15px", minWidth: "90px" }}>Label</td>
                            <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0, width: "70%" }}>{ prop.label }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td className="selectable" style={{ paddingRight: "15px" }}>Status</td>
                            <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{
                                status + (TASK_STATE[prop.status] === "In progress" ? " ("+percent+"%)" : "")
                            }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td className="selectable" style={{ paddingRight: "15px" }}>Started</td>
                            <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ started }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td className="selectable" style={{ paddingRight: "15px" }}>Duration</td>
                            <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ duration }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td className="selectable" style={{ paddingRight: "15px" }}>Host</td>
                            <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0, whiteSpace: "break-spaces", wordBreak: "break-all" }}>{ prop.host }</td>
                        </tr>
                        {
                            (this.state.expanded || window.innerWidth > 991) &&
                            [
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ paddingRight: "15px" }}>Target</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.ip }:{ prop.port }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ paddingRight: "15px" }}>Method</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.request }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ paddingRight: "15px" }}>Load type</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ isData ? "Linear bump" : '' }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ paddingRight: "15px" }}>Threads</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.threads_per_node }x{ prop.nodes_assigned }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ padding: "12px", paddingRight: "15px", paddingLeft: "0px", lineHeight: "1.0" }}>Number of requests</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>
                                        { prop.max }
                                    </td>
                                </tr>,
                                <tr
                                    style={{ borderBottom: "#efefef 1px dotted" }}
                                >
                                    <td className="selectable" style={{
                                            padding: "12px",
                                            paddingRight: "15px",
                                            paddingLeft: "0px",
                                            lineHeight: "1.0"
                                        }}
                                    >Actually sent</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }} title="Successfully sent (Total attempts)">
                                        <span style={{ cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                            { prop.requests_done > 0 ? prop.requests_done : 0 }
                                            { prop.requests_total > 0 ? " (" + prop.requests_total + ")" : "" }
                                        </span>
                                    </td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td className="selectable" style={{ padding: "10px", paddingRight: "15px", paddingLeft: "0px", lineHeight: "1.0" }}>Average response</td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }}>{ avarge_response.toFixed(2) } sec.</td>
                                </tr>,
                                <tr
                                    style={{ borderBottom: "#efefef 1px dotted" }}
                                >
                                    <td className="selectable" style={{ paddingRight: "15px" }}>
                                        Errors
                                    </td>
                                    <td className="selectable" style={{ fontWeight: 500, lineHeight: 1.0 }} title="Client errors / Agent errors">
                                        <span style={{ cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                            { spider_errors }% / { loader_errors }%
                                        </span>
                                    </td>
                                </tr>,
                                <tr style={{ borderBottom: window.innerWidth < 991 ? "#efefef 1px dotted" : "" }}>
                                    <td className="selectable" style={{ paddingRight: "15px" }}>Link(s)</td>
                                    <td className="selectable" style={{
                                        fontWeight: 500,
                                        lineHeight: 1.0,
                                        whiteSpace: "break-spaces",
                                        wordBreak: "break-all",
                                        padding: "10px"
                                    }}>{ links }</td>
                                </tr>
                            ]
                        }
                        {
                            window.innerWidth < 991 &&
                            (
                                <tr>
                                    <td colSpan={2} style={{
                                        padding: "12px",
                                        paddingRight: "15px",
                                        textAlign: "center",
                                        lineHeight: "1.0"
                                    }}>
                                        <a onClick={() => {
                                            this.setState({
                                                expanded: !this.state.expanded
                                            })
                                        }}>{
                                            this.state.expanded ? "Hide" : "Show"
                                        } detailed testing information</a>
                                    </td>
                                </tr>
                            )
                        }
                    </table>
                </div>
            );
            if (isRequestChart) {
                datasets.push({
                    label: 'Target response time',
                    type: 'line',
                    fill: false,
                    borderColor: '#3bc6ca',
                    backgroundColor: '#3bc6ca',
                    borderWidth: 2,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    data: response,
                    yAxisID: `y-axis-1-${key}`
                });
                yAxes.push(
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: `y-axis-1-${key}`,
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Target response time (sec)'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        }
                    }
                );
            }
            datasets.push({
                label: 'Requests per minute',
                type: 'line',
                fill: false,
                borderColor: '#ffc904',
                backgroundColor: '#ffc904',
                borderWidth: 2,
                pointRadius: 1,
                pointHoverRadius: 2,
                data: values,
                yAxisID: `y-axis-2-${key}`
            });
            yAxes.push({
                type: 'linear',
                display: true,
                position: 'right',
                id: `y-axis-2-${key}`,
                labels: {
                    show: true
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Requests per minute'
                },
            });
        }
        let button = null;
        if (
            prop &&
            TASK_STATE[prop.status] !== "Completed" &&
            TASK_STATE[prop.status] !== "Paused" &&
            TASK_STATE[prop.status] !== "Terminated"
        ) {
            button = (
                <Button
                    color="primary"
                    className="btn newTest extend"
                    round
                    disabled={prop === null}
                    onClick={e => {
                        this.pauseTesting(prop.id)
                    }}
                >
                    <i className="pe-7s-gleam" style={{
                        fontWeight: "bold",
                        paddingLeft: "3px",
                        paddingRight: "7px"
                    }}/>
                    <span className="btnText">
                        Pause testing &nbsp;
                    </span>
                </Button>
            )
        } else if ( prop && TASK_STATE[prop.status] === "Paused" ) {
            button = (
                <Button
                    color="primary"
                    className="btn newTest extend"
                    round
                    disabled={prop === null}
                    onClick={e => {
                        this.continueTesting(prop.id)
                    }}
                >
                    <i className="pe-7s-gleam" style={{
                        fontWeight: "bold",
                        paddingLeft: "3px",
                        paddingRight: "7px"
                    }}/>
                    <span className="btnText">
                        Continue testing &nbsp;
                    </span>
                </Button>
            )
        } else {
            button = (
                <Button
                    color="primary"
                    round
                    className="btn newTest extend"
                    disabled={!prop }
                    onClick={e => {
                        if (!this.props.templatesLoaded) {
                            this.props.loadTemplates(() => {
                                this.props.newTest(this.props.templateList);
                            });
                        } else {
                            this.props.newTest(this.props.templateList);
                        }
                    }}
                >
                    <i className="pe-7s-gleam" style={{
                        fontWeight: "bold",
                        paddingLeft: "3px",
                        paddingRight: "7px"
                    }}/>
                    <span className="btnText">
                        New testing &nbsp;
                    </span>
                </Button>
            );
        }
        return (
            <div className="content dashboard">
                <Grid fluid>
                    <Row>
                        {
                            this.state.loaded && isData?
                                <Col md={8}>
                                    <Card
                                        statsIcon="fa fa-history"
                                        id="chartHours"
                                        content={
                                            <div style={{ marginTop: "-30px"}}>
                                            <div className="h4">
                                                {
                                                    this.state.loaded ? (
                                                        status && status.length ? (
                                                            "Load testing is " + status.toLowerCase()
                                                        ) : 'Load testing'
                                                    ) : "Load testing dashboard"
                                                }
                                            </div>
                                            <div className="data">
                                                {
                                                    button
                                                }
                                                {
                                                    prop &&
                                                    <Bar
                                                        redraw={false}
                                                        height={400}
                                                        data={{
                                                            labels: labels,
                                                            datasets: datasets
                                                        }}
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            tooltips: {
                                                                mode: 'label'
                                                            },
                                                            elements: {
                                                                line: {
                                                                    fill: false
                                                                }
                                                            },
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        display: true,
                                                                        gridLines: {
                                                                            display: true
                                                                        },
                                                                        scaleLabel: {
                                                                            display: true,
                                                                            labelString: isResponseChart ?
                                                                                'Ratio of the load level to the response rate' :
                                                                                'The chart of the load level'
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: yAxes
                                                            }
                                                        }}
                                                    />
                                                }
                                            </div>
                                            </div>
                                        }
                                    />
                                </Col> :
                                <Col md={12} >
                                    <Card
                                        hCenter
                                        ctTableResponsive
                                        ctTableUpgrade
                                        className="TestingList"
                                        content={
                                            <Grid fluid className="TestingsTable">
                                                <Row>
                                                    <Col md={10} mdOffset={1}>
                                                        <Card
                                                            plain
                                                            title={
                                                                "Load testing dashboard"
                                                            }
                                                            ctTableFullWidth
                                                            ctTableResponsive
                                                            content={
                                                                <div>
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn newTest"
                                                                        round
                                                                        disabled={prop === null || prop && prop.status < 5}
                                                                        onClick={e => {
                                                                            if (!this.props.templatesLoaded) {
                                                                                this.props.loadTemplates(() => {
                                                                                    this.props.newTest(
                                                                                        this.props.templateList,

                                                                                    );
                                                                                });
                                                                            } else {
                                                                                this.props.newTest(this.props.templateList);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="pe-7s-gleam" style={{
                                                                            fontWeight: "bold",
                                                                            paddingLeft:"3px",
                                                                            paddingRight:"7px"
                                                                        }} />
                                                                        <span className="btnText">
                                                                            New testing &nbsp;
                                                                        </span>
                                                                    </Button>
                                                                    <br/>
                                                                    {
                                                                        this.state.loaded ?
                                                                        (
                                                                            <div className="notFound">
                                                                                <i className="pe-7s-gleam emptyState"/>
                                                                                <br/>
                                                                                <br/>
                                                                                {
                                                                                    this.props.testing ?
                                                                                        <span>Loading. Please wait..</span>:
                                                                                        <span>System is idle</span>
                                                                                }

                                                                            </div>
                                                                        ) : (
                                                                            <div className="loader app-loader">
                                                                                <img height={150} src={spinner} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        }
                                    />
                                </Col>
                        }
                        {
                            this.state.loaded && isData &&
                            <Col md={4}>
                                <Card content={info} />
                            </Col>
                        }
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default DashboardComponent;
