/**
 * Loadest - AdminNavbarLinks component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Nav, MenuItem } from "react-bootstrap";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import "./AdminNavbar.css";

const AdminNavbarLinks: React.FunctionComponent = (props: { user:any, logout: () => any, children?: React.ReactNode }) => {
    document["handler"].log("AdminNavbarLinks.render()");
    const notification = (
        <div>
            <i className="fa fa-globe" />
            <b className="caret" />
            <span className="notification">2</span>
            <p className="hidden-lg hidden-md">Notifications</p>
        </div>
    );
    const [logoutDialog, setLogoutDialog] = React.useState(false);
    const dialog = (
        <Dialog
            open={logoutDialog}
            keepMounted
            onClose={() => setLogoutDialog(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
            >
                <h1 style={{
                    fontSize: "21px",
                    marginTop: "40px",
                    minWidth: "240px",
                    textAlign: "center",
                }}>Logout now?</h1>
            </DialogTitle>
            <DialogActions >
                <Button
                    onClick={() => {
                        props.logout();
                        document["history"].push('/');
                    }}
                    color="transparent"
                    simple
                >
                    Yes
                </Button>
                <Button
                    onClick={() => { setLogoutDialog(false); }}
                    color="danger"
                    simple
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
    /*
    <NavItem eventKey={2} href="#" style={{ width:"64px" }}>
        <i className="fa fa-search" />
        <p className="hidden-lg hidden-md">Search</p>
    </NavItem>
     */
    return (
        <div>
            {
                /*

            <Nav>
              <NavItem eventKey={1}>
                <i className="" />
                <p className="hidden-lg hidden-md"></p>
              </NavItem>
            </Nav>

                */
            }
            <Nav pullRight>
                {
                    /*
                <NavDropdown
                    eventKey={3}
                    title={notification}
                    noCaret
                    id="basic-nav-dropdown"
                >
                    <MenuItem eventKey={4.1}>Notification 1</MenuItem>
                    <MenuItem eventKey={4.2}>Notification 2</MenuItem>
                </NavDropdown>
                     */
                }
                {
                    props.user &&
                    props.user.name &&
                    <MenuItem
                        title="Account token balance, click to charge"
                    >
                        <a
                            href={"/payment"}
                            style={{cursor: "pointer", paddingTop: 3}}
                            onClick={() => {
                                // @ts-ignore
                                window.location = "/payment";
                            }}
                        >
                            <div style={{fontSize: 17, paddingTop: 1, fontWeight: 400}}>
                                <i className="pe-7s-gleam" style={{
                                    position: "absolute",
                                    marginTop: "2px"
                                }}/><span style={{paddingLeft: 20}}>{
                                props.user && props.user.balance.toFixed(3)
                            }</span>
                            </div>
                        </a>
                    </MenuItem>
                }
                {
                    props.user &&
                    props.user.name &&
                    <MenuItem
                        onClick={() => {
                            setLogoutDialog(true);
                        }}
                        className="logoutBtn"
                        title="Logout"
                    >
                        <ExitToAppIcon className="exitIcon" />
                        <div className="exitText">Logout</div>
                    </MenuItem>
                }
                {
                    dialog
                }
            </Nav>
        </div>
    );
}

export default AdminNavbarLinks;
