/**
 * Loadest - функция валидации Email адреса
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

const isValidEmail = (email) => {
    var pattern = new RegExp('[-a-zA-Z0-9:%._\\+~#=]{2,256}\\@[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}.*?','i');
    return pattern.test(email);
}

export default isValidEmail;