/**
 * Loadest - TemplatesTable container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import TemplatesTable from "./TemplatesTable.component";
import * as actions from "../../actions";
import showSuccess from "../../functions/showSuccess";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        searchTemplatesPhrase: state.templates.searchTemplatesPhrase,
        templates: state.templates.templates,
        sortMethod: state.templates.sortMethod,
        sortKey: state.templates.sortKey
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        new: () => {
            dispatch(
                actions.showRightModal({
                    label: "",
                    action: "new_template"
                })
            );
        },
        edit: (template_id, label) => {
            dispatch(
                actions.showRightModal({
                    label: label,
                    template_id: template_id,
                    action: "edit_template"
                })
            );
        },
        delete: (template_id) => {
            dispatch(
                actions.showModal(
                    "Are you sure?",
                    "Please confirm your intention to remove this template from the list.",
                    () => {
                        document["dataSource"].deleteTemplate(template_id).then(res => {
                            if (res) {
                                showSuccess("Template is successfully deleted");
                                dispatch(
                                    actions.hideModal()
                                );
                                document["dataSource"].getTemplates();
                            }
                        });
                    }
                )
            )
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatesTable));
