/**
 * Loadest - DomainList component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { Card } from "../../components/Card/Card";
import {
    IDomainListProps,
    IDomainListState
} from "./DomainList.interface";
import "./DomainList.css";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import DomainsTable from "../../components/DomainsTable/DomainsTable";

class DomainListComponent extends React.PureComponent<IDomainListProps, IDomainListState> {
    renderId: number;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props){
        super(props);
        document["handler"].log("SettingsListComponent.constructor()");
        this.renderId = 0;
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("SettingsListComponent.componentDidMount()");
        document.title = 'Loadest / Domains';
        // if (!this.props.loaded) {
            this.props.load();
        // }
        setTimeout(()=>{this.props.updateParent(true)}, 0);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("SettingsListComponent.componentWillUnmount()");
        this.props.updateParent(false);
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("SettingsListComponent.render() №"+(++this.renderId));
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12} lg={12} xl={10} xlOffset={1}>
                            <Card
                                hCenter
                                ctTableResponsive
                                ctTableUpgrade
                                className="DomainList"
                                content={
                                    !this.props.loaded ?
                                    <div className="loader app-loader">
                                        <img height={150} src={spinner} />
                                    </div>
                                        :
                                    <DomainsTable reloadList={this.props.load} />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default DomainListComponent;
