/**
 * Loadest - TestingList component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import { Card } from "../../components/Card/Card";
import TestingsTable from "../../components/TestingsTable/TestingsTable";
import {
    ITestingListProps,
    ITestingListState
} from "./TestingList.interface";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import "./TestingList.css";

class TestingListComponent extends React.Component<ITestingListProps, ITestingListState> {
    renderId: number;
    interval: number;
    onResize: () => void;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props){
        super(props);
        document["handler"].log("TestingListComponent.constructor()");
        this.renderId = 0;
        this.state = {
            loaded: false
        }
        this.onResize = () => {
            this.forceUpdate();
            this.props.updateParent(false);
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("TestingListComponent.componentDidMount()");
        setTimeout(()=>{this.props.updateParent(true)}, 0);
        document.title = 'Loadest / Load Testing';
        if (!this.state.loaded) {
            this.props.load((result) => {
                window.addEventListener("resize", () => this.onResize());
                if (!this.props.templates.length) {
                    this.props.loadTemplates(result => {
                        this.setState({
                            loaded: result
                        });
                        this.interval = window.setInterval(() => this.props.load((result) => {
                            this.setState({
                                loaded: result
                            })
                        }), 10000);
                    })
                } else {
                    this.setState({
                        loaded: result
                    });
                    this.interval = window.setInterval(() => this.props.load((result) => {
                        this.setState({
                            loaded: result
                        })
                    }), 10000);
                }
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("TestingListComponent.componentWillUnmount()");
        this.onResize = () => {}
        window.clearInterval(this.interval);
        this.props.updateParent(false);
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("TestingListComponent.render() №"+(++this.renderId));
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col lg={12}>
                            <Card
                                hCenter
                                ctTableResponsive
                                ctTableUpgrade
                                className="TestingList"
                                content={
                                    !this.state.loaded ?
                                    <div className="loader app-loader">
                                        <img height={150} src={spinner} />
                                    </div> :
                                    <TestingsTable loaded={this.props.loaded} />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default TestingListComponent;
