/**
 * Loadest - Public testing details page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {Bar} from "react-chartjs-2";
import {Col, Grid, Row} from "react-bootstrap";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import "./DetailsPage.css";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
import Footer from "../../components/Footer/Footer";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import {ERROR_CODE, TASK_STATE} from "../../constants";
import intToTime from "../../functions/intToTime";
import {Card} from "../../components/Card/Card";

export default function DetailsPage(props) {
    document["handler"].log("DetailsPage.render()");
    document.title = 'Testing details';
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState<boolean|null>(null);
    const [state, setState] = useState({
        expanded: false
    });
    //@ts-ignore
    const classes = makeStyles(styles)();
    const { ...rest } = props;
    let fout;
    const error = <div style={{ textAlign: "center", paddingTop: 200, paddingBottom: 200 }}>Invalid testing ID</div>;
    const str = window.location.toString().split('details/');
    if (str && str.length > 1) {
        const id = window.location.toString().split('details/')[1];
        useEffect(() => {
            if (loaded === null) {
                setLoaded(false);
                document["dataSource"].getTesingInfo(id).then((res) => {
                    setTimeout(() => setLoaded(true), 1000);
                    setData(res);
                })
            }
        });
        const prop = data;
        let status = '';
        let started = '—';
        let info;
        const key = 1;
        const labels = [];
        const values = [];
        const response = [];
        const datasets = [];
        const yAxes = [];
        const isData = data && data.id;
        let isResponseChart = false;
        let isRequestChart = false;
        if (prop) {
            if (!prop.error_code) {
                status = TASK_STATE[prop.status];
            } else {
                status = ERROR_CODE[prop.error_code];
            }
            let duration = '—';
            if(prop.result && prop.result.length > 1) {
                duration = intToTime((
                    (new Date(prop.result[prop.result.length - 1].date).getTime()) -
                    (new Date(prop.result[0].date).getTime())
                ) / 1000);
            }
            let avarge_response = 0;
            if (prop.result) {
                prop.result.forEach((item) => {
                    if (item.response_time > 0) {
                        isResponseChart = true;

                        if (item.requests_per_minute > 0) {
                            isRequestChart = true;
                        }
                    }
                });
            }
            if (prop.result) {
                prop.result.forEach((item) => {
                    if (isResponseChart) {
                        if (item.response_time > 0 && item.requests_per_minute > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                            response.push(item.response_time);
                        }
                    } else {
                        if (item.requests_per_minute > 0) {
                            labels.push(item.date.substr(11, 8).replace('T', ' '));
                            values.push(item.requests_per_minute);
                        }
                    }
                });
                if (prop.result && prop.result.length && prop.result[0].date) {
                    started = new Date(prop.result[0].date).toLocaleString();
                }
                prop.result.forEach(item => {
                    avarge_response += item.response_time;
                });
                if (prop.result.length) {
                    avarge_response /= prop.result.length;
                }
            }
            let links = [];
            props && prop.links && prop.links.split(',').forEach(link => {
                if (link.trim().length) {
                    let url = '';
                    if (prop.port === 443) {
                        url = 'https://'+prop.host+link;
                    } else {
                        url = 'http://'+prop.host+link;
                    }
                    links.push(<a href={url} target="_blank">{link}</a>);
                    links.push(<br/>);
                }
            });
            let loader_errors = prop.loader_errors ? prop.loader_errors.toFixed(2) : 0;
            if (loader_errors >= 1000000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000000) + "M";
            } else if (loader_errors >= 1000) {
                // @ts-ignore
                loader_errors = parseInt(loader_errors / 1000) + "K";
            }
            let spider_errors = prop.spider_errors > 0 ? prop.spider_errors.toFixed(2) : 0
            let percent = prop.percent_done ? (
                prop.percent_done > 100 ? 100 : prop.percent_done.toFixed(2)
            ) : 0;
            info = (
                <div className="selectable" style={{
                    textAlign: "left",
                    lineHeight: "2.7",
                    paddingLeft: "20px",
                    paddingBottom: "12px",
                    overflow: "hidden",
                    marginTop: "-8px",
                    paddingRight: "10px",
                }}>
                    <table style={{ width: "100%" }}>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td style={{ paddingRight: "15px", minWidth: "90px" }}>Label</td>
                            <td style={{ fontWeight: 500, lineHeight: 1.0, width: "70%" }}>{ prop.label }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td style={{ paddingRight: "15px" }}>Status</td>
                            <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{
                                status + (TASK_STATE[prop.status] === "In progress" ? " ("+percent+"%)" : "")
                            }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td style={{ paddingRight: "15px" }}>Started</td>
                            <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ started }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td style={{ paddingRight: "15px" }}>Duration</td>
                            <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ duration }</td>
                        </tr>
                        <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                            <td style={{ paddingRight: "15px" }}>Host</td>
                            <td style={{ fontWeight: 500, lineHeight: 1.0, whiteSpace: "break-spaces", wordBreak: "break-all" }}>{ prop.host }</td>
                        </tr>
                        {
                            (state.expanded || window.innerWidth > 991) &&
                            [
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ paddingRight: "15px" }}>Target</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.ip }:{ prop.port }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ paddingRight: "15px" }}>Method</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.request }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ paddingRight: "15px" }}>Load type</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ isData ? "Linear bump" : '' }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ paddingRight: "15px" }}>Threads</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ prop.threads_per_node }x{ prop.nodes_assigned }</td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ padding: "12px", paddingRight: "15px", paddingLeft: "0px", lineHeight: "1.0" }}>Number of requests</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>
                                        { prop.max }
                                    </td>
                                </tr>,
                                <tr
                                    style={{ borderBottom: "#efefef 1px dotted" }}
                                >
                                    <td style={{
                                        padding: "12px",
                                        paddingRight: "15px",
                                        paddingLeft: "0px",
                                        lineHeight: "1.0"
                                    }}
                                    >Actually sent</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }} title="Successfully sent (Total attempts)">
                                        <span style={{ cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                            { prop.requests_done > 0 ? prop.requests_done : 0 }
                                            { prop.requests_total > 0 ? " (" + prop.requests_total + ")" : "" }
                                        </span>
                                    </td>
                                </tr>,
                                <tr style={{ borderBottom: "#efefef 1px dotted" }}>
                                    <td style={{ padding: "10px", paddingRight: "15px", paddingLeft: "0px", lineHeight: "1.0" }}>Average response</td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }}>{ avarge_response.toFixed(2) } sec.</td>
                                </tr>,
                                <tr
                                    style={{ borderBottom: "#efefef 1px dotted" }}
                                >
                                    <td style={{ paddingRight: "15px" }}>
                                        Errors
                                    </td>
                                    <td style={{ fontWeight: 500, lineHeight: 1.0 }} title="Client errors / Agent errors">
                                        <span style={{ cursor: "help", borderBottom: "#dfdfdf 1px dotted" }}>
                                            { spider_errors }% / { loader_errors }%
                                        </span>
                                    </td>
                                </tr>,
                                <tr style={{ borderBottom: window.innerWidth < 991 ? "#efefef 1px dotted" : "" }}>
                                    <td style={{ paddingRight: "15px" }}>Link(s)</td>
                                    <td style={{
                                        fontWeight: 500,
                                        lineHeight: 1.0,
                                        whiteSpace: "break-spaces",
                                        wordBreak: "break-all",
                                        padding: "10px"
                                    }}>{ links }</td>
                                </tr>
                            ]
                        }
                        {
                            window.innerWidth < 991 &&
                            (
                                <tr>
                                    <td colSpan={2} style={{
                                        padding: "12px",
                                        paddingRight: "15px",
                                        textAlign: "center",
                                        lineHeight: "1.0"
                                    }}>
                                        <a onClick={() => {
                                            setState({
                                                expanded: !state.expanded
                                            })
                                        }}>{
                                            state.expanded ? "Hide" : "Show"
                                        } detailed testing information</a>
                                    </td>
                                </tr>
                            )
                        }
                    </table>
                </div>
            );
            if (isRequestChart) {
                datasets.push({
                    label: 'Target response time',
                    type: 'line',
                    fill: false,
                    borderColor: '#3bc6ca',
                    backgroundColor: '#3bc6ca',
                    borderWidth: 2,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    data: response,
                    yAxisID: `y-axis-1-${key}`
                });
                yAxes.push(
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: `y-axis-1-${key}`,
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Target response time (sec)'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        }
                    }
                );
            }
            datasets.push({
                label: 'Requests per minute',
                type: 'line',
                fill: false,
                borderColor: '#ffc904',
                backgroundColor: '#ffc904',
                borderWidth: 2,
                pointRadius: 1,
                pointHoverRadius: 2,
                data: values,
                yAxisID: `y-axis-2-${key}`
            });
            yAxes.push({
                type: 'linear',
                display: true,
                position: 'right',
                id: `y-axis-2-${key}`,
                labels: {
                    show: true
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Requests per minute'
                },
            });
        }
        fout = (
            <div className="content details">
                <Grid fluid>
                    <Row>
                        <Col md={8}>
                            <Card
                                statsIcon="fa fa-history"
                                id="chartHours"
                                content={
                                    <div style={{ marginTop: "-30px"}}>
                                        <div className="h4">
                                            Load testing details
                                        </div>
                                        <div className="data">
                                            {
                                                prop &&
                                                <Bar
                                                    redraw={false}
                                                    height={510}
                                                    data={{
                                                        labels: labels,
                                                        datasets: datasets
                                                    }}
                                                    options={{
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        tooltips: {
                                                            mode: 'label'
                                                        },
                                                        elements: {
                                                            line: {
                                                                fill: false
                                                            }
                                                        },
                                                        scales: {
                                                            xAxes: [
                                                                {
                                                                    display: true,
                                                                    gridLines: {
                                                                        display: true
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        labelString: isResponseChart ?
                                                                            'Ratio of the load level to the response rate' :
                                                                            'The chart of the load level'
                                                                    }
                                                                }
                                                            ],
                                                            yAxes: yAxes
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <Card content={info} />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    } else {
        fout = error;
    }
    return (
        <div className="DetailsPage">
            <Header
                absolute
                color="transparent"
                brand={
                    <div className="siteLogo">
                        <i className="pe-7s-gleam siteIcon" />
                        <div className="siteName">
                            loadest
                        </div>
                    </div>
                }
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader + " pageHeader"}
                style={{ backgroundImage: "url(" + image + ")" }}
            >
                <div className={classNames(classes.main, classes.mainContent)} style={{ paddingBottom: 10 }}>
                    <div className={classNames(classes.textContainer)} style={{ paddingLeft: "calc(5px + 1%)", paddingRight: "calc(5px + 1%)" }}>
                        {
                            loaded && data && data.id && fout
                        }
                        {
                            loaded && data && !data.id && error
                        }
                        {
                            !loaded && <div className="loader">
                                <img src={spinner} height={150} />
                            </div>
                        }
                    </div>
                </div>
                <div className="h50" />
                <Footer  cookies={true} linearGradient={false} />
            </div>
        </div>
    );
}