/**
 * Loadest - Redux state debugger.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

export default function stateHandler({ getState }) {
  return next => action => {
    document["handler"].debug("Will dispatch action >>");
    document["handler"].debug(action);
    const returnValue = next(action);
    document["handler"].debug("Redux after dispatch >>");
    document["handler"].debug(getState());
    return returnValue;
  }
}