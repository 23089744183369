/**
 * Loadest - Nodes reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { NodeTypes } from "../variables/Types";
import { INode, INodesSummaryAction } from "../actions";

interface INodesState {
    nodes: INode[];
    loaded: boolean;
}

const initialState = (): INodesState => ({
    nodes: [],
    loaded: false,
});

const nodes = (state: INodesState = initialState(), action: INodesSummaryAction): INodesState => {
    switch (action.type) {
        case NodeTypes.UpdateNodesAction:
            return {
                ...state,
                nodes: (action.payLoad.nodes? action.payLoad.nodes : []),
                loaded: action.payLoad.loaded,
            };
        default:
            return state;
    }
};

export { nodes };