/**
 * Loadest - ErrorsTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import { Card } from "../Card/Card";
import { INodesTableProps } from "./NodesTable.props";
import "./NodesTable.css";
import Button from "../Button/Button";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import showMessage from "../../functions/showMessage";
import showSuccess from "../../functions/showSuccess";
import showError from "../../functions/showError";

class NodesTableComponent extends React.Component<INodesTableProps, { nodes: any[], loaded: boolean }>{
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("NodesTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            id: "id",
            name: "Name",
            ip: "IP",
            online: "Online",
            tps: "TPS",
            hoster: "Hoster",
            node_id: "Node ID",
            act: ""
        }
        this.state = {
            loaded: false,
            nodes: []
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    load():void {
        document["dataSource"].getNodes().then(results => {
            setTimeout(() => {
                if (results.length) {
                    this.setState({
                        nodes: results,
                        loaded: true
                    });
                } else {
                    this.setState({
                        loaded: false
                    });
                }
            }, 300);
        })
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        if (this.state.loaded) {
            this.setState({
                loaded: false
            });
        }
        this.load();
    }
    //------------------------------------------------------------------------------------------------------------------
    restart(id): void {
        showMessage(`Restarting node #${id}`);
        document["dataSource"].restartNode(id).then(result => {
           console.error(result);
           if (result) {
               showSuccess(`Node #${id} was succesfully restarted!`);
           } else {
               showError(`An error occured durning restarting Node #${id}`);
           }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("NodesTableComponent.print()");
        let fout = [];
        this.state.nodes &&
        this.state.nodes.length &&
        this.state.nodes.forEach((prop, key) => {
            const date1 = new Date(prop.online);
            const date2 = new Date();
            // @ts-ignore
            const diffTime = parseInt(Math.abs(date2 - date1) / 1000);
            fout.push (
                <tr key={key}>
                    <td>{prop.id}</td>
                    <td>{prop.name}</td>
                    <td>{prop.ip}</td>
                    <td>{diffTime} sec ago</td>
                    <td>{prop.tps}</td>
                    <td>{prop.hoster}</td>
                    <td>{prop.node_id}</td>
                    <td style={{ width: '80px' }}>
                        {
                            prop.hoster === "ruvds" &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => {
                                    this.restart(prop.id);
                                } }
                                title="Restart node"
                            >
                                <CachedOutlinedIcon />
                            </div>
                        }
                    </td>
                </tr>
            );
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("NodesTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            tHead.push(
                <th key={thKey}><span /* className="link" */>{this.tableHead[thKey]}</span></th>
            )
        }
        return(
            <Grid fluid className="NodesTable">
                <Row>
                    <Col md={10} mdOffset={1}>
                        <Card
                            plain
                            title="Assigned nodes"
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <>
                                    <Button
                                        color="primary"
                                        round
                                        className="btn newTest"
                                        onClick={e => {
                                            this.componentDidMount();
                                        }}
                                    >
                                        <i className="pe-7s-refresh" style={{
                                            fontWeight: "bold",
                                            paddingLeft: "3px",
                                            paddingRight: "7px"
                                        }}/>
                                    </Button>
                                    <br/>

                                    <div style={{ overflow: "auto" }}>
                                    {
                                        this.state.loaded ? (
                                            body.length?
                                            (
                                                <Table hover>
                                                    <thead>
                                                    <tr>{ tHead }</tr>
                                                    </thead>
                                                    <tbody>{ body }</tbody>
                                                </Table>
                                            ):(
                                                <div className="notFound">
                                                    <i className="pe-7s-attention emptyState"/>
                                                    <br/>
                                                        Nodes not found.
                                                    <br/>
                                                </div>
                                            )
                                        ) : (
                                            <div className="loader app-loader">
                                                <img height={150} src={spinner} />
                                            </div>
                                        )
                                    }
                                    </div>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default NodesTableComponent;
