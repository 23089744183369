/**
 * Loadest - DomainsTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Card } from "../Card/Card";
import { IDomainsTableProps, IDomainsTableState } from "./DomainsTable.props";
import "./DomainsTable.css";
import showSuccess from "../../functions/showSuccess";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

class DomainsTable extends React.Component <IDomainsTableProps, IDomainsTableState> {
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("DomainsTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            domain: "Domain",
            ip: "IP address",
            verified: "Verified",
            date: "Date Added",
            action: "Action"
        };
        this.state = {
            deleteDialog: 0,
            confirmDialog: null
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("DomainsTableComponent.print()");
        let fout = [];
        this.props.domains &&
        this.props.domains.length &&
        this.props.domains.forEach((prop, key) => {
            fout.push (
                <tr key={key}>
                    <td className={"selectable"}>{prop.domain}</td>
                    <td className={"selectable"}>{prop.ip}</td>
                    <td className={"selectable"}>{prop.verified ? 'Yes' : 'No'}</td>
                    <td className={"selectable"}>{new Date(prop.date).toLocaleString()}</td>
                    <td>
                        {
                            !prop.verified &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => { this.props.showDomainConfirmation(
                                    prop.domain,
                                    () => {
                                        this.props.reloadList();
                                    }
                                ) }}
                                title="Confirm rights"
                            >
                                <VerifiedUserOutlinedIcon />
                            </div>
                        }
                        {
                            prop.verified == "true" &&
                            <div
                                className="iconBtn materialIconBtn"
                                onClick={() => { this.setState({ deleteDialog: (prop.id)})}}
                                title="Delete domain"
                            >
                                <DeleteOutlined />
                            </div>
                        }
                    </td>
                </tr>
            );
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    deleteDialog() {
        return (
            <Dialog
                open={true}
                keepMounted
                onClose={() => this.setState({ deleteDialog: 0 })}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                >
                    <h1 style={{
                        fontSize: "24px",
                        marginTop: "30px",
                        minWidth: "300px",
                        textAlign: "center",
                        fontFamily: "Open Sans",
                        marginBottom: "30px"
                    }}>Are you sure?</h1>
                    Please confirm your intention to remove this domain from the list.
                </DialogTitle>
                <DialogActions >
                    <Button
                        onClick={() => {
                            document["dataSource"].deleteDomain(this.state.deleteDialog).then(result => {
                                if (result) {
                                    showSuccess("Domain successfully deleted from the list");
                                }
                                this.setState({ deleteDialog: 0 });
                                this.props.reloadList();
                            });
                        }}
                        color="transparent"
                        simple
                    >
                        Ok
                    </Button>
                    <Button
                        onClick={() => { this.setState({ deleteDialog: 0 }) }}
                        color="danger"
                        simple
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("DomainsTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            let arrow = '';
            if(thKey === this.props.sortKey){
                if(this.props.sortMethod){
                    arrow = " \u2193";
                }else{
                    arrow = " \u2191";
                }
            }
            tHead.push(
                <th key={thKey}><span /* className="link" onClick={() => {
                    document["handler"].info("DomainsTableComponent.th("+this.tableHead[thKey]+").click(method="+!(this.props.sortMethod)+")");
                    this.props.sort(thKey, !(this.props.sortMethod));
                }} */>{this.tableHead[thKey]}</span>{arrow}</th>
            )
        }
        return(
            <Grid fluid className="DomainsTable">
                <Row>
                    <Col md={12} sm={12} lg={10} lgOffset={1}>
                        <Card
                            plain
                            title="Associated domains"
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <div style={{ overflow: "auto" }}>
                                    <br/>
                                    {
                                        body.length?
                                        (
                                            <Table hover>
                                                <thead>
                                                <tr>{ tHead }</tr>
                                                </thead>
                                                <tbody>{ body }</tbody>
                                            </Table>
                                        ):(
                                            <div className="notFound">
                                                <i className="pe-7s-network emptyState"/>
                                                <br/>
                                                <br/>
                                                Domains not found.
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        />
                        {
                            this.state.deleteDialog > 0 &&
                            this.deleteDialog()
                        }
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default DomainsTable;
