/**
 * Loadest - LogsTable container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import LogsTableComponent from "./LogsTable.component";
import * as actions from "../../actions";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        searchLogsPhrase: state.errors.searchLogsPhrase,
        errors: state.errors.records,
        sortMethod: state.errors.sortMethod,
        sortKey: state.errors.sortKey
    }
}
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        search: (value:string) => {
            dispatch(
                actions.searchLogsPhrase(value)
            )
        },
        sort: (sortKey:string, sortMethod:boolean) => {
            dispatch(
                actions.sortLogs(sortKey, sortMethod)
            );
        }
    }
}
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogsTableComponent));
