/**
 * Loadest - TemplatesTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Card } from "../Card/Card";
import { ITemplatesTableProps } from "./TemplatesTable.props";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import "./TemplatesTable.css";
import Button from "../Button/Button";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";

class TemplatesTableComponent extends React.Component <ITemplatesTableProps> {
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("TemplatesTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            label: "Label",
            host: "Host",
            port: "Port",
            method: "Method",
            load: "Requests",
            threads: "Threads",
            actions: "Action"
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("TemplatesTableComponent.print()");
        let fout = [];
        this.props.templates &&
        this.props.templates.length &&
        this.props.templates.forEach((prop, key) => {
            let background = "#fff";
            if (key%2) {
                background = "#fafafa";
            }
            fout.push (
                <tr key={key} style={{ background: background }}>
                    <td className={"selectable"}>{prop.label}</td>
                    <td className={"selectable"}>{prop.host}</td>
                    <td className={"selectable"}>{prop.port}</td>
                    <td className={"selectable"}>{prop.request}</td>
                    <td className={"selectable"}>{prop.load}</td>
                    <td className={"selectable"}>{prop.threads}x{prop.nodes}</td>
                    <td>
                        <div
                            className="iconBtn materialIconBtn"
                            onClick={() => this.props.edit(prop.id, prop.label) }
                            title="Edit template"
                        >
                            <EditOutlinedIcon />
                        </div>
                        <div
                            className="iconBtn materialIconBtn"
                            onClick={() => this.props.delete(prop.id) }
                            title="Delete template"
                        >
                            <DeleteOutlined />
                        </div>
                    </td>
                </tr>
            );
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("TemplatesTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            let arrow = '';
            if(thKey === this.props.sortKey){
                if(this.props.sortMethod){
                    arrow = " \u2193";
                }else{
                    arrow = " \u2191";
                }
            }
            tHead.push(
                <th key={thKey}><span /* className="link" onClick={() => {
                    document["handler"].info("TemplatesTableComponent.th("+this.tableHead[thKey]+").click(method="+!(this.props.sortMethod)+")");
                    this.props.sort(thKey, !(this.props.sortMethod));
                }} */>{this.tableHead[thKey]}</span>{arrow}</th>
            )
        }
        return(
            <Grid fluid className="TemplatesTable">
                <Row>
                    <Col md={12} sm={12} lg={10} lgOffset={1}>
                        <Card
                            plain
                            title="Testing configurations"
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <div className="table-container">
                                    <Button
                                        color="primary"
                                        round
                                        className="btn newTemplate"
                                        onClick={e => this.props.new()}
                                    >
                                        <i className="pe-7s-browser" style={{
                                            fontWeight: "bold",
                                            paddingLeft:"3px",
                                            paddingRight:"7px"
                                        }} />
                                        <span className="btnText">
                                            New template &nbsp;
                                        </span>
                                    </Button>
                                    <br/>
                                    {
                                        body.length?
                                        (
                                            <Table hover>
                                                <thead>
                                                <tr>{ tHead }</tr>
                                                </thead>
                                                <tbody>{ body }</tbody>
                                            </Table>
                                        ):(
                                            <div className="notFound">
                                                <i className="pe-7s-browser emptyState"/>
                                                <br/>
                                                <br/>
                                                Templates not found.
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default TemplatesTableComponent;
