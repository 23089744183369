/**
 * Loadest - Logs actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {LogTypes} from "../variables/Types";
import {ILog} from "../views/LogList/LogList.interface";
import {Action} from "redux";
//----------------------------------------------------------------------------------------------------------------------
/* Logs sorting action */
interface ISortLogsAction extends Action {
    payLoad: {
        sortKey: string,
        sortMethod: boolean
    }
}
const sortLogs = (sortKey:string, sortMethod:boolean):ISortLogsAction => ({
    type: LogTypes.SortLogsAction,
    payLoad: {
        sortKey: sortKey,
        sortMethod: sortMethod
    }
});
//----------------------------------------------------------------------------------------------------------------------
/* Add an error action */
interface IAddLogAction extends Action {
    payLoad: {
        error: ILog
    };
}
const addLog = (error:ILog):IAddLogAction => ({
    type: LogTypes.AddLog,
    payLoad: {
        error: error
    }
});

//----------------------------------------------------------------------------------------------------------------------
/* Logs search action */
interface ISearchLogsPhraseAction extends Action {
    payLoad: {
        searchLogsPhrase: string;
    };
}
const searchLogsPhrase = (searchLogsPhrase:string):ISearchLogsPhraseAction => ({
    type: LogTypes.UpdateLogsSearchPhraseAction,
    payLoad: {
        searchLogsPhrase: searchLogsPhrase
    }
});
//----------------------------------------------------------------------------------------------------------------------
/* Summary action interface */
type ILogsSummaryAction =
    & ISortLogsAction
    & ISearchLogsPhraseAction
    & IAddLogAction;
//----------------------------------------------------------------------------------------------------------------------
export {
    ISortLogsAction,
    IAddLogAction,
    ISearchLogsPhraseAction,
    ILogsSummaryAction,
    sortLogs,
    addLog,
    searchLogsPhrase,
};