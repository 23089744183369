/**
 * Loadest - функция валидации URL адреса
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

const isValidURL = (url) => {
    if (url.indexOf("@") >= 0) {
        return false;
    }
    if (url.indexOf(" ") >= 0) {
        return false;
    }
    const pattern = new RegExp('.*?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}.*?','i');
    return pattern.test(url);
}

export default isValidURL;