/**
 * Loadest - Confirm Domain window component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";
import showSuccess from "../../functions/showSuccess";
// @ts-ignore
import spinner from "../../assets/img/load.gif";

class ConfirmDomain extends React.Component<any, any> {
    renderId: number;
    onResize: (e:any) => void;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("ConfirmDomain.constructor()");
        this.renderId = 0;
        this.state = {
            domain: props.domain ? props.domain : null,
            loaded: true,
        }
        this.onResize = function (e) {
            this.forceUpdate();
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("ConfirmDomain.componentDidMount()");
        window.addEventListener('resize', (e) => this.onResize(e), false);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("ConfirmDomain.componentWillUnmount()");
        this.onResize = () => {}
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("ConfirmDomain.render() №" + (++this.renderId));
        let domainName = this.state.domain.trim().indexOf("https") === 0 ?
            this.state.domain.trim() : "http://" +this.state.domain +"/loadest.txt";
        return (
            <Dialog
                open={true}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                >
                    <h1 style={{
                        fontSize: "24px",
                        textAlign: "center",
                        fontFamily: "Open Sans",
                        lineHeight: "1.5"
                    }}>Domain rights confirmation</h1>
                    {
                        /*
                        <>
                            1) Input your domain name below<br/>
                            <CustomInput
                                styles={{ width: "100%", paddingTop: "5px" }}
                                value={this.state.domain}
                                defaultValue={this.state.domain}
                                onChange={e => this.setState({ domain: e.target.value })}
                            /> <br/>
                            <br/>
                            2) Create file&nbsp;
                            <span style={{
                                padding: "5px",
                                background:"#eee",
                            }}> loadest.txt </span>&nbsp;inside your domain root directory.
                            <br/>
                            <br/>
                            3) Write the line to the file
                            <span
                                style={{
                                    padding: "5px",
                                    background:"#eee",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                loadest-{this.props.magic}
                            </span>
                                        <br/>
                                        <br/>
                                        4) Make sure file available as&nbsp;
                                        <span style={{
                                            padding: "5px",
                                            background:"#eee",
                                        }}>
                                { domainName }
                            </span>
                        </>
                         */
                        this.state.loaded ?
                        <p style={{
                            paddingTop: "20px"
                        }}>
                            <div>
                                1) Create file&nbsp;
                                <span style={{
                                    padding: "5px",
                                    background:"#eee",
                                }}> loadest.txt </span>&nbsp;inside your domain root directory.
                                <br/>
                                <br/>
                                2) Write the line to the file
                                <span
                                    style={{
                                        padding: "5px",
                                        background:"#eee",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    loadest-{this.props.magic}
                                </span>
                                <br/>
                                <br/>
                                3) Make sure file available as&nbsp;
                                <span style={{
                                    padding: "5px",
                                    background:"#eee",
                                }}>
                                    { domainName }
                                </span>
                            </div>
                        </p> :
                        <div style={{ width: window.innerWidth > 600 ? "550px" : "100%" }}>
                            <div className="loader"  style={{ margin: "0px auto", marginTop: "30px" }}>
                                <img height={130} src={spinner} />
                            </div>
                        </div>
                    }
                </DialogTitle>
                <DialogActions >
                    <Button
                        onClick={() => {
                            this.setState({
                                loaded: false,
                            });
                            document["dataSource"].confirmDomain(this.state.domain).then(result => {
                                setTimeout(() => {
                                    this.setState({
                                        loaded: true,
                                    });
                                    this.props.onClose();
                                    if (result) {
                                        showSuccess("Rights to domain successfully verified!");
                                        this.props.onOk();
                                    }
                                }, 300);
                            });
                        }}
                        color="transparent"
                        simple
                    >
                        Ok
                    </Button>
                    <Button
                        onClick={() => { this.props.onClose() }}
                        color="danger"
                        simple
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmDomain;