/**
 * Loadest - Sidebar container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import SidebarComponent from "./Sidebar.component";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        errors: state.errors.data,
        email: state.user.email,
    }
}
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return { }
}
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarComponent));
