/**
 * Loadest - HeaderLinks component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
    Apps,
    TouchApp,
    AccountCircle
} from "@material-ui/icons";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import adminRoutes from "../../adminRoutes";
import styles from "./HeaderLinks.style";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {MenuItem} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";
// @ts-ignore
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    document["handler"].log("HeaderLinks.render()");
    const [renderId, setRenderId] = useState(0);
    const classes = useStyles();
    const routes = adminRoutes.map(item => {
        return (item.display || (
            !item.display && item.admin && props.user.email === process.env.REACT_APP_ADMIN_EMAIL
        )) && (
            <NavLink to={item.layout+item.path} className={classes.dropdownLink}>
                {item.name}
            </NavLink>
        );
    });
    let resizeHeaders = () => {
        try {
            if (window.innerWidth != renderId) {
                setRenderId(window.innerWidth);
            }
        } catch (e) { /* ... */ }
    };
    useEffect( () => {
        try {
            window.addEventListener("resize", resizeHeaders);
            resizeHeaders();
        } catch (e) { /* ... */ }
        return () => {
            try {
                resizeHeaders = () => {};
                window.removeEventListener("resize", resizeHeaders);
            } catch (e) { /* ... */}
        }
    });
    const [logoutDialog, setLogoutDialog] = React.useState(false);
    const dialog = (
        <Dialog
            open={logoutDialog}
            keepMounted
            onClose={() => setLogoutDialog(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
            >
                <h1 style={{
                    fontSize: "24px",
                    marginTop: "30px",
                    minWidth: "240px",
                    textAlign: "center",
                    fontFamily: "Open Sans"
                }}>Logout now?</h1>
            </DialogTitle>
            <DialogActions >
                <Button
                    onClick={() => {
                        props.logout();
                        setLogoutDialog(false);
                        document["history"].push('/');
                    }}
                    color="transparent"
                    simple
                >
                    Yes
                </Button>
                <Button
                    onClick={() => { setLogoutDialog(false); }}
                    color="danger"
                    simple
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
  return (
    <List className={classes.list}>
        {
            !props.user.name &&
            <ListItem className={classes.listItem}>
                <NavLink
                    to="/signup"
                    className={classes.navLink}
                    color="transparent"
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <TouchApp className={classes.icons}/>
                    Sign Up
                </NavLink>
            </ListItem>
        }
        {
            !props.user.name &&
            <ListItem className={classes.listItem}>
                <NavLink
                    to="/login"
                    className={classes.navLink}
                    color="transparent"
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginRight: "10px",
                    }}
                >
                    <AccountCircle className={classes.icons}/>
                    Login
                </NavLink>
            </ListItem>
        }
        {
            window.innerWidth > 960 &&
            props.user.name &&
            <ListItem className={classes.listItem} title="Menu">
                <CustomDropdown
                    noLiPadding
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonText="MENU&nbsp;"
                    buttonIcon={Apps}
                    dropdownList={routes}
                />
            </ListItem>

        }
        {
            window.innerWidth <= 960 &&
            props.user.name &&
            adminRoutes.map((route, index) => {
                return <NavLink
                    key={"link_"+index}
                    to={route.layout+route.path}
                    className={classes.navLink}
                    color="transparent"
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginRight: "10px",
                    }}
                >
                    <i className={route.icon} style={{
                        fontSize: "24px",
                        marginLeft: "-12px",
                        fontWeight: 600,
                        minWidth: "30px"
                    }} />
                    {route.name}
                </NavLink>
            })
        }
        {
            props.user.name &&
            <MenuItem
                onClick={() => {
                    setLogoutDialog(true);
                }}
                className={classes.navLink + " logoutBtn"}
                style={{ paddingTop: "7px" }}
                title="Logout"
            >
                <ExitToAppIcon
                    style={{
                        fontSize: "21px",
                        margin: "0px",
                        marginTop: "2px"
                    }}
                    title="Logout"
                />
                <div style={{
                    paddingLeft:"5px",
                    paddingRight:"5px",
                    fontFamily:"Roboto",
                    fontSize:"12px"
                }}>
                    Logout
                </div>
            </MenuItem>
        }
        {
            dialog
        }
    </List>
  );
}
