/**
 * Loadest - InfoArea Props.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import PropTypes from "prop-types";

interface IExpressTestState {
    step: number,
    url: string,
    email: string,
    dialog: boolean,
    alert: boolean | string,
    urlError: boolean | string,
    loading: boolean,
    forward: boolean,
    domainConfirm: string,
    alertOnOk: () => void,
}

const ExpressTestDefaultProps = {
    classes: []
};

const ExpressTestPropTypes = {
    classes: PropTypes.any,
    history: PropTypes.any,
    domainConfirmation: PropTypes.any,
    magic: PropTypes.string,
    alertOnOk: PropTypes.func,
};

export {
    IExpressTestState,
    ExpressTestDefaultProps,
    ExpressTestPropTypes
}
