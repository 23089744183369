/**
 * Loadest - User factory function.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import isString from "../functions/isString";
import { PAYMENT_STATUS } from "../constants";

export interface IUser {
    name: string;
    email: string;
    sid: string;
    magic: string;
    confirmed: boolean;
    balance: number;
    instructed: boolean;
}

export interface IRate {
    btc: number,
    bch: number,
    eth: number,
    xch: number
}

export interface IPayment {
    id: number,
    chain: string,
    sum: number,
    amount: number,
    status: typeof PAYMENT_STATUS,
    address: string,
    txid: string,
    created_at: string,
    update_at: string,
    expired_at: string
}

export function UserWrapper({
    name,
    sid,
    email,
    magic,
    balance,
    confirmed,
    instructed
}):IUser {
    return {
        name: isString(name) ? name.toString() : null,
        email: isString(email) ? email.toString() : null,
        sid: isString(sid) ? sid.toString() : null,
        magic: isString(magic) ? magic.toString() : null,
        confirmed: confirmed ? true : false,
        balance: balance ? parseFloat(balance) : 0,
        instructed: instructed ? true : false
    }
};