/**
 * Loadest - Logs screen component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import { Card } from "../../components/Card/Card";
import {ILogListProps} from "./LogList.interface";
import LogsTable from "../../components/LogsTable/LogsTable";
import "./LogList.css";

class LogList extends React.PureComponent<ILogListProps> {
    renderId: number;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props){
        super(props);
        document["handler"].log("NodeList.constructor()");
        this.renderId = 0;
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("NodeList.componentDidMount()");
        setTimeout(()=>{this.props.updateParent(true)}, 0);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("NodeList.componentWillUnmount()");
        this.props.updateParent(false);
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("NodeList.render() №"+(++this.renderId));
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={10} mdOffset={1}>
                            <Card
                                hCenter
                                ctTableResponsive
                                ctTableUpgrade
                                className="LogList"
                                content={
                                    <LogsTable />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default LogList;
