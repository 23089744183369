/**
 * Loadest - Application actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {LogTypes, MainTypes} from "../variables/Types";
import {INotification} from "../views/Admin/Admin.interface";
import {Action} from "redux";
import {ILog} from "../views/LogList/LogList.interface";

export * from "./dashboard";
export * from "./testing";
export * from "./logs";
export * from "./templates";
export * from "./domains";
export * from "./nodes";
//----------------------------------------------------------------------------------------------------------------------
/* Notification system initialization */
interface IAddNotificationSystemAction extends Action {
    payLoad: {
        addNotification: INotification
    };
}
const addNotificationSystem = (addNotification:INotification):IAddNotificationSystemAction => ({
    type: MainTypes.AddNotificationSystem,
    payLoad: {
        addNotification: addNotification
    }
});
//----------------------------------------------------------------------------------------------------------------------
/* Scrolling */
interface IOnScrollAction extends Action {
    payLoad: {
        scrollTop: number
    };
}
const scrollWindow = (scroll:number):IOnScrollAction => ({
    type: MainTypes.OnScrollAction,
    payLoad: {
        scrollTop: scroll
    }
});
//----------------------------------------------------------------------------------------------------------------------
/* Modal window */
interface IShowModalAction extends Action {
    payLoad: {
        caption?: string;
        text?: string;
        callback?: () => void;
    }
}
const showModal = (
    caption: string,
    text: any,
    callback = null
):IShowModalAction => ({
    type: MainTypes.ShowModalAction,
    payLoad: {
        caption: caption,
        text: text,
        callback: callback
    }
});
const hideModal = ():IShowModalAction => ({
    type: MainTypes.HideModalAction,
    payLoad: {}
});
//----------------------------------------------------------------------------------------------------------------------
/* Right modal window */
interface IShowRightModalAction extends  Action {
    payLoad: {
        showRightModal?: any,
        action?: string
    }
}
const showRightModal = (modal:any):IShowRightModalAction => ({
    type: MainTypes.ShowRightModalAction,
    payLoad: {
        showRightModal: modal,
        action: "new"
    }
});
const hideRightModal = ():IShowRightModalAction => ({
    type: MainTypes.HideRightModalAction,
    payLoad: {}
});
//----------------------------------------------------------------------------------------------------------------------
const showDomainConfirmation = (domain, onOk, onClose) => ({
    type: MainTypes.ShowDomainConfirmationAction,
    payLoad: {
        display: true,
        domain: domain,
        onOk: onOk,
        onClose: onClose,
    }
});
const hideDomainConfirmation = () => ({
    type: MainTypes.HideDomainConfirmationAction
});
//----------------------------------------------------------------------------------------------------------------------
/* Add an error action */
interface IAddErrorAction extends Action {
    payLoad: {
        code:number,
        message:string,
        category:string,
        date:string
    };
}
const addError = (data):IAddErrorAction => ({
    type: "addError",
    payLoad: {
        code: data.code,
        message: data.message,
        category: data.category,
        date: data.date
    }
});
//----------------------------------------------------------------------------------------------------------------------
type MainSummaryAction =
    IShowModalAction &
    IOnScrollAction &
    IAddNotificationSystemAction &
    IShowRightModalAction &
    IAddErrorAction
//----------------------------------------------------------------------------------------------------------------------
export {
    IShowModalAction,
    IOnScrollAction,
    IAddNotificationSystemAction,
    IShowRightModalAction,
    IAddErrorAction,
    MainSummaryAction,
    addNotificationSystem,
    scrollWindow,
    showModal,
    hideModal,
    showRightModal,
    hideRightModal,
    showDomainConfirmation,
    hideDomainConfirmation,
    addError
};
