/**
 * Loadest - Login page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from '@material-ui/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/GridItem/GridItem";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import Footer from "../../components/Footer/Footer";
import {
    ILoginPageProps,
    ILoginPageState
} from "./LoginPage.interface";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import "./LoginPage.css";
import isValidEmail from "../../functions/isValidEmail";

class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
    renderId: number;
    onKeyPress: (e:any) => void;
    isLoaded: boolean;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("LoginPage.constructor()");
        this.renderId = 0;
        this.isLoaded = false;
        this.state = {
            dialog: "",
            loading: false,
            email: "",
            pass: "",
        };
        this.onKeyPress = function(e) {
            document["handler"].log(`LoginPage.onKeyPress(${e.code})`);
            if (
                (e.code === "Enter" || e.code === "NumpadEnter") &&
                window.location.href.indexOf("/login") >= 0
            ) {
                try {
                    this.onLogin();
                } catch (e) { /* ... */ }
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        document["handler"].log("LoginPage.componentDidMount()");
        setTimeout(() => document.title = 'Login', 1);
        if (document["reduxStore"].getState().user.sid) {
            this.props.history.push('/app/dashboard');
        }
        try {
            if (window.innerWidth >= 768) {
                const el = document.getElementById("email");
                if (el) {
                    el.focus();
                }
            }
        } catch (e) { /* ... */ }
        document.addEventListener('keypress', e => this.onKeyPress(e), false);
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount(): void {
        document["handler"].log("LoginPage.componentWillUnmount()");
        this.onKeyPress = e => {};
    }
    //------------------------------------------------------------------------------------------------------------------
    setDialog(text:string): void {
        document["handler"].log(`LoginPage.setDialog(${text})`);
        this.setState({ ...this.state, dialog: text });
    }
    //------------------------------------------------------------------------------------------------------------------
    setLoading(value:boolean): void {
        document["handler"].log(`LoginPage.setDialog(${value})`);
        this.setState({ ...this.state, loading: value });
    }
    //------------------------------------------------------------------------------------------------------------------
    onLogin() {
        document["handler"].log("LoginPage.onLogin()");
        const email_el = document.getElementById("email");
        const pass_el = document.getElementById("pass");
        const email = isValidEmail(this.state.email.trim()) ? this.state.email.trim().toLowerCase() : false;
        const pass = this.state.pass.trim().toLowerCase();
        let flag = false;
        if (!email) {
             flag = true;
            try {
                // @ts-ignore
                email_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
             if (!pass) {
                 try {
                     // @ts-ignore
                     pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
                 } catch(e) { /*...*/ }
                 this.setDialog('Invalid password and email address');
             } else {
                 this.setDialog('Invalid email address');
             }
        } else if (!pass) {
            flag = true;
            try {
                // @ts-ignore
                pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
            this.setDialog('Invalid password');
        }
        if (!flag) {
            this.setLoading(true);
            document["dataSource"].loginUser(email, pass, this.setDialog.bind(this)).then(result => {
                if (result) {
                    setTimeout(() => {
                        if (!document["initInterval"]) {
                            document["initInterval"] = window.setInterval(() => document["dataSource"].initData(), 30000);
                        }
                        this.props.history.push('/app/dashboard');
                    }, 1000);
                } else {
                    setTimeout(() => {
                        this.setLoading(false);
                    }, 1000);
                }
            });
        }
    };
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("LoginPage.render() №" + (++this.renderId));
        if (!this.isLoaded) {
            this.isLoaded = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 1);
        }
        const { classes, ...rest } = this.props;
        return (
            <div className="loginPage">
                <Header
                    absolute
                    color="transparent"
                    brand={
                        <div className="siteLogo">
                            <i className="pe-7s-gleam siteIcon" />
                            <div className="siteName">
                                loadest
                            </div>
                        </div>
                    }
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <div
                    className={classes.pageHeader + " pageHeader"}
                    style={{ backgroundImage: "url(" + image + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6} style={{ maxWidth: "360px" }}>
                                <Card content={
                                    <form className={classes.form}>
                                        <h1 className={classes.loginCaption}>Login</h1>
                                        {
                                            this.state.loading &&
                                            <div className="loader">
                                                <img src={spinner} height={150} />
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>
                                                <CustomInput
                                                    labelText="Email"
                                                    id="email"
                                                    defaultValue=""
                                                    value={this.state.email}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "email",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Email className={classes.inputIconsColor}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            email: e.target.value,
                                                        })
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Password"
                                                    id="pass"
                                                    value={this.state.pass}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "password",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LockOutlined className={classes.inputIconsColor}/>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            pass: e.target.value
                                                        });
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <div className="getStarted">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        style={{margin: "0px auto"}}
                                                        onClick={() => this.onLogin()}
                                                        disabled={
                                                            !this.state.email ||
                                                            !this.state.pass
                                                        }
                                                    >
                                                        Get started!
                                                    </Button>
                                                    <div className="h20" />
                                                    {
                                                        <div style={{
                                                            paddingTop: '40px',
                                                            paddingBottom: '10px',
                                                            color: "#666",
                                                            textAlign: "center"
                                                        }}>
                                                            Problems with sign in? &nbsp;
                                                            <NavLink
                                                                onClick={() => {
                                                                    window.scrollTo(0,0);
                                                                }}
                                                                to="/reset"
                                                                style={{
                                                                    textDecoration: "underline"
                                                                }}
                                                            >
                                                                Reset password
                                                            </NavLink>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </form>
                                }
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="h100" />
                    <Footer  cookies={true} linearGradient={false} />
                </div>
                {
                    this.state.dialog !== "" &&
                    <CustomDialog
                        open={true}
                        caption="Access denied"
                        text={this.state.dialog.toString()}
                        close={() => this.setDialog("")}
                    />
                }
            </div>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(LoginPage);
