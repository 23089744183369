/**
 * Loadest - функция проверки строчного типа данных
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
}

export default isString;