/**
 * Loadest - функция отображения всплывающего сообщения.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";

const showMessage = (text:string) => {
    document["handler"].log("showMessage()");
    try {
        if (document["reduxStore"].getState().main.addNotification) {
            document["reduxStore"].getState().main.addNotification({
                title: <span data-notify="icon" className="pe-7s-info"/>,
                message: <div> {text} </div>,
                level: "info",
                position: "tr",
                autoDismiss: 5,
            });
        } else {
            window.alert(text);
        }
    }catch(e){
        document["handler"].error("showMessage() -> "+e.message);
        window.alert(text);
    }
}

export default showMessage;