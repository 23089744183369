/**
 * Loadest - TestingList container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import TestingList from "./TestingList.component";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        testings: state.testing.testings,
        templates: state.templates.templates,
        loaded: state.testing.loaded,
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = dispatch => {
    return {
        load: (callback) => {
            document["dataSource"].getTestings().then((result) => {
                setTimeout(callback, 300, result);
            });
        },
        loadTemplates: callback => {
            document["dataSource"].getTemplates().then((result) => {
                setTimeout(callback, 300, result);
            });
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestingList));