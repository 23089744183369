/**
 * Loadest - DAO page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import "./DaoPage.css";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
import Footer from "../../components/Footer/Footer";
import DAO from "../../components/DAO/DAO";

export default function DaoPage(props) {
    document.title = 'DAO Whitepaper';
    //@ts-ignore
    const classes = makeStyles(styles)();
    const { ...rest } = props;
    return (
        <div className="DaoPage">
            <Header
                absolute
                color="transparent"
                brand={
                    <div className="siteLogo">
                        <i className="pe-7s-gleam siteIcon" />
                        <div className="siteName">
                            loadest
                        </div>
                    </div>
                }
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader + " pageHeader"}
                style={{ backgroundImage: "url(" + image + ")" }}
            >
                <div className={classNames(classes.main, classes.mainContent)}>
                    <div className={classNames(classes.container, classes.textContainer)}>
                        <div>
                            <DAO />
                        </div>
                    </div>
                </div>
                <div className="h50" />
                <Footer linearGradient={false} cookies={true} />
            </div>
        </div>
    );
}