/**
 * Loadest - API page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import "./ApiPage.css";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
import API from "../../components/API/API";
import Footer from "../../components/Footer/Footer";

export default function ApiPage(props) {
    document.title = 'API';
    //@ts-ignore
    const classes = makeStyles(styles)();
    const { ...rest } = props;
    return (
        <div className="ApiPage">
            <Header
                absolute
                color="transparent"
                brand={
                    <div className="siteLogo">
                        <i className="pe-7s-gleam siteIcon" />
                        <div className="siteName">
                            loadest
                        </div>
                    </div>
                }
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader + " pageHeader"}
                style={{ backgroundImage: "url(" + image + ")" }}
            >
                <div className={classNames(classes.main, classes.mainContent)}>
                    <div className={classNames(classes.container, classes.textContainer)}>
                        <div>
                            <API />
                        </div>
                    </div>
                </div>
                <div className="h50" />
                <Footer linearGradient={false} cookies={true} />
            </div>
        </div>
    );
}