/**
 * Loadest - функция отображения всплывающего сообщения с предупреждением.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";

const showWarning = (text:string) => {
    document["handler"].log("showWarning()");
    try {
        if (document["reduxStore"].getState().main.addNotification) {
            document["reduxStore"].getState().main.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention"/>,
                message: (
                    <div>
                        {text}
                    </div>
                ),
                level: "warning",
                position: "tr",
                autoDismiss: 5,
            });
        } else {
            window.alert(text);
        }
    }catch(e){
        document["handler"].error("showWarning() -> "+e.message);
        window.alert(text);
    }
}

export default showWarning;