/**
 * Loadest - Testing actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {Action} from 'redux';
import {ITesting} from "../interfaces/Testing"
import {TestingTypes} from "../variables/Types";
//----------------------------------------------------------------------------------------------------------------------
interface ILoadTestingAction extends Action {
    payLoad: {
        testings: ITesting[],
        loaded: boolean,
    }
}
const loadTestingList = (data: ITesting[]):ILoadTestingAction => ({
    type: TestingTypes.UpdateTestingAction,
    payLoad: {
        testings: data,
        loaded: true
    }
});
const reloadTestingList = ():ILoadTestingAction => ({
    type: TestingTypes.UpdateTestingAction,
    payLoad: {
        testings: [],
        loaded: false
    }
});
//----------------------------------------------------------------------------------------------------------------------
type ITestingSummaryAction =
    ILoadTestingAction;
//----------------------------------------------------------------------------------------------------------------------
export {
    ILoadTestingAction,
    ITestingSummaryAction,
    reloadTestingList,
    loadTestingList,
};
