/**
 * Loadest - Custom Input component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import styles from "./CustomInput.style";
import { ICustomInputProps, CustomInputPropTypes } from "./CustomInput.props";
import {InferProps} from "prop-types";
// @ts-ignore
const useStyles = makeStyles(styles);

const CustomInput: React.FunctionComponent<ICustomInputProps> = (props:InferProps<typeof CustomInputPropTypes>) => {
    document["handler"].log("CustomInput.render()");
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        autoFocus
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
        [classes.bigFont]: props.bigFont ? true : false,
    });
    let formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    return (
        <FormControl {...formControlProps} className={formControlClasses} style={props.styles}>
            {
                labelText !== undefined ?
                (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : null
            }
            <Input
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                }}
                placeholder={props.placeholder}
                id={id}
                {...inputProps}
                style={{
                    width: (props.width ? props.width : "auto")
                }}
                disabled={props.disabled ? true : false}
                autoFocus={autoFocus}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
            />

        </FormControl>
    );
}

CustomInput.propTypes = CustomInputPropTypes;

export default CustomInput;
