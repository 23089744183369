/**
 * Loadest - Payment page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/GridItem/GridItem";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import {
    IPaymentPageProps,
    IPaymentPageState
} from "./PaymentPage.interface";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import "./PaymentPage.css";
import * as actions from "../../actions";
import { useTimer } from 'react-timer-hook';
import {PAYMENT_STATUS} from "../../constants";
import CustomDialog from "../../components/CustomDialog/CustomDialog";

function MyTimer({ expiryTimestamp }) {
    const {
        seconds,
        minutes,
        hours,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn('onExpire called')
    });
    return <span>
        <span>{hours > 10 ? hours : `0${hours}`}</span>:
        <span>{minutes > 10 ? minutes : `0${minutes}`}</span>:
        <span>{seconds > 10 ? seconds : `0${seconds}`}</span>
    </span>;
}

class PaymentPage extends React.Component<IPaymentPageProps, IPaymentPageState> {
    renderId: number;
    onKeyPress: (e:any) => void;
    isLoaded: boolean;
    interval: number;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("PaymentPage.constructor()");
        this.renderId = 0;
        this.isLoaded = false;
        this.interval = null;
        let amount = 10000;
        this.state = {
            dialog: {
                display: false,
                title: '',
                onOk: () => {}
            },
            loading: true,
            amount: amount,
            code: "",
            error: "",
            data: {
                wallets: [],
                rates: {},
                transaction: null
            },
            chain: "BTC"
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        document["handler"].log("PaymentPage.componentDidMount()");
        if (this.props.sid) {
            if (!this.props.confirmed) {
                this.props.history.push('/confirmation');
            } else {
                setTimeout(() => document.title = 'Payment', 100);
                try {
                    if (window.innerWidth >= 768) {
                        const el = document.getElementById("url");
                        if (el) {
                            el.focus();
                        }
                    }
                } catch (e) { /* ... */ }
                if (this.state.loading) {
                    document["dataSource"].getPaymentInfo().then((result) => {
                        if (result) {
                            console.error(result);
                            this.setState({
                                data: result,
                                loading: false
                            })
                        }
                    });
                    if (!this.interval) {
                        this.interval = window.setInterval(() => {
                            document["dataSource"].getPaymentInfo().then((result) => {
                                if (result) {
                                    this.setState({
                                        data: result,
                                        loading: false
                                    })
                                }
                            });
                        }, 60 * 1000);
                    }
                }
            }
        } else {
            this.props.history.push('/signup');
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount(): void {
        document["handler"].log("PaymentPage.componentWillUnmount()");
        // this.onKeyPress = e => {};
    }
    //------------------------------------------------------------------------------------------------------------------
    setDialog(title:string = null, onOk: () => void = null): void {
        document["handler"].log(`PaymentPage.setDialog(${title})`);
        this.setState({
            dialog: {
                display: title != null,
                title: title,
                onOk: onOk
             }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    setLoading(value:boolean): void {
        document["handler"].log(`PaymentPage.setLoading(${value})`);
        this.setState({ loading: value });
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("PaymentPage.render() №" + (++this.renderId));
        document["handler"].log(this.state);
        if (!this.isLoaded) {
            this.isLoaded = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 1);
        }
        const { classes, ...rest } = this.props;
        let time = new Date();
        if (this.state.data.transaction && this.state.data.transaction.expired_at) {
            time.setSeconds((new Date(this.state.data.transaction.expired_at).getTime() - time.getTime()) / 1000);
        }
        let loadHeight = 150;
        try {
            loadHeight = document.getElementsByClassName("content")[0].clientHeight - 263;
        } catch (e) {}
        let crypto = '';
        if (this.state.data && this.state.data.rates) {
            crypto = (((this.state.amount / 1000) * this.state.data.rates.usd) / this.state.data.rates[this.state.chain.toLowerCase()]).toFixed(8);
        }
        return (
            <div className="paymentPage">
                <Header
                    absolute
                    color="transparent"
                    brand={
                        <div className="siteLogo">
                            <i className="pe-7s-gleam siteIcon" />
                            <div className="siteName">
                                loadest
                            </div>
                        </div>
                    }
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <div
                    className={classes.pageHeader + " pageHeader"}
                    style={{ backgroundImage: "url(" + image + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6} style={{ maxWidth: 360 }}>
                                <Card content={
                                    <form className={classes.form} style={{ lineHeight: 1.2, marginBottom: 0 }}>
                                        <h1 className={classes.loginCaption} style={{ fontSize: "28px" }}>Payment</h1>
                                        {
                                            this.state.loading &&
                                            <div className="loader" style={{ paddingTop: 75, paddingBottom: 74 }}>
                                                <div style={{ height: loadHeight }}>
                                                    <img height={180} src={spinner} style={{ paddingTop: "30px" }} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>
                                                <div style={{
                                                    color: "rgb(102, 102, 102)",
                                                    paddingTop: "20px",
                                                    paddingBottom: "10px",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    width: "100%",
                                                    margin: "0px auto"
                                                }}>
                                                    {
                                                        !this.state.data.transaction && <div>
                                                            <>Payment method</>
                                                            <br/>
                                                            <select
                                                                className="input"
                                                                style={{ padding: 7, width: "100%", maxWidth: 280, marginTop: 3}}
                                                                onChange={(e) => this.setState({ chain: e.target.value })}
                                                            >
                                                                {
                                                                    this.state.data.wallets.map((el) => {
                                                                        return <option selected={el.code === this.state.chain} disabled={!el.synced} value={el.code}>{el.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            <br/>
                                                            <br/>
                                                            <>Desired amount of tokens</>
                                                            <br/>
                                                            <input
                                                                type={"number"}
                                                                value={this.state.amount}
                                                                onChange={(e) => {
                                                                    const val = parseInt(e.target.value);
                                                                    if (val > 0) {
                                                                        if (val <= 1000000) {
                                                                            this.setState({amount: val});
                                                                        } else {
                                                                            window.alert("There are not enough tokens in the system pool");
                                                                        }
                                                                    }
                                                                }}
                                                                className="input"
                                                                style={{ padding: 7, width: "100%", maxWidth: 280, marginTop: 3}}
                                                            />
                                                            <br/>
                                                            <br/>
                                                            <>Amount payable in USD</>
                                                            <br/>
                                                            <input
                                                                type={"number"}
                                                                value={this.state.amount / 1000}
                                                                onChange={(e) => {
                                                                    const val = parseFloat(e.target.value);
                                                                    if (val > 0) {
                                                                        if (val <= 1000000) {
                                                                            this.setState({amount: val * 1000});
                                                                        } else {
                                                                            window.alert("There are not enough tokens in the system pool");
                                                                        }
                                                                    }
                                                                }}
                                                                className="input"
                                                                style={{ padding: 7, width: "100%", maxWidth: 280, marginTop: 3}}
                                                            />
                                                            <br/>
                                                            <div style={{
                                                                display: "table",
                                                                width: "100%",
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 400,
                                                                marginTop: 25
                                                            }}>
                                                                <div style={{ textAlign: "left", display: "table-cell", paddingLeft: 25 }}>
                                                                    <>To pay</>
                                                                    <br/>
                                                                    <div style={{ fontSize: 24, paddingTop: 5 }}>
                                                                        ~ {crypto} {this.state.chain}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <br/>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    style={{ margin: "0px auto", marginBottom: "20px" }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            loading: true
                                                                        });
                                                                        document["dataSource"].createPayment(this.state.chain, this.state.amount / 1000).then(result => {
                                                                            if (result) {
                                                                                this.setState({
                                                                                    data: {
                                                                                        wallets: this.state.data.wallets,
                                                                                        rates: this.state.data.rates,
                                                                                        transaction: result
                                                                                    }
                                                                                })
                                                                            }
                                                                            setTimeout(() => this.setState({
                                                                                loading: false
                                                                            }), 500);
                                                                        });
                                                                    }}
                                                                >
                                                                    Continue to payment
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.data.transaction !== null
                                                        && !this.state.loading
                                                        && <div style={{ textAlign: "left", fontSize: 16, lineHeight: 1.2 }}>
                                                            Status: { PAYMENT_STATUS[this.state.data.transaction.status] }
                                                            <br/>
                                                            {
                                                                this.state.data.transaction.status === 1 &&
                                                                <div style={{ fontSize: 16, color: "#ddaa01" }}>
                                                                    (confirmations required: 2)
                                                                </div>
                                                            }
                                                            <br/>
                                                            Amount: <span className={"selectable"} style={{ fontFamily: "Open Sans", color: "#000" }}>
                                                                { this.state.data.transaction.amount }
                                                            </span> { this.state.data.transaction.chain }
                                                            {
                                                                this.state.data.transaction.status === 0 &&
                                                                [
                                                                    <br/>,
                                                                    <span style={{ fontSize: 16, color: "#ddaa01" }}>(payment should be made in single blockchain transaction)</span>
                                                                ]
                                                            }
                                                            <br/><br/>
                                                            Address: <span className={"selectable"} style={{ fontFamily: "Open Sans", color: "#000", wordBreak: "break-all" }}>
                                                                { this.state.data.transaction.address }
                                                            </span>
                                                            <br/><br/>
                                                            Support: <a href="mailto:restinpc37@gmail.com" style={{  }}>restinpc37@gmail.com</a>
                                                            <br/><br/>
                                                            {
                                                                this.state.data.transaction.status < 2
                                                                && <div>
                                                                    Time left: <MyTimer expiryTimestamp={time} />
                                                                    <br/>
                                                                    <br/>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.data.transaction.status >= 2
                                                                && <div>
                                                                    Paid at: {new Date(this.state.data.transaction.updated_at).toLocaleString()}
                                                                    <br/>
                                                                    <br/>
                                                                </div>
                                                            }
                                                            <br/>
                                                            {
                                                                this.state.data.transaction.status < 2
                                                                && <div style={{ textAlign: "center" }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="large"
                                                                        style={{ margin: "0px auto", marginBottom: "20px", minWidth: 260 }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                loading: true
                                                                            });
                                                                            document["dataSource"].getPaymentInfo().then((result) => {
                                                                                if (result) {
                                                                                    setTimeout(() => {
                                                                                        this.setState({
                                                                                            data: result,
                                                                                            loading: false
                                                                                        })
                                                                                    }, 300);
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        Refresh payment status
                                                                    </Button>
                                                                    {
                                                                        this.state.data.transaction.status === 0
                                                                        && <Button
                                                                            variant="contained"
                                                                            color="danger"
                                                                            size="large"
                                                                            style={{ margin: "0px auto", marginBottom: "15px", minWidth: 260 }}
                                                                            onClick={() => {
                                                                                if (window.confirm("Confirm that you did not make a transaction yet and want to cancel this payment process. The action is irreversible.")) {
                                                                                    this.setState({
                                                                                        loading: true
                                                                                    });
                                                                                    document["dataSource"].cancelPayment(this.state.data.transaction.id).then((result) => {
                                                                                        if (result) {
                                                                                            document["dataSource"].getPaymentInfo().then((result) => {
                                                                                                if (result) {
                                                                                                    this.setState({
                                                                                                        data: result,
                                                                                                        loading: false
                                                                                                    })
                                                                                                }
                                                                                            });
                                                                                        } else {
                                                                                            window.alert("Unknown error. Please try again");
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            Cancel payment process
                                                                        </Button>
                                                                    }

                                                                </div>
                                                            }
                                                            {
                                                                this.state.data.transaction.status === 3
                                                                && <div style={{ textAlign: "center" }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="large"
                                                                        style={{ margin: "0px auto", marginBottom: "20px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                data: {...this.state.data, transaction: null}
                                                                            })
                                                                        }}
                                                                    >
                                                                        New payment
                                                                    </Button>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.data.transaction.status === 2
                                                                && <div style={{ textAlign: "center" }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="large"
                                                                        style={{ margin: "0px auto", marginBottom: "20px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                loading: true
                                                                            });
                                                                            document["dataSource"].completePayment(this.state.data.transaction.id).then((result) => {
                                                                                if (result) {
                                                                                    setTimeout(() => {
                                                                                        this.setState({
                                                                                            loading: false,
                                                                                            dialog: {
                                                                                                display: true,
                                                                                                title: "Tokens was successfully credited to your account balance",
                                                                                                onOk: () => {
                                                                                                    document["dataSource"].initData().then((result) => {
                                                                                                        this.props.history.push('/app/dashboard');
                                                                                                    });
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }, 500);
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        Request tokens
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </form>
                                } />
                                </GridItem>
                        </GridContainer>
                    </div>
                    {
                        this.state.dialog.display && <CustomDialog
                            open={true}
                            caption={undefined}
                            text={this.state.dialog.title.toString()}
                            close={() => {
                                this.setState({
                                    dialog: {...this.state.dialog, display: false}
                                });
                                this.state.dialog.onOk();
                            }}
                        />
                    }
                    <div className="h100" />
                    <Footer cookies={true} linearGradient={false} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sid: state.user.sid,
        email: state.user.email,
        confirmed: state.user.confirmed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideRightModal: () => {
            dispatch(
                actions.hideRightModal()
            );
            document.body.style.overflow = "auto";
        }
    }
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentPage));
