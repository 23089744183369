/**
 * Loadest - Log screen reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {ILogsSummaryAction} from '../actions/logs';
import {LogTypes} from "../variables/Types";
import matchString from "../functions/matchStrings";
import isString from "../functions/isString";
import { ILog } from "../views/LogList/LogList.interface";
//----------------------------------------------------------------------------------------------------------------------
interface ILogsState{
    searchLogsPhrase: string;
    sortKey: string;
    sortMethod: boolean;
    data: ILog[];
    records: ILog[];
}
//----------------------------------------------------------------------------------------------------------------------
const initialState = (): ILogsState => ({
    searchLogsPhrase: "",
    sortKey: "date",
    sortMethod: true,
    data: [],
    records: []
});
//----------------------------------------------------------------------------------------------------------------------
const sortRecords = (
    records:ILog[],
    sortKey:string,
    sortMethod:boolean,
    searchLogsPhrase:string
): ILog[] => {
    let fout = [];
    records &&
    records.length &&
    records.forEach((prop) => {
        if (
            (
                searchLogsPhrase !== "" && (
                    (
                        prop.message &&
                        isString(prop.message) &&
                        prop.message.toLowerCase().indexOf(searchLogsPhrase.toLowerCase()) >= 0
                    )
                        ||
                    (
                        prop.category &&
                        isString(prop.category) &&
                        prop.category.toLowerCase().indexOf(searchLogsPhrase.toLowerCase()) >= 0
                    )
                )
            ) || searchLogsPhrase === ""
        ) {
            if (!fout.length) {
                fout.push(prop);
            } else if (sortKey === "message") {
                if (sortMethod) {
                    if (matchString(fout[fout.length - 1].message, prop.message)) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(matchString(fout[i].message, prop.message)) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                } else {
                    if (!matchString(fout[fout.length - 1].message, prop.message)) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(!matchString(fout[i].message, prop.message)) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                }
            } else if (sortKey === "category") {
                if (sortMethod) {
                    if (matchString(fout[fout.length - 1].category, prop.category)) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(matchString(fout[i].category, prop.category)) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                } else {
                    if (!matchString(fout[fout.length - 1].category, prop.category)) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(!matchString(fout[i].category, prop.category)) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                }
            } else if (sortKey === "date") {
                if (sortMethod) {
                    if (fout[fout.length - 1].date < prop.date) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(fout[i].date < prop.date) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                } else {
                    if (fout[fout.length - 1].date > prop.date) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(fout[i].date > prop.date) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                }
            } else if (sortKey === "code") {
                if (sortMethod) {
                    if (fout[fout.length - 1].code < prop.code) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(fout[i].code < prop.code) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                } else {
                    if (fout[fout.length - 1].code > prop.code) {
                        fout.push(prop);
                    } else {
                        let temp_arr = [];
                        for(let i = 0; i < fout.length; i++) {
                            if(fout[i].code > prop.code) {
                                temp_arr.push(fout[i]);
                            }else {
                                temp_arr.push(prop);
                                for(let j = i; j < fout.length; j++){
                                    temp_arr.push(fout[j]);
                                }
                                fout = temp_arr;
                                break;
                            }
                        }
                    }
                }
            }
        }
    });
    return fout;
}
//----------------------------------------------------------------------------------------------------------------------
const logs = (state: ILogsState = initialState(), action: ILogsSummaryAction): ILogsState => {
    switch (action.type) {
        case LogTypes.InitLogsAction:
            return initialState();
        case LogTypes.UpdateLogsSearchPhraseAction:
            return {
                ...state,
                searchLogsPhrase: action.payLoad.searchLogsPhrase,
                records: sortRecords(state.data, state.sortKey, state.sortMethod, action.payLoad.searchLogsPhrase)
            }
        case LogTypes.SortLogsAction:
            return {
                ...state,
                sortKey: action.payLoad.sortKey,
                sortMethod: action.payLoad.sortMethod,
                records: sortRecords(state.data, action.payLoad.sortKey, action.payLoad.sortMethod, state.searchLogsPhrase)
            };

        case LogTypes.AddLog:
            let data = state.data.concat(action.payLoad.error);
            return {
                ...state,
                data: data,
                records: sortRecords(data, state.sortKey, state.sortMethod, state.searchLogsPhrase)
            }
        default:
            return state;
    }
};
//----------------------------------------------------------------------------------------------------------------------
export { logs };