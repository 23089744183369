/**
 * Loadest - TemplatesTable container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import * as actions from "../../actions";
import DomainsTable from "./DomainsTable.component";
import {hideModal} from "../../actions";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        domains: state.domains.domains,
        sortMethod: state.domains.sortMethod,
        sortKey: state.domains.sortKey,
        magic: state.user.magic,
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => {
            dispatch(hideModal());
        },
        showDomainConfirmation: (domain, callback) => {
            dispatch(
                actions.showDomainConfirmation(
                    domain,
                    callback,
                    () => {
                        dispatch(actions.hideDomainConfirmation())
                    }
                )
            )
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainsTable));
