/**
 * Loadest - Testing reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {TestingTypes} from "../variables/Types";
import {ITesting} from "../interfaces/Testing";
import {ITestingSummaryAction} from "../actions/testing";

interface ITasksState{
    testings: ITesting[];
    loaded: boolean;
}

const initialState = (): ITasksState => ({
    testings: [],
    loaded: false,
});

const testing = (state: ITasksState = initialState(), action: ITestingSummaryAction): ITasksState => {
    switch (action.type) {
        case TestingTypes.UpdateTestingAction:
            return {
                ...state,
                testings: (action.payLoad.testings? action.payLoad.testings : []),
                loaded: action.payLoad.loaded,
            };
        default:
            return state;
    }
};

export { testing };