/**
 * Loadest - AdminNavbar component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { IAdminNavbarProps, IAdminNavbarState } from "./AdminNavbar.props";
import "./AdminNavbar.css";
// @ts-ignore
import logo from "../../assets/img/favicon.png";

class AdminNavbar extends React.Component<IAdminNavbarProps, IAdminNavbarState> {
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        document["handler"].log("AdminNavbar.constructor()");
        super(props);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.state = {
            sidebarExists: false
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    mobileSidebarToggle(e) {
        document["handler"].log("AdminNavbar.mobileSidebarToggle()");
        try {
            if (this.state.sidebarExists === false) {
                this.setState({
                    sidebarExists: true
                });
            }
            e.preventDefault();
            document.documentElement.classList.toggle("nav-open");
            var node = document.createElement("div");
            node.id = "bodyClick";
            node.onclick = function () {
                document["handler"].info("AdminNavbar.div(bodyClick).click()");
                if (document.documentElement.className === "nav-open") {
                    // @ts-ignore
                    this.parentElement.removeChild(this);
                    document.documentElement.classList.toggle("nav-open");
                }
            };
            document.body.appendChild(node);
        }catch(e){ }
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("AdminNavbar.render()");
        return (
            <Navbar className="AdminNavbar" fluid>
                <Navbar.Header>
                    <div className="mobileLogo">
                        <img src={logo} alt="loadest" width="100%" />
                    </div>
                    <Navbar.Brand>
                        {
                            window.innerWidth > 1360 ? this.props.brandText : "loadest"
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={(e)=>{
                        document["handler"].info("AdminNavbar.Navbar(Toggle).click()");
                        this.mobileSidebarToggle(e);
                    }} />
                    {
                        window.innerWidth < 1360
                        && <div style={{
                            width: "100%",
                            textAlign: "right",
                            paddingRight: "75px",
                            paddingTop: "15px",
                            whiteSpace: "nowrap"
                        }}>
                            <a
                                onClick={() => {
                                    // @ts-ignore
                                    window.location = "/payment";
                                }}
                                className="logoutBtn"
                                title="Account balance, tokens"
                                style={{ cursor: "pointer" }}
                            >
                                <div style={{ fontSize: 17, paddingTop: 3, fontWeight: 400 }}>
                                    <i className="pe-7s-gleam" style={{
                                        position: "absolute",
                                        marginTop: 0
                                    }} /><span style={{ paddingLeft: 20 }}>{
                                    this.props.user && this.props.user.balance.toFixed(2)
                                }</span>
                                </div>
                            </a>
                        </div>
                    }
                </Navbar.Header>
                <Navbar.Collapse>
                    { /* @ts-ignore */ }
                    <AdminNavbarLinks user={this.props.user} logout={this.props.logout} />
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export {AdminNavbar as default};
