/**
 * Loadest - Domains reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { DomainsTypes } from "../variables/Types";
import IDomain from "../interfaces/Domain";
import { IDomainsSummaryAction } from "../actions";

interface IDomainsState {
    domains: IDomain[];
    loaded: boolean;
}

const initialState = (): IDomainsState => ({
    domains: [],
    loaded: false,
});

const domains = (state: IDomainsState = initialState(), action: IDomainsSummaryAction): IDomainsState => {
    switch (action.type) {
        case DomainsTypes.UpdateDomainsAction:
            return {
                ...state,
                domains: (action.payLoad.domains? action.payLoad.domains : []),
                loaded: action.payLoad.loaded,
            };
        default:
            return state;
    }
};

export { domains };