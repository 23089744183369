/**
 * Loadest - User reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { IUser } from "../interfaces/User";
import { IUserSummaryAction } from "../actions/user";
import { UserTypes } from "../variables/Types";

const initialState = (): IUser => ({
    name: null,
    email: null,
    sid: null,
    magic: null,
});

const user = (state: IUser = initialState(), action: IUserSummaryAction): IUser => {
    switch (action.type) {
        case UserTypes.LoadUserInfoAction:
            return {
                ...state,
                ...action.payLoad
            };
        case UserTypes.LogoutUserAction: {
            return {
                ...initialState(),
            }
        }
        default:
            return state;
    }
};

export { user };