/**
 * Loadest - Terms and conditions page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import "./TermsPage.css";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
import Footer from "../../components/Footer/Footer";

export default function TermsPage(props) {
    //@ts-ignore
    const classes = makeStyles(styles)();
    const { ...rest } = props;
    document.title = 'Terms and Conditions';
    return (
        <div className="TermsPage">
            <Header
                absolute
                color="transparent"
                brand={
                    <div className="siteLogo">
                        <i className="pe-7s-gleam siteIcon" />
                        <div className="siteName">
                            loadest
                        </div>
                    </div>
                }
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader + " pageHeader"}
                style={{ backgroundImage: "url(" + image + ")" }}
            >
                <div className={classNames(classes.main, classes.mainContent)}>
                    <div className={classNames(classes.container, classes.textContainer)}>
                        <div>
                            <h1>Terms & Conditions</h1>
                            <p>
                                These Website Standard Terms and Conditions written on this webpage shall manage your use of
                                this website. These Terms will be applied fully and affect to your use of this Website. By using
                                this Website, you agreed to accept all terms and conditions written in here. You must not use
                                this Website if you disagree with any of these Website Standard Terms and Conditions.
                                Minors or people below 18 years old are not allowed to use this Website.
                            </p>
                            <h2>Restrictions</h2>
                            <p>
                                You are specifically restricted from all of the following:<br />
                                <ul>
                                    <li>Use the Website functions in relation to the resources that do not belong to you.</li>
                                    <li>Using this Website in any way that impacts user access to this Website.</li>
                                    <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity.</li>
                                    <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website.</li>
                                    <li>Using this Website to engage in any advertising or marketing.</li>
                                </ul>
                                Certain areas of this Website are restricted from being access by you and may further restrict
                                access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and
                                password you may have for this Website are confidential and you must maintain confidentiality as
                                well.
                            </p>
                            <h2>No warranties</h2>
                            <p>
                                This Website is provided “as is,” with all faults, and express no representations or warranties,
                                of any kind related to this Website or the materials contained on this Website. Also, nothing
                                contained on this Website shall be interpreted as advising you.
                                All responsibility for problems arising as a result of using the functions of the Website
                                with respect to third parties lies with the initiator of the test.
                            </p>
                            <h2>Limitation of liability</h2>
                            <p>
                                In no event shall , nor any of its officers, directors and employees, shall be held liable for
                                anything arising out of or in any way connected with your use of this Website whether such
                                liability is under contract. , including its officers, directors and employees shall not be held
                                liable for any indirect, consequential or special liability arising out of or in any way related
                                to your use of this Website.
                            </p>
                            <h2>Indemnification</h2>
                            <p>
                                You hereby indemnify to the fullest extent from and against any and/or all liabilities, costs,
                                demands, causes of action, damages and expenses arising in any way related to your breach of any
                                of the provisions of these Terms.
                            </p>
                            <h2>Severability</h2>
                            <p>
                                If any provision of these Terms is found to be invalid under any applicable law, such provisions
                                shall be deleted without affecting the remaining provisions herein.
                            </p>
                            <h2>Variation of Terms</h2>
                            <p>
                                It is permitted to revise these Terms at any time as it sees fit, and by using this Website you are
                                expected to review these Terms on a regular basis.
                            </p>
                            <h2>Assignment</h2>
                            <p>
                                The is allowed to assign, transfer, and subcontract its rights and/or obligations under these
                                Terms without any notification. However, you are not allowed to assign, transfer, or subcontract
                                any of your rights and/or obligations under these Terms.
                            </p>
                            <h2>Entire Agreement</h2>
                            <p>
                                These Terms constitute the entire agreement between and you in relation to your use of this
                                Website, and supersede all prior agreements and understandings.
                            </p>
                            <h2>Contacting Us</h2>
                            <p>
                                If there are any questions regarding this terms and conditions, you may contact us via email <a href={"mailto:" + process.env.REACT_APP_ADMIN_EMAIL}>{process.env.REACT_APP_ADMIN_EMAIL}</a>
                            </p>
                            <br />
                            <div style={{textAlign:"right"}}>
                                Terms and conditions was updated at 02/15/2022 14:05 UTC
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h50" />
                <Footer  cookies={true} linearGradient={false} />
            </div>
        </div>
    );
}