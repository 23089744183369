/**
 * Loadest - InfoArea Props.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import PropTypes from "prop-types";

const InfoAreaDefaultProps = {
    iconColor: "gray"
};

const InfoAreaPropTypes = {
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    iconColor: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    vertical: PropTypes.bool
};

export {InfoAreaDefaultProps, InfoAreaPropTypes}
