/**
 * Loadest - Admin router file.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import Dashboard from "./views/Dashboard/Dashboard";
import TestingList from "./views/TestingList/TestingList";
import TemplateList from "./views/TemplateList/TemplateList";
import DomainList from "./views/DomainList/DomainList";
import LogList from "./views/LogList/LogList";
import NodeList from "./views/NodeList/NodeList";
import ErrorList from "./views/ErrorList/ErrorList";

const adminRoutes = [
    {
        path: "/dashboard",
        display: true,
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: Dashboard,
        layout: "/app"
    },
    {
        path: "/testing",
        display: true,
        name: "Load Testings",
        icon: "pe-7s-gleam",
        component: TestingList,
        layout: "/app"
    },
    {
        path: "/templates",
        display: true,
        name: "Templates",
        icon: "pe-7s-browser",
        component: TemplateList,
        layout: "/app"
    },
    {
        path: "/domains",
        display: true,
        name: "Domains",
        icon: "pe-7s-network",
        component: DomainList,
        layout: "/app"
    },
    {
        path: "/network",
        display: false,
        name: "Network",
        admin: true,
        icon: "pe-7s-share",
        component: NodeList,
        layout: "/app"
    },
    {
        path: "/logs",
        display: false,
        name: "Logs",
        admin: true,
        icon: "pe-7s-box1",
        component: LogList,
        layout: "/app"
    },
    {
        path: "/errors",
        display: false,
        admin: true,
        name: "Errors",
        icon: "pe-7s-attention",
        component: ErrorList,
        layout: "/app"
    }
];

export default adminRoutes;
