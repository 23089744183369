/**
 * Loadest - Domains actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { Action } from 'redux';
import { DomainsTypes } from "../variables/Types";
import IDomain from "../interfaces/Domain";

interface ILoadDomainsAction extends Action {
    payLoad: {
        domains: IDomain[],
        loaded: boolean,
    }
}

const loadDomainsList = (data: IDomain[]):ILoadDomainsAction => ({
    type: DomainsTypes.UpdateDomainsAction,
    payLoad: {
        domains: data,
        loaded: true
    }
});

const resetDomainList = ():ILoadDomainsAction => ({
    type: DomainsTypes.UpdateDomainsAction,
    payLoad: {
        domains: [],
        loaded: false
    }
});

type IDomainsSummaryAction =
    ILoadDomainsAction;

export {
    ILoadDomainsAction,
    IDomainsSummaryAction,
    loadDomainsList,
    resetDomainList,
};
