/**
 * Loadest - Email confirmation page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import { withStyles } from '@material-ui/styles';
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/GridItem/GridItem";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import Footer from "../../components/Footer/Footer";
import {
    IConfirmationPageProps,
    IConfirmationPageState
} from "./ConfirmationPage.interface";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
//@ts-ignore
import image from "../../assets/img/background.jpeg";
// @ts-ignore
import spinner from "../../assets/img/load.gif";
import "./ConfirmationPage.css";

class ConfirmationPage extends React.Component<IConfirmationPageProps, IConfirmationPageState> {
    renderId: number;
    onKeyPress: (e:any) => void;
    isLoaded: boolean;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("ConfirmationPage.constructor()");
        this.renderId = 0;
        this.isLoaded = false;
        this.state = {
            dialog: {
                display: false,
                title: '',
                onOk: () => {}
            },
            loading: false,
            url: "",
            sended: false,
            code: "",
            error: "",
        };
        this.onKeyPress = function(e) {
            document["handler"].log(`ConfirmationPage.onKeyPress(${e.code})`);
            if (
                (e.code === "Enter" || e.code === "NumpadEnter") &&
                window.location.href.indexOf("/confirmation") >= 0
            ) {
                try {
                    this.onRequest();
                } catch (e) { /* ... */ }
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        document["handler"].log("ConfirmationPage.componentDidMount()");
        if (document["reduxStore"].getState().user.sid) {
            if (document["reduxStore"].getState().user.confirmed) {
                this.props.history.push('/payment');
            } else {
                setTimeout(() => document.title = 'Email confirmation', 100);
                try {
                    if (window.innerWidth >= 768) {
                        const el = document.getElementById("url");
                        if (el) {
                            el.focus();
                        }
                    }
                } catch (e) { /* ... */ }
                document.addEventListener('keypress', e => this.onKeyPress(e), false);
            }
        } else {
            this.props.history.push('/signup');
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount(): void {
        document["handler"].log("ConfirmationPage.componentWillUnmount()");
        this.onKeyPress = e => {};
    }
    //------------------------------------------------------------------------------------------------------------------
    setDialog(title:string = null, onOk: () => void = null): void {
        document["handler"].log(`ConfirmationPage.setDialog(${title})`);
        this.setState({
            dialog: {
                display: title != null,
                title: title,
                onOk: onOk
             }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    setLoading(value:boolean): void {
        document["handler"].log(`ConfirmationPage.setLoading(${value})`);
        this.setState({ loading: value });
    }
    //------------------------------------------------------------------------------------------------------------------
    onRequest() {
        document["handler"].log(`ConfirmationPage.onRequest()`);
        if (!this.state.sended) {
            this.setState({ loading: true });
            document["dataSource"].sendConfirmationCode().then((result) => {
                if (result) {
                    document["dataSource"].initData().then((result) => {
                        setTimeout(() => {
                            this.setState({
                                sended: true,
                                loading: false
                            });
                            try {
                                document.getElementById("code").focus();
                            } catch (e) {}
                        }, 100);
                    });
                } else {
                    this.setState({ error: "Unable to send confirmation code" });
                }
            })
        } else {
            document["dataSource"].emailConfirmation(this.state.code).then((result) => {
                if (result) {
                    document["dataSource"].initData().then((result) => {
                        if (result) {
                            this.props.history.push('/payment');
                        }
                    });
                } else {
                    this.setState({ error: "Invalid confirmation code" });
                }
            })
        }
    };
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("ConfirmationPage.render() №" + (++this.renderId));
        document["handler"].log(this.state);
        if (!this.isLoaded) {
            this.isLoaded = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 1);
        }
        const { classes, ...rest } = this.props;
        return (
            <div className="confirmationPage">
                <Header
                    absolute
                    color="transparent"
                    brand={
                        <div className="siteLogo">
                            <i className="pe-7s-gleam siteIcon" />
                            <div className="siteName">
                                loadest
                            </div>
                        </div>
                    }
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <div
                    className={classes.pageHeader + " pageHeader"}
                    style={{ backgroundImage: "url(" + image + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6} style={{ maxWidth: "480px" }}>
                                <Card content={
                                    <form className={classes.form}>
                                        <h1 className={classes.loginCaption} style={{ fontSize: "28px" }}>Email confirmation</h1>
                                        {
                                            this.state.loading &&
                                            <div className="loader">
                                                <img src={spinner} height={150} />
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>
                                                <div style={{
                                                    color: "rgb(102, 102, 102)",
                                                    paddingTop: "20px",
                                                    paddingBottom: "10px",
                                                    textAlign: "center",
                                                    fontSize: "14px"
                                                }}>
                                                    {
                                                        !this.state.sended && <p>
                                                            Please validate your email address
                                                        </p>
                                                    }
                                                    {
                                                        this.state.sended && <p>
                                                            Check your email and insert code from the message
                                                        </p>
                                                    }
                                                </div>
                                                <div style={{ display: "table", width: "100%" }}>
                                                    {
                                                        this.state.sended
                                                        && <div style={{ display: "table-cell", textAlign: "right", verticalAlign: "top" }}>
                                                            <CustomInput
                                                                labelText="Confirmation code"
                                                                id="code"
                                                                defaultValue=""
                                                                value={this.state.code}
                                                                formControlProps={{
                                                                    className: "input"
                                                                }}
                                                                placeholder={""}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        error: "",
                                                                        code: e.target.value,
                                                                    })
                                                                    try {
                                                                        e.target.parentNode.style.background = "transparent";
                                                                    } catch(e) { /*...*/ }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    <div style={{
                                                        display: "table-cell",
                                                        paddingTop: 25,
                                                        textAlign: this.state.sended ? "left" : "center",
                                                        verticalAlign: "top"
                                                    }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            style={{ margin: "0px auto", marginBottom: "20px" }}
                                                            onClick={() => this.onRequest()}
                                                            disabled={ this.state.sended && this.state.code.length < 6 }
                                                        >
                                                            {
                                                                this.state.sended ? "Continue" : "Send confirmation code"
                                                            }
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                }
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="h100" />
                    <Footer  cookies={true} linearGradient={false} />
                </div>
                {
                    this.state.dialog.display &&
                    <CustomDialog
                        open={true}
                        caption={this.state.dialog.title.toString()}
                        close={() => {
                            this.state.dialog.onOk();
                            this.setDialog();
                        }}
                    />
                }
            </div>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(ConfirmationPage);
