/**
 * Loadest - Data container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import AppLoader from "./appLoader";
import { Store } from "redux";
import {
    addError,
    loadDashboard,
    loadDomainsList,
    loadTestingList,
} from "./actions";
import cookie from 'react-cookies'
import showError from "./functions/showError";
import showWarning from "./functions/showWarning";
import { UserWrapper } from "./interfaces/User";
import { loadUserInfo } from "./actions/user";
import {
    EXPRESS_TEST_UNKNOWN_ERROR,
    NEW_TEMPLATE_UNKNOWN_ERROR,
    UNKNOWN_ERROR
} from "./variables/language";
import { loadTemplatesList } from "./actions/templates";

export default class DataSource {
    url: string;
    store: Store;
    loader: AppLoader;
    data: any;
    request: string;
    errorState: number;
    requeryCount: number;
    timeout: number;
    domain: string;
    interval: NodeJS.Timer;
    //------------------------------------------------------------------------------------------------------------------
    constructor(store: Store, loader: AppLoader) {
        document["handler"].log("DataSource.constructor()");
        this.url = process.env.REACT_APP_API_URL;
        this.store = store;
        this.loader = loader;
        this.data = {};
        this.request = "";
        this.errorState = 0;
        this.domain = "";
        this.requeryCount = parseInt(process.env.REACT_APP_REQUERY_COUNT);
        if(!this.requeryCount) this.requeryCount = 1;
        this.timeout = parseInt(process.env.REACT_APP_REQUEST_TIMEOUT)*1000;
        document["dataSource"] = this;
    }
    //------------------------------------------------------------------------------------------------------------------
    submitRequest(
        url:string,
        method:string,
        body:any,
        func:(func:any) => void,
        strict: boolean = true,
        requery: boolean = true
    ): Promise<string|void>{
        return new Promise((callback) => {
            document["handler"].log("DataSource.submitRequest()");
            try {
                let xhr = new XMLHttpRequest();
                if (url.indexOf('http') < 0) {
                    xhr.open(method, this.url + url, true);
                } else {
                    xhr.open(method, url, true);
                }
                xhr.timeout = this.timeout;
                xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
                xhr.send(body);
                let flag = false;
                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return false;
                    }
                    if (!flag) {
                        flag = true;
                        if (xhr.status !== 200 && xhr.status !== 400 && xhr.status !== 402) {
                            document["handler"].error(
                                "dataSource.submitRequest(url=" + url + ")" +
                                ".xhr(status=" + xhr.status + ") -> " + xhr.statusText
                            );
                            if (strict) {
                                document["handler"].error(xhr.responseText);
                                document["handler"].throw();
                            } else if (requery && ++this.errorState < this.requeryCount) {
                                this.submitRequest(url, method, body, func, strict, requery).then(() => callback(null));
                            } else {
                                callback(false);
                            }
                        } else {
                            if (xhr.status === 402 && window.location.toString().indexOf("payment") == -1) {
                                showError("To continue working with the service, you should confirm your membership");
                                setTimeout(() => {
                                    // @ts-ignore
                                    window.location = "/payment";
                                }, 300);
                                callback(false);
                            } else {
                                this.errorState = 0;
                                callback(xhr.responseText);
                            }
                        }
                    }
                };
            } catch(e) {
                if (strict) {
                    document["handler"].error("DataSource.submitRequest(url=" + url + ") -> "+e.message);
                    document["handler"].throw();
                } else if (++this.errorState < this.requeryCount) {
                    this.submitRequest(url, method, body, func).then(() => callback(null));
                } else {
                    callback(false);
                }
            }
        }).then(xhr => func(xhr));
    }
    //------------------------------------------------------------------------------------------------------------------
    getApiInfo(): Promise<string|void>{
        return new Promise((callback) => {
            document["handler"].log("DataSource.getApiInfo()");
            try {
                let xhr = new XMLHttpRequest();
                xhr.open("GET", process.env.REACT_APP_API_MD, true);
                xhr.timeout = this.timeout;
                xhr.send();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return false;
                    }
                    if (xhr.status === 200) {
                        this.errorState = 0;
                        callback(xhr.responseText);
                    }
                };
            }catch(e){
                document["handler"].error("DataSource.getApiInfo() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getDaoInfo(): Promise<string|void>{
        return new Promise((callback) => {
            document["handler"].log("DataSource.getApiInfo()");
            try {
                let xhr = new XMLHttpRequest();
                xhr.open("GET", process.env.REACT_APP_DAO_MD, true);
                xhr.timeout = this.timeout;
                xhr.send();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return false;
                    }
                    if (xhr.status === 200) {
                        this.errorState = 0;
                        callback(xhr.responseText);
                    }
                };
            }catch(e){
                document["handler"].error("DataSource.getApiInfo() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getTesingInfo(id): Promise<string|boolean>{
        return new Promise((callback) => {
            document["handler"].log(`DataSource.getTesingInfo(${id})`);
            try {
                const data = { id };
                this.submitRequest("testing_details", "POST", JSON.stringify(data), (response) => {
                    const data = JSON.parse(response);
                    if (data && data.payLoad) {
                        callback(data.payLoad);
                    } else {
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error("DataSource.getTesingInfo() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    shareTesting(id): Promise<string|boolean>{
        return new Promise((callback) => {
            document["handler"].log(`DataSource.shareTesting(${id})`);
            try {
                let sid = cookie.load('sid');
                const data = { id, sid };
                this.submitRequest("share", "POST", JSON.stringify(data), (response) => {
                    const data = JSON.parse(response);
                    if (data && data.payLoad) {
                        callback(data.payLoad);
                    } else {
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error("DataSource.shareTesting() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    expressRequest(request): Promise<boolean>{
        return new Promise((callback) => {
            document["handler"].log("dataSource.expressRequest()");
            try {
                const data = {
                    request: request
                };
                this.submitRequest("log", "POST", JSON.stringify(data), (response) => {
                    callback(response === false);
                });
            }catch(e){
                document["handler"].error("dataSource.expressRequest() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    initData(): Promise<boolean> {
        document["handler"].info("Connecting to "+this.url);
        return new Promise((callback) => {
            document["handler"].log("dataSource.initData()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = { sid: sid };
                this.submitRequest("init", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].info("Connected!");
                        document["handler"].log("dataSource.initData().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            if (this.data.payLoad && this.data.payLoad.sid) {
                                if (!document["initInterval"]) {
                                    document["initInterval"] = setInterval(() => this.initData(), 30 * 1000);
                                }
                                console.error(this.store.getState());
                                cookie.save('sid', this.data.payLoad.sid, { path: '/', maxAge: 2628000 });
                                if (this.data.payLoad.payment_id
                                    && window.location.toString().indexOf("/payment") < 0
                                    && !this.store.getState().user.sid
                                ) {
                                    // @ts-ignore
                                    window.location = "/payment";
                                } else {
                                    this.store.dispatch( loadUserInfo( UserWrapper( this.data.payLoad )));
                                }
                                try {
                                    localStorage.setItem("sid", this.data.payLoad.sid);
                                } catch(e) { /* ... */ }
                            }
                            this.loader.load("dataSource");
                            callback(true);
                        } else {
                            cookie.remove('sid', { path: '/' });
                            try {
                                localStorage.removeItem("sid");
                            } catch(e) { /* ... */ }
                            if (this.data.error) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                alert(this.data.error.message);
                            }
                            this.loader.load("dataSource");
                            callback(false);
                        }
                    } else {
                        if (this.url === process.env.REACT_APP_API_URL) {
                            document["handler"].info("Reconnecting to secondary DAO server");
                            document["handler"].setFlag(true);
                            this.url = process.env.REACT_APP_API_MIRROR;
                            this.errorState = 0;
                            this.initData().then(fout => callback(fout));
                        } else if (this.errorState === this.requeryCount){
                            document["handler"].error("dataSource.initData().submitRequest << null");
                            document["handler"].throw();
                            callback(false);
                        }
                    }
                }, false);
            } catch(e) {
                if (this.url === process.env.REACT_APP_API_URL) {
                    document["handler"].info("Reconnecting to secondary DAO server");
                    document["handler"].setFlag(true);
                    this.url = process.env.REACT_APP_API_MIRROR;
                    this.errorState = 0;
                    this.initData().then(fout => callback(fout));
                } else {
                    document["handler"].error("DataSource.initData() -> "+e.message);
                }
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    signupUser(name, email, pass, notify): Promise<boolean> {
        return new Promise((callback) => {
            document["handler"].log("dataSource.signupUser()");
            try {
                const request = {
                    name: name,
                    email: email,
                    pass: pass
                };
                this.submitRequest("signup", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.signupUser().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.payLoad.sid &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                setTimeout(() => {
                                    notify(this.data.error.message);
                                }, 500);
                            }
                            this.store.dispatch( loadUserInfo( UserWrapper( this.data.payLoad )));
                            cookie.save('sid', this.data.payLoad.sid, { path: '/', maxAge: 2628000 });
                            try {
                                localStorage.setItem("sid", this.data.payLoad.sid);
                            } catch(e) { /* ... */ }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch(addError(this.data.error));
                            setTimeout(() => {
                                notify(this.data.error.message);
                            }, 500);
                            callback(false);
                        }
                    }else{
                        document["handler"].log("dataSource.signupUser().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "При регистрации произошла ошибка",
                            category: "Неизвестная ошибка",
                            date: Date.now()
                        }));
                        showError("При регистрации произошла неизвестная ошибка.");
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error("DataSource.signupUser() -> " + e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    loginUser(email, pass, notify): Promise<boolean> {
        return new Promise((callback) => {
            document["handler"].log("dataSource.loginUser()");
            try {
                const request = {
                    email: email,
                    pass: pass
                };
                this.submitRequest("login", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.loginUser().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.payLoad.sid &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                setTimeout(() => {
                                    notify(this.data.error.message);
                                }, 500);
                            }
                            this.store.dispatch( loadUserInfo( UserWrapper( this.data.payLoad )));
                            cookie.save('sid', this.data.payLoad.sid, { path: '/', maxAge: 2628000 });
                            try {
                                localStorage.setItem("sid", this.data.payLoad.sid);
                            } catch(e) { /* ... */ }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch(addError(this.data.error));
                            setTimeout(() => {
                                notify(this.data.error.message);
                            }, 500);
                            callback(false);
                        }
                    }else{
                        document["handler"].log("dataSource.loginUser().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "При авторизации произошла ошибка",
                            category: "Неизвестная ошибка",
                            date: Date.now()
                        }));
                        showError("При авторизации произошла неизвестная ошибка.");
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error("DataSource.loginUser() -> " + e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    createTemplate(
        label:string,
        method:string,
        host:string,
        port:number,
        tps:number,
        count:number,
        links:string,
        nodes_assigned:number,
        headers:string,
        post:string
    ): Promise<boolean> {
        return new Promise((callback) => {
            document["handler"].log("dataSource.createTemplate()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    label: label,
                    request: method,
                    host: host,
                    port: port,
                    tps: tps,
                    min: count,
                    max: count,
                    errors: 0,
                    timeout: 1000,
                    links: links,
                    nodes_assigned: nodes_assigned,
                    headers: headers,
                    post: post,
                };
                this.submitRequest("create_template", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.createTemplate().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.payLoad.template_id &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showWarning(this.data.error.message);
                                }
                            }
                            this.getTemplates().then(result => callback(result));
                        } else {
                            if (this.data && this.data.error!= null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showError(this.data.error.message);
                                }
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.createTemplate().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: NEW_TEMPLATE_UNKNOWN_ERROR,
                            category: UNKNOWN_ERROR,
                            date: Date.now()
                        }));
                        showError(NEW_TEMPLATE_UNKNOWN_ERROR);
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error("DataSource.createTemplate() -> " + e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    updateTemplate(
        id:number,
        label:string,
        method:string,
        host:string,
        port:number,
        tps:number,
        count:number,
        links:string,
        nodes_assigned:number,
        headers:string,
        post:string,
    ): Promise<boolean> {
        return new Promise((callback) => {
            document["handler"].log("dataSource.updateTemplate()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    template_id: id,
                    label: label,
                    request: method,
                    host: host,
                    port: port,
                    tps: tps,
                    min: count,
                    max: count,
                    errors: 0,
                    timeout: 1000,
                    links: links,
                    nodes_assigned: nodes_assigned,
                    headers: headers,
                    post: post,
                };
                this.submitRequest("update_template", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.updateTemplate().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showWarning(this.data.error.message);
                                }
                            }
                            this.getTemplates().then(result => callback(result));
                        } else {
                            if (this.data && this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showError(this.data.error.message);
                                }
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.updateTemplate().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: NEW_TEMPLATE_UNKNOWN_ERROR,
                            category: UNKNOWN_ERROR,
                            date: Date.now()
                        }));
                        showError(NEW_TEMPLATE_UNKNOWN_ERROR);
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error("DataSource.updateTemplate() -> " + e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    createTest(
        label:string,
        method:string,
        host:string,
        port:number,
        tps:number,
        count:number,
        links:string,
        nodes_assigned:number,
        headers:string = "",
        post:string = ""
    ): Promise<boolean> {
        document["handler"].log("DataSource.createTest()");
        return new Promise((callback, reject) => {
            document["handler"].log("dataSource.createTest()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    label: label,
                    request: method,
                    host: host,
                    port: port,
                    tps: tps,
                    min: count,
                    max: count,
                    errors: 0,
                    timeout: 1000,
                    links: links,
                    nodes_assigned: nodes_assigned,
                    headers: headers,
                    post: post,
                };
                this.submitRequest("create_task", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.createTest().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.payLoad.task_id &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showWarning(this.data.error.message);
                                }
                            }
                            callback(this.data.payLoad.message ? this.data.payLoad.message : true);
                        } else {
                            if (this.data && this.data.error != null) {
                                this.store.dispatch(addError(this.data.error));
                                if (this.data.error.message) {
                                    showError(this.data.error.message);
                                    reject(this.data.error.message);
                                }
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.createTest().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: EXPRESS_TEST_UNKNOWN_ERROR,
                            category: UNKNOWN_ERROR,
                            date: Date.now()
                        }));
                        showError(EXPRESS_TEST_UNKNOWN_ERROR);
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error("DataSource.createTest() -> " + e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    createPayment(chain:string, sum:number): Promise<boolean|any> {
        document["handler"].log(`DataSource.createPayment()`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    chain: chain,
                    sum: sum
                };
                console.error(request);
                this.submitRequest("create_payment", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.createPayment().submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(this.data.payLoad);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.createPayment().submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while payment process",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while payment process");
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error("DataSource.createPayment() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    cancelPayment(payment_id): Promise<string|boolean>{
        return new Promise((callback) => {
            document["handler"].log(`DataSource.cancelPayment(${payment_id})`);
            try {
                let sid = cookie.load('sid');
                const data = { payment_id, sid };
                this.submitRequest("cancel_payment", "POST", JSON.stringify(data), (response) => {
                    document["handler"].log(`DataSource.cancelPayment().submitRequest <<`);
                    document["handler"].log(response);
                    this.data = JSON.parse(response);
                    if (
                        this.data &&
                        this.data.success
                    ) {
                        if (this.data.error != null) {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch(addError(this.data.error));
                            showWarning(this.data.error.message);
                        }
                        callback(true);
                    } else {
                        if (this.data.error != null) {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch(addError(this.data.error));
                        }
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error("DataSource.cancelPayment() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    completePayment(payment_id): Promise<boolean|any> {
        document["handler"].log(`DataSource.completePayment()`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    payment_id
                };
                this.submitRequest("finish_payment", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.completePayment().submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.createPayment().submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while finishing payment process",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while finishing payment process");
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error("DataSource.completePayment() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    expressTest(url, email): Promise<boolean> {
        return new Promise((callback, reject) => {
            document["handler"].log(`DataSource.expressTest(${url}, ${email})`);
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    url: url,
                    email: email
                };
                this.submitRequest("express_test", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.expressTest().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (this.data.payLoad && this.data.payLoad.sid) {
                            this.store.dispatch( loadUserInfo( UserWrapper( this.data.payLoad )));
                            cookie.save('sid', this.data.payLoad.sid, { path: '/', maxAge: 2628000 });
                            try {
                                localStorage.setItem("sid", this.data.payLoad.sid);
                            } catch(e) { /* ... */ }
                        }
                        this.domain = this.data.payLoad.domain ? this.data.payLoad.domain : null;
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.payLoad.sid &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                reject(this.data.error.message);
                            } else {
                                callback(this.data.payLoad.message);
                            }
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch(addError(this.data.error));
                            reject(this.data.error.message);
                        }
                    } else {
                        document["handler"].log("dataSource.expressTest().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: EXPRESS_TEST_UNKNOWN_ERROR,
                            category: UNKNOWN_ERROR,
                            date: Date.now()
                        }));
                        showError(EXPRESS_TEST_UNKNOWN_ERROR);
                        callback(false);
                    }
                });
            } catch (e) {
                document["handler"].error(`DataSource.expressTest(${url}, ${email}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    confirmDomain(domain:string): Promise<boolean> {
        document["handler"].log(`DataSource.confirmDomain(${domain})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    domain: domain,
                };
                this.submitRequest("confirm_domain", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.confirmDomain(${domain}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.confirmDomain(${domain}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while processing domain rights verification",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while processing domain rights verification");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.confirmDomain() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    stopTesting(host:string): Promise<boolean> {
        document["handler"].log(`DataSource.stopTesting(${host})`);
        return new Promise((callback) => {
            try {
                const request = {
                    host: host,
                };
                this.submitRequest("stop", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.stopTesting(${host}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.stopTesting(${host}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while stopping test",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while stopping test");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.stopTesting() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    sendConfirmationCode(): Promise<boolean> {
        document["handler"].log(`DataSource.sendConfirmationCode()`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("send_code", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.sendConfirmationCode().submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.sendConfirmationCode().submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while request confirmation code",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while request confirmation code");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.sendConfirmationCode() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    confirmInstruct(): Promise<boolean> {
        document["handler"].log(`DataSource.confirmInstruct()`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid
                };
                this.submitRequest("instruct", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.confirmInstruct().submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.confirmInstruct().submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.confirmInstruct() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    emailConfirmation(code:string): Promise<boolean> {
        document["handler"].log(`DataSource.emailConfirmation()`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    code: code
                };
                this.submitRequest("confirm_email", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.emailConfirmation().submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.emailConfirmation().submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while confirm email",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while confirm email");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.emailConfirmation() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    restorePassword(email:string): Promise<boolean> {
        document["handler"].log(`DataSource.restorePassword(${email})`);
        return new Promise((callback) => {
            try {
                const request = {
                    email: email,
                };
                this.submitRequest("reset", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.restorePassword(${email}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                            }
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.restorePassword(${email}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An unknown error occurred while reseting password",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while reseting password");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.restorePassword() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    pauseTesting(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.pauseTesting(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    task_id: id,
                };
                this.submitRequest("pause_task", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.pauseTesting(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.pauseTesting(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while pausing the test",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while pausing the test");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error("DataSource.pauseTesting() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    continueTesting(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.continueTesting(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    task_id: id,
                };
                this.submitRequest("continue_task", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.continueTesting(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.continueTesting(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while reactivating the testing",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while reactivating the testing");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error(`DataSource.continueTesting(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    deleteTesting(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.deleteTesting(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    task_id: id,
                };
                this.submitRequest("delete_task", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.deleteTesting(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.deleteTesting(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while deleting the testing",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while deleting the testing");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error(`DataSource.continueTesting(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    deleteLog(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.deleteLog(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    log_id: id,
                };
                this.submitRequest("delete_log", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.deleteLog(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.deleteLog(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while deleting the log",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while deleting the log");
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error(`DataSource.deleteLog(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    restartNode(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.restartNode(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    id: id,
                };
                this.submitRequest("restart", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.restartNode(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.restartNode(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while restarting the node",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while restarting the node");
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error(`DataSource.restartNode(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    deleteTemplate(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.deleteTemplate(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    template_id: id,
                };
                this.submitRequest("delete_template", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.deleteTemplate(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.deleteTemplate(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while deleting the templates",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while deleting the templates");
                        callback(false);
                    }
                });
            } catch(e) {
                document["handler"].error(`DataSource.continueTesting(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    deleteDomain(id:number): Promise<boolean> {
        document["handler"].log(`DataSource.deleteDomain(${id})`);
        return new Promise((callback) => {
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                    domain_id: id,
                };
                this.submitRequest("delete_domain", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log(`DataSource.deleteDomain(${id}).submitRequest <<`);
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log(`DataSource.deleteDomain(${id}).submitRequest << null`);
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while deleting the domain record",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while deleting the domain record");
                        callback(false);
                    }
                });
            }catch(e){
                document["handler"].error(`DataSource.deleteDomain(${id}) -> ${e.message}`);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getDashboard(): Promise<boolean> {
        document["handler"].log("DataSource.getDashboard()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getDashboard()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("testing_list", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getDashboard().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            this.store.dispatch( loadDashboard( this.data.payLoad[this.data.payLoad.length - 1] ));
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else if (response != false) {
                        document["handler"].log("dataSource.getDashboard().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the dashboard info",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the dashboard info");
                        callback(false);
                    } else {
                        callback(false);
                    }
                }, false, false);
            }catch(e){
                document["handler"].error("DataSource.getDashboard() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getTestings(): Promise<boolean> {
        document["handler"].log("DataSource.getTestings()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getTestings()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid
                };
                this.submitRequest("testing_list", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getTestings().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            Array.isArray(this.data.payLoad) &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            this.store.dispatch( loadTestingList( this.data.payLoad ));
                            callback(true);
                        } else {
                            if (this.data.timeStamp) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showError(this.data.error.message);
                            }
                            callback(false);
                        }
                    } else if (response != false) {
                        document["handler"].log("dataSource.getTestings().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the testing list",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the testing list");
                        callback(false);
                    } else {
                        callback(false);
                    }
                }, false, false);
            }catch(e){
                document["handler"].error("DataSource.getTestings() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getTemplates(): Promise<boolean> {
        document["handler"].log("DataSource.getTemplates()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getTemplates()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("template_list", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getTemplates().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            Array.isArray(this.data.payLoad) &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            this.store.dispatch( loadTemplatesList( this.data.payLoad ));
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getTemplates().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the template list",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the template list");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getTemplates() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getErrors(): Promise<boolean|any[]> {
        document["handler"].log("DataSource.getErrors()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getErrors()");
            try {
                this.submitRequest("https://pool.loadest.nodes-studio.com:2342/list", "GET", null, (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getErrors().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        console.log(this.data);
                        if (
                            this.data &&
                            Array.isArray(this.data)
                        ) {
                            callback(this.data);
                        } else {
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getErrors().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the error stacktraces.",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the error stacktraces.");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getErrors() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getLogs(): Promise<boolean|any[]> {
        document["handler"].log("DataSource.getLogs()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getLogs()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("logs", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getLogs().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        console.log(this.data);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            Array.isArray(this.data.payLoad) &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(this.data.payLoad);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getLogs().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the list of logs.",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the list of logs.");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getLogs() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getNodes(): Promise<boolean|any[]> {
        document["handler"].log("DataSource.getNodes()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getNodes()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("node_list", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getNodes().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        console.log(this.data);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            Array.isArray(this.data.payLoad) &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(this.data.payLoad);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getNodes().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the list of nodes.",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the list of nodes.");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getNodes() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getDomains(): Promise<boolean> {
        document["handler"].log("DataSource.getDomains()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getDomains()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("domain_list", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getDomains().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            Array.isArray(this.data.payLoad) &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            this.store.dispatch( loadDomainsList( this.data.payLoad ));
                            callback(true);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getDomains().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the list of domains.",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the list of domains.");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getDomains() -> "+e.message);
            }
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    getPaymentInfo(): Promise<boolean|{ wallets: any[], transaction: any}> {
        document["handler"].log("DataSource.getPaymentInfo()");
        return new Promise((callback) => {
            document["handler"].log("dataSource.getPaymentInfo()");
            try {
                let sid = cookie.load('sid');
                if (!sid) {
                    try {
                        sid = localStorage.getItem("sid");
                    } catch (e) { /* ... */ }
                }
                const request = {
                    sid: sid,
                };
                this.submitRequest("finance", "POST", JSON.stringify(request), (response) => {
                    if (response != null && response != false) {
                        document["handler"].log("dataSource.getPaymentInfo().submitRequest <<");
                        document["handler"].log(response);
                        this.data = JSON.parse(response);
                        console.log(this.data);
                        if (
                            this.data &&
                            this.data.payLoad &&
                            this.data.success
                        ) {
                            if (this.data.error != null) {
                                this.data.error.date = this.data.timeStamp;
                                this.store.dispatch(addError(this.data.error));
                                showWarning(this.data.error.message);
                            }
                            callback(this.data.payLoad);
                        } else {
                            this.data.error.date = this.data.timeStamp;
                            this.store.dispatch( addError( this.data.error ));
                            showError(this.data.error.message);
                            callback(false);
                        }
                    } else {
                        document["handler"].log("dataSource.getPaymentInfo().submitRequest << null");
                        this.store.dispatch(addError({
                            code: 0,
                            message: "An error occurred while getting the payment info.",
                            category: "Unknown error",
                            date: Date.now()
                        }));
                        showError("An unknown error occurred while getting the payment info.");
                        callback(false);
                    }
                }, false);
            }catch(e){
                document["handler"].error("DataSource.getLogs() -> "+e.message);
            }
        });
    }
}