/**
 * Loadest - Testing reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { TemplatesTypes } from "../variables/Types";
import ITemplate from "../interfaces/Template";
import { ITemplatesSummaryAction } from "../actions/templates";

interface ITemplatesState {
    templates: ITemplate[];
    loaded: boolean;
}

const initialState = (): ITemplatesState => ({
    templates: [],
    loaded: false,
});

const templates = (state: ITemplatesState = initialState(), action: ITemplatesSummaryAction): ITemplatesState => {
    switch (action.type) {
        case TemplatesTypes.UpdateTemplatesAction:
            return {
                ...state,
                templates: (action.payLoad.templates? action.payLoad.templates : []),
                loaded: action.payLoad.loaded,
            };
        default:
            return state;
    }
};

export { templates };