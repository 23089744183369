/**
 * Loadest - Dashboard reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { IDashboardSummaryAction, ITaskListItem } from '../actions/dashboard';
import { DashboardTypes } from "../variables/Types";
import { ITesting } from "../interfaces/Testing";

interface IDashboardState {
    listCount: number;
    list: ITaskListItem[];
    testing: ITesting;
    testings: number;
    templates: number;
    domains: number;
    loaded: boolean;
}

const initialState = (): IDashboardState => ({
    list: [],
    listCount: 0,
    testing: null,
    testings: 0,
    templates: 0,
    domains: 0,
    loaded: false,
});

const dashboard = (state: IDashboardState = initialState(), action: IDashboardSummaryAction): IDashboardState => {
    switch (action.type) {
        case DashboardTypes.UpdateListAction:
            return {
                ...state,
                list: (action.payLoad.tasks? action.payLoad.tasks : []),
                listCount: (action.payLoad.tasks && Array.isArray(action.payLoad.tasks) && action.payLoad.tasks.length > 0) ?
                    action.payLoad.tasks.length : 0
            };
        case DashboardTypes.ShowListCountAction:
            return {
                ...state,
                listCount: action.payLoad.count
            };
        case DashboardTypes.LoadDashboardAction:
            return {
                ...state,
                testing: action.payLoad,
                loaded: true,
            }
        default:
            return state;
    }
};

export { dashboard };