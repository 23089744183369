/**
 * Loadest - Application root component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { RefObject } from "react";
import { get } from "lodash";
import { Route, Switch } from "react-router-dom";
import cookie from 'react-cookies';
import Chat from "../../components/Chat/Chat";
import Dialog from "@material-ui/core/Dialog/Dialog";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import routes from "../../adminRoutes";
import NotificationSystem from "react-notification-system";
import { IAdminProps, IAdminState } from "./Admin.interface";
import notificationStyle from "../../variables/notificationStyle";
import isString from "../../functions/isString";
import Footer from "../../components/Footer/Footer";
import "./Admin.css";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import RightModal from "../../components/RightModal/RightModal";
import ConfirmDomain from "../../components/ConfirmDomain/ConfirmDomain";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../../components/Button/Button";
// @ts-ignore
import slide1 from "../../assets/img/1.jpg";
// @ts-ignore
import slide2 from "../../assets/img/2.jpg";
// @ts-ignore
import slide3 from "../../assets/img/3.jpg";


class AdminComponent extends React.Component<IAdminProps, IAdminState>{
    renderId: number;
    state: IAdminState;
    checkContentHeight: boolean;
    sidebarRef: RefObject<HTMLDivElement>;
    mainPanelRef: RefObject<HTMLDivElement>;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("AdminComponent.constructor()");
        this.renderId = 0;
        this.state = {
            color: "white",
            fixedClasses: "dropdown show-dropdown open",
            chatIsOpen: false,
            slide: 0,
        };
        window.addEventListener("keydown", (e) =>  {
            if(e.key === "Escape"){
                this.props.hideModals();
            }
        });
        this.sidebarRef = React.createRef<HTMLDivElement>();
        this.mainPanelRef = React.createRef<HTMLDivElement>();
        this.checkContentHeight = true;
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(){
        document["handler"].log("AdminComponent.componentDidMount()");
        // @ts-ignore
        this.props.initNotificationSystem(this.refs.notificationSystem.addNotification);
        this.forceUpdate();
        setTimeout(() => {
            this.setState({ slide: 1});
        }, 1000);
        window.onresize = (e) => {this.forceUpdate();}
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount() {
        document["handler"].log("AdminComponent.componentWillUnmount()");
        window.onresize = null;
        //window.onscroll = null;
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidCatch(error, errorInfo){
        document["handler"].error(error.toString()+' -> '+errorInfo.toString());
    }
    //------------------------------------------------------------------------------------------------------------------
    getRoutes = routes => {
        document["handler"].log("AdminComponent.getRoutes()");
        let minHeight = "100%";
        if(this.sidebarRef && this.sidebarRef.current && this.sidebarRef.current.clientHeight){
            minHeight = (this.sidebarRef.current.clientHeight-83)+"px";
        }
        return routes &&
            routes.length &&
            routes.map((prop, key) => {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        render={props => (
                            <prop.component
                                minHeight={minHeight}
                                updateParent={(checkContentHeight) => {
                                    this.checkContentHeight = checkContentHeight;
                                    this.forceUpdate();
                                }}
                                {...props}
                            />
                        )}
                        key={key}
                    />
                );
            });
    };
    //------------------------------------------------------------------------------------------------------------------
    getBrandText = path => {
        document["handler"].log("AdminComponent.getBrandText()");
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location &&
                this.props.location.pathname &&
                isString(this.props.location.pathname) &&
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        document["handler"].throw();
    };
    //------------------------------------------------------------------------------------------------------------------
    componentDidUpdate(e) {
        document["handler"].log("AdminComponent.componentDidUpdate("+e.history.action+")");
        try {
            if (
                window.innerWidth < 993 &&
                e.history.location.pathname !== e.location.pathname &&
                document.documentElement &&
                document.documentElement.className &&
                isString(document.documentElement.className) &&
                document.documentElement.className.indexOf("nav-open") !== -1
            ) {
                document.documentElement.classList.toggle("nav-open");
            }
            if (e.history.action === "PUSH") {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
                // @ts-ignore
                this.mainPanelRef.scrollTop = 0;
            }
        }catch(e){
            document["handler"].log("AdminComponent.componentDidUpdate -> "+e.message);
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("AdminComponent.render() №"+(++this.renderId));
        if (this.props.user.sid && this.props.user.sid !== cookie.load('sid')) {
            //@ts-ignore
            // window.location = "/login";
            window.alert(this.props.user.sid);
            return null;
        } else if (this.props.user.sid){
            let minHeight;
            if (this.sidebarRef && this.sidebarRef.current && this.sidebarRef.current.clientHeight) {
                minHeight = (this.sidebarRef.current.clientHeight);
                if (this.checkContentHeight) {
                    let content = document.getElementsByClassName("content")[0];
                    if (content && content.clientHeight > minHeight) {
                        minHeight = content.clientHeight + 80;
                    }
                }
                minHeight += "px";
            } else {
                minHeight = "100%";
            }
            this.checkContentHeight = true;
            return (
                <div className="wrapper">
                    <NotificationSystem ref="notificationSystem" style={notificationStyle}/>
                    <Sidebar refObject={this.sidebarRef} {...this.props} routes={routes} color={this.state.color}/>
                    <div id="main-panel" className="main-panel" ref={this.mainPanelRef} style={{height: minHeight}}>
                        <AdminNavbar
                            {...this.props}
                            brandText={
                                this.getBrandText(this.props.location.pathname)
                            }
                            logout={() => this.props.logout()}
                        />
                        <Switch>
                            {this.getRoutes(routes)}
                            <Route component={document["handler"].notFound()} />
                        </Switch>
                        <Footer  cookies={true} linearGradient={false} color="#333" />
                    </div>
                    {/*<Chat
                        isOpen={this.state.chatIsOpen}
                        onClick={() => {
                            this.setState({
                                chatIsOpen: !this.state.chatIsOpen
                            });
                        }}
                    />*/}
                    {
                        !this.props.user.instructed
                        && this.state.slide > 0
                        && this.state.slide < 4
                        && <Dialog
                            open={true}
                            keepMounted
                            aria-labelledby="classic-modal-slide-title"
                            aria-describedby="classic-modal-slide-description"
                        >
                            <img src={slide1} style={{ display: this.state.slide === 1 ? "block" : "none" }} />
                            <img src={slide2} style={{ display: this.state.slide === 2 ? "block" : "none" }} />
                            <img src={slide3} style={{ display: this.state.slide === 3 ? "block" : "none" }} />
                            {
                                this.state.slide === 1
                                && [
                                    <p style={{ padding: 20, paddingBottom: 0 }}>
                                        We are credit to you welcome bonus - 10 tokens, required for load testing.
                                        Basically, the price of a load testing token is 0.001 tokens per request.
                                    </p>
                                ]
                            }
                            {
                                this.state.slide === 2
                                && [
                                    <p style={{ padding: 20, paddingBottom: 0 }}>
                                        You can purchase tokens for one of 4 cryptocurrencies at the market price.
                                        Soon we will list the token on several cryptocurrency exchanges.
                                    </p>
                                ]
                            }
                            {
                                this.state.slide === 3
                                && [
                                    <p style={{ padding: 20, paddingBottom: 0 }}>
                                        We are also going to introduce a decentralized autonomous organization model
                                        soon to allow everybody to invest in and manage transparent service.
                                    </p>
                                ]
                            }
                            <DialogActions >
                                <Button
                                    onClick={() => {
                                        if (this.state.slide < 3) {
                                            this.setState({
                                                slide: this.state.slide + 1
                                            });
                                        } else {
                                            setTimeout(() => {
                                                document["dataSource"].confirmInstruct().then((res) => console.log(res));
                                            });
                                            this.setState({
                                                slide: this.state.slide + 1
                                            });
                                        }
                                    }}
                                    color="transparent"
                                    simple
                                >
                                    { this.state.slide === 3 ? "Get started" : "Next"}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                    {
                        this.props.modal &&
                        <CustomDialog
                            open={true}
                            caption={this.props.modalData.caption}
                            text={this.props.modalData.text}
                            close={() => this.props.hideModals()}
                            onOk={() => {
                                if (this.props.modalData.callback) {
                                    return this.props.modalData.callback();
                                } else {
                                    return false;
                                }
                            }}
                            cancel={true}
                        />
                    }
                    {
                        this.props.rightModal &&
                        <RightModal label={get(this.props, 'rightModalData.showRightModal.label', '')} />
                    }
                    {
                        this.props.domainConfirmation &&
                        this.props.domainConfirmation.display &&
                        <ConfirmDomain
                            domain={this.props.domainConfirmation.domain}
                            magic={this.props.magic}
                            onOk={() => this.props.domainConfirmation.onOk()}
                            onClose={() => this.props.domainConfirmation.onClose()}
                        />
                    }
                </div>
            );
        }
    }
}

export default AdminComponent;
