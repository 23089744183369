/**
 * Loadest - Admin container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import cookie from 'react-cookies'
import Admin from "./Admin.component";
import * as actions from "../../actions";
import {INotification} from "./Admin.interface";
import {logoutUser} from "../../actions/user";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        modalData: state.main.modalData,
        modal: state.main.modal,
        rightModalData: state.main.rightModalData,
        rightModal: state.main.rightModal,
        user: state.user,
        magic: state.user.magic,
        domainConfirmation: state.main.domainConfirmation
    }
}
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        initNotificationSystem: (addNotification:INotification) => {
            dispatch(
                actions.addNotificationSystem(addNotification)
            )
        },
        scroll: (scroll:number): void => {
            dispatch(
                actions.scrollWindow(scroll)
            )
        },
        hideModals: (): void =>{
            dispatch(
                actions.hideModal()
            );
            document.body.style.overflow = "auto";
        },
        logout: (): void => {
            cookie.remove('sid', { path: '/' });
            try {
                localStorage.removeItem("sid");
            } catch (e) { /* ... */ }
            dispatch(logoutUser());
        }
    }
}
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
