/**
 * Loadest - CustomDialog Component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "../Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ICustomDialogProps from "./CustomDialog.props";
import "./CustomDialog.css";

const CustomDialog: React.FunctionComponent<ICustomDialogProps> = (props:ICustomDialogProps) => {
    return (
        <Dialog
            open={props.open}
            keepMounted
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >

            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
            >
                <div className="CustomDialog">
                    <h1>{ props.caption }</h1>
                    {
                        props.text &&
                        (
                            <>
                                <br/>
                                    { props.text }
                                <br />
                            </>
                        )
                    }
                </div>
            </DialogTitle>
            <DialogActions >
                <Button
                    onClick={() => {
                        if (props.onOk) {
                            if (props.onOk()) {
                                props.close();
                            }
                        } else {
                            props.close();
                        }
                    }}
                    color="transparent"
                    simple
                >
                    Ok
                </Button>

                {
                    props.cancel &&
                    <Button
                        onClick={() => props.close()}
                        color="danger"
                        simple
                    >
                        Cancel
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;