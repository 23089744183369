/**
 * Loadest - RightModal container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import RightModal from "./RightModal.component";
import * as actions from "../../actions";
import {hideModal, reloadTemplatesList, reloadTestingList, showModal} from "../../actions";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        content: state.main.rightModalData,
        templates: state.templates.templates,
        template_id: state.main.rightModalData.showRightModal.template_id,
        action: state.main.rightModalData.showRightModal.action,
        magic: state.user.magic,
        username: state.user.name,
        email: state.user.email,
    }
}
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        hideRightModal: () => {
            dispatch(
                actions.hideRightModal()
            );
            document.body.style.overflow = "auto";
        },
        confirm: (caption, text, callback) => {
            dispatch(
                showModal(caption, text, callback)
            );
        },
        reloadTestings: () => {
            dispatch(reloadTestingList());
            setTimeout(() => document["dataSource"].getTestings(), 300);
        },
        reloadTemplates: () => {
            dispatch(reloadTemplatesList());
            setTimeout(() => document["dataSource"].getTemplates(), 300);
        },
        hideModal: () => {
            dispatch(hideModal());
        },
        showDomainConfirmation: (domain, callback) => {
            dispatch(
                actions.showDomainConfirmation(
                    domain,
                    callback,
                    () => {
                        dispatch(actions.hideDomainConfirmation())
                    }
                )
            )
        }
    }
}
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightModal));
