/**
 * Loadest - Application loader.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

class AppLoader {
    load_state: number;
    is_dom_loaded: boolean;
    target_state: number;
    root_opacity: number;
    load_interval: number;
    handlers: (() => void)[];
    timeout: number;
    //------------------------------------------------------------------------------------------------------------------
    constructor() {
        document["handler"].log("AppLoader.constructor()");
        this.load_state = 0;
        this.is_dom_loaded = false;
        this.target_state = 2;
        this.root_opacity = 0.0;
        this.load_interval = null;
        this.handlers = [];
        this.timeout = window.setTimeout(() => {
            window.location.reload();
        }, 21000);
    }
    //------------------------------------------------------------------------------------------------------------------
    addHandler(handler) {
        this.handlers.push(handler)
    }
    //------------------------------------------------------------------------------------------------------------------
    load(sender) {
        document["handler"].log("AppLoader.load("+sender+")");
        try {
            if (sender === "window") {
                this.is_dom_loaded = true;
            }
            if (this.load_state < this.target_state) {
                if (this.load_state + 1 >= this.target_state) {
                    clearTimeout(this.timeout);
                    const func = () => {
                        document["handler"].log("AppLoader.func()");
                        const el = document.getElementById("react");
                        if (el) {
                            el.style.display = "block";
                            setTimeout(() => {
                                this.load_interval = window.setInterval(() => {
                                    this.root_opacity += 0.03;
                                    document.getElementById("root").style.opacity = "" + (this.root_opacity);
                                    if (this.root_opacity >= 1) {
                                        if (document.getElementById("root").childNodes.length > 0 &&
                                            // @ts-ignore
                                            document.getElementById("root").childNodes[0].className === "wrapper") {
                                            document.body.style.background = "#fff";
                                            document.getElementById("root").style.background = "#fff";
                                        }
                                        clearInterval(this.load_interval);
                                        setTimeout(() => {
                                            try {
                                                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                                                    // @ts-ignore
                                                    m[i].l=1*new Date();
                                                    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                                                    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                                                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                                                // @ts-ignore
                                                ym(68174386, "init", {
                                                    clickmap:true,
                                                    trackLinks:true,
                                                    accurateTrackBounce:true,
                                                    webvisor:true
                                                });
                                            } catch (e) {}
                                            this.handlers.forEach(handler => {
                                                handler.call(this);
                                            })
                                        }, 1000);
                                    }
                                }, 1);
                            }, 300);
                        } else {
                            setTimeout(() => func(), 1000);
                        }
                    }
                    func();
                } else this.load_state++;
            }
        }catch(e){
            document["handler"].error("AppLoader.load() -> "+e.message);
        }
    }
}

export default AppLoader;
