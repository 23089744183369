/**
 * Loadest - Redux container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import StateHandler from "./stateHandler";
import Reducer from "./reducers/main";

const reduxStore = () => {
    return createStore(Reducer, composeWithDevTools(applyMiddleware(StateHandler)));
}

export default reduxStore;