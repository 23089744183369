/**
 * Loadest - User actions.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {Action} from 'redux';
import {IUser} from "../interfaces/User";
import {UserTypes} from "../variables/Types";
//----------------------------------------------------------------------------------------------------------------------
interface ILoadUserInfoAction extends Action {
    payLoad: IUser
}
const loadUserInfo = (data: IUser):ILoadUserInfoAction => ({
    type: UserTypes.LoadUserInfoAction,
    payLoad: data
});
//----------------------------------------------------------------------------------------------------------------------
const logoutUser = ():Action => ({
   type: UserTypes.LogoutUserAction,
});
//----------------------------------------------------------------------------------------------------------------------
type IUserSummaryAction =
    ILoadUserInfoAction;
//----------------------------------------------------------------------------------------------------------------------
export {
    ILoadUserInfoAction,
    IUserSummaryAction,
    loadUserInfo,
    logoutUser,
};
