/**
 * Loadest - Dashboard container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {withRouter} from "react-router";
import {connect} from "react-redux";
import Dashboard from "./Dashboard.component";
import * as actions from "../../actions";
import showError from "../../functions/showError";
import * as React from "react";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        tasks: state.dashboard.list,
        testing: state.dashboard.testing,
        testings: state.dashboard.testings,
        templates: state.dashboard.templates,
        domains: state.dashboard.domains,
        templateList: state.templates.templates,
        templatesLoaded: state.templates.loaded,
        loaded: state.testing.loaded,
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        load: (callback) => {
            document["dataSource"].getDashboard().then(res => callback(res));
        },
        loadTemplates: (callback) => {
            document["dataSource"].getTemplates().then((result) => {
                setTimeout(callback, 300, result);
            });
        },
        newTest: (templates) => {
            if (templates.length) {
                dispatch(
                    actions.showModal(
                        "New load testing",
                        (
                            <div style={{
                                width: window.innerWidth > 400 ? "320px" : "auto",
                                textAlign: "left",
                                padding: "20px",
                                lineHeight: "1.5"
                            }}>
                                Testing label
                                <br/>
                                <input
                                    id="testing_label"
                                    type="text"
                                    style={{
                                        width: "100%",
                                        padding: "7px"
                                    }}
                                    onChange={e => {
                                        try {
                                            e.target.style.border = "#c0c0c0 1px solid";
                                        } catch(e) { /*...*/ }
                                    }}
                                />
                                <br/>
                                {
                                    templates.length > 0 &&
                                    <>
                                        <br/>
                                        Template
                                        <br/>
                                        <select
                                            style={{
                                                width: "100%",
                                                padding: "7px"
                                            }}
                                            id="templateSelect"
                                        >
                                            <option selected disabled>Not selected</option>
                                            {
                                                templates.map(item => {
                                                    return <option value={ item.id }>{ item.label }</option>
                                                })
                                            }
                                        </select>
                                    </>
                                }

                            </div>
                        ),
                        () => {
                            const el = document.getElementById("testing_label");
                            let templateId = 0;
                            try {
                                templateId = parseInt(document.getElementById("templateSelect")["value"])
                            } catch(e) { /* ... */ }
                            // @ts-ignore
                            if (el.value) {
                                dispatch(
                                    actions.showRightModal({
                                        label: el["value"].trim(),
                                        template_id: templateId,
                                        action: "new_testing"
                                    })
                                );
                                return true;
                            } else {
                                try {
                                    const el = document.getElementById("testing_label");
                                    el.style.border = "#f66 1px solid";
                                    el.focus();
                                } catch(e) { /*...*/ }
                                showError("Label is not defined");
                                return false;
                            }
                        }
                    )
                )
            } else {
                dispatch(
                    actions.showRightModal({
                        template_id: 0,
                        action: "new_testing"
                    })
                );
                return true;
            }
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
