/**
 * Loadest - GridItem Props.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import PropTypes from "prop-types";

const GridItemDefaultProps = {
    className: "",
    style: {}
};

const GridItemPropTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    style: PropTypes.object,
};

export { GridItemDefaultProps, GridItemPropTypes }
