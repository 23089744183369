/**
 * Loadest - HeaderLinks container.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import { withRouter } from "react-router";
import { connect } from "react-redux";
import cookie from 'react-cookies'
import HeaderLinks from "./HeaderLinks.component";
import { logoutUser } from "../../actions/user";
//----------------------------------------------------------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
//----------------------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        logout: (): void => {
            cookie.remove('sid', { path: '/' });
            try {
                localStorage.removeItem("sid");
            } catch (e) { /* ... */ }
            dispatch(logoutUser());
        }
    }
};
//----------------------------------------------------------------------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderLinks));