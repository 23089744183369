/**
 * Loadest - ErrorsTable component.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import * as React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import { Card } from "../Card/Card";
import { ILogsTableProps } from "./LogsTable.props";
import "./LogsTable.css";
import Button from "../Button/Button";
// @ts-ignore
import spinner from "../../assets/img/load.gif";

class LogsTableComponent extends React.Component<ILogsTableProps, { logs: any[], loaded: boolean }>{
    renderId: number;
    tableHead: {};
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("NodesTableComponent.constructor()");
        this.renderId = 0;
        this.tableHead = {
            act: "",
            date: "Date",
            action: "Action",
            message: "Message",
        }
        this.state = {
            loaded: false,
            logs: []
        };
    }
    //------------------------------------------------------------------------------------------------------------------
    load():void {
        document["dataSource"].getLogs().then(results => {
            setTimeout(() => {
                if (results.length) {
                    this.setState({
                        logs: results,
                        loaded: true
                    });
                } else {
                    this.setState({
                        loaded: false
                    });
                }
            }, 300);
        })
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        if (this.state.loaded) {
            this.setState({
                loaded: false
            });
        }
        this.load();
    }
    //------------------------------------------------------------------------------------------------------------------
    print(){
        document["handler"].log("NodesTableComponent.print()");
        let fout = [];
        this.state.logs &&
        this.state.logs.length &&
        this.state.logs.forEach((prop, key) => {
            let date = new Date(prop.date);
            let log_date = date.toLocaleString();
            fout.push (
                <tr key={key}>
                    <td style={{ width: "80px" }}>
                        <div
                            className="iconBtn materialIconBtn"
                            onClick={() => {
                                document["dataSource"].deleteLog(prop.id).then(result => {
                                    if (result) {
                                        this.load();
                                    }
                                })
                            } }
                            title="Delete entity"
                        >
                            <DeleteOutlined />
                        </div>
                    </td>
                    <td>{log_date}</td>
                    <td title={prop.node_id ? prop.node_id : null}>{prop.action}</td>
                    <td>{
                        (
                            prop.action === "request" ||
                            prop.action === "new test" ||
                            prop.action === "express test"
                        ) ?
                            <a target="_blank" href={prop.message.indexOf("http") === 0 ? prop.message : "http://"+prop.message}>{
                                prop.message.replace('http://', '').replace('https://', '')
                            }</a> : prop.message
                    }</td>
                </tr>
            );
        });
        return fout;
    }
    //------------------------------------------------------------------------------------------------------------------
    render(){
        document["handler"].log("NodesTableComponent.render() №"+(++this.renderId));
        let tHead = [];
        let body = this.print();
        for (let thKey in this.tableHead) {
            let arrow = '';
            if(thKey === this.props.sortKey){
                if(this.props.sortMethod){
                    arrow = " \u2193";
                }else{
                    arrow = " \u2191";
                }
            }
            tHead.push(
                <th key={thKey}><span /* className="link" onClick={() => {
                    document["handler"].info("NodesTableComponent.th("+this.tableHead[thKey]+").click(method="+!(this.props.sortMethod)+")");
                    this.props.sort(thKey, !(this.props.sortMethod));
                }} */>{this.tableHead[thKey]}</span>{arrow}</th>
            )
        }
        return(
            <Grid fluid className="LogsTable">
                <Row>
                    <Col md={10} mdOffset={1}>
                        <Card
                            plain
                            title="Logs"
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <>
                                    <Button
                                        color="primary"
                                        round
                                        className="btn newTest"
                                        onClick={e => {
                                            this.componentDidMount();
                                        }}
                                    >
                                        <i className="pe-7s-refresh" style={{
                                            fontWeight: "bold",
                                            paddingLeft: "3px",
                                            paddingRight: "7px"
                                        }}/>
                                    </Button>
                                    <br/>

                                    <div style={{ overflow: "auto" }}>
                                    {
                                        this.state.loaded ? (
                                            body.length?
                                            (
                                                <Table hover>
                                                    <thead>
                                                    <tr>{ tHead }</tr>
                                                    </thead>
                                                    <tbody>{ body }</tbody>
                                                </Table>
                                            ):(
                                                <div className="notFound">
                                                    <i className="pe-7s-attention emptyState"/>
                                                    <br/>
                                                        Logs not found.
                                                    <br/>
                                                </div>
                                            )
                                        ) : (
                                            <div className="loader app-loader">
                                                <img height={150} src={spinner} />
                                            </div>
                                        )
                                    }
                                    </div>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default LogsTableComponent;
