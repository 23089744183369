/**
 * Loadest - Signup page.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import React from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import {
    ISignupPageProps,
    ISignupPageState
} from "./SignupPage.interface";
import "./SignupPage.css";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/HeaderLinks/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/GridItem/GridItem";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import Footer from "../../components/Footer/Footer";
import isValidEmail from "../../functions/isValidEmail";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
// @ts-ignore
import image from "../../assets/img/background.jpeg";
// @ts-ignore
import spinner from "../../assets/img/load.gif";


class SignupPage extends React.Component<ISignupPageProps, ISignupPageState> {
    renderId: number;
    onKeyPress: (e:any) => void;
    isLoaded: boolean;
    //------------------------------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        document["handler"].log("SignupPage.constructor()");
        this.renderId = 0;
        this.isLoaded = false;
        this.state = {
            dialog: "",
            loading: false,
            name: "",
            email: "",
            pass: ""
        };
        this.onKeyPress = function(e) {
            document["handler"].log(`SignupPage.onKeyPress(${e.code})`);
            if (
                (e.code === "Enter" || e.code === "NumpadEnter") &&
                window.location.href.indexOf("/signup") >= 0
            ) {
                try {
                    this.onSignUp();
                } catch (e) { /* ... */ }
            }
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentDidMount(): void {
        document["handler"].log("SignupPage.componentDidMount()");
        if (document["reduxStore"].getState().user.sid) {
            if (document["reduxStore"].getState().user.confirmed) {
                this.props.history.push('/app/dashboard');
            } else {
                this.props.history.push('/confirmation');
            }
        } else {
            setTimeout(() => document.title = 'Sign Up', 1);
            try {
                if (window.innerWidth >= 768) {
                    const el = document.getElementById("name");
                    if (el) {
                        el.focus();
                    }
                }
            } catch (e) { /* ... */ }
            document.addEventListener('keypress', (e) => this.onKeyPress(e), false);
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    componentWillUnmount(): void {
        document["handler"].log("SignupPage.componentWillUnmount()");
        this.onKeyPress = e => {};
    }
    //------------------------------------------------------------------------------------------------------------------
    setDialog(text:string): void {
        document["handler"].log(`SignupPage.setDialog(${text})`);
        this.setState({
            ...this.state,
            dialog: text,
            loading: this.state.loading
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    setLoading(value:boolean): void {
        document["handler"].log(`SignupPage.setDialog(${value})`);
        this.setState({
            ...this.state,
            dialog: this.state.dialog,
            loading: value
        });
    }
    //------------------------------------------------------------------------------------------------------------------
    onSignUp() {
        document["handler"].log(`SignupPage.onSignUp()`);
        const name_el = document.getElementById("name");
        const email_el = document.getElementById("email");
        const pass_el = document.getElementById("pass");
        const name = this.state.name.trim();
        const email = isValidEmail(this.state.email.trim()) ? this.state.email.trim().toLowerCase() : false;
        const pass = this.state.pass.trim().toLowerCase();
        let flag = false;
        if (!name) {
            flag = true;
            try {
                // @ts-ignore
                name_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
            if (!email) {
                try {
                    // @ts-ignore
                    email_el.parentNode.style.background = "rgba(255,0,0,0.1)";
                } catch(e) { /*...*/ }
                if (!pass) {
                    try {
                        // @ts-ignore
                        pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
                    } catch(e) { /*...*/ }
                    this.setDialog('Invalid name, password and email address');
                } else {
                    this.setDialog('Invalid name and email address');
                }
            } else if (!pass) {
                try {
                    // @ts-ignore
                    pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
                } catch(e) { /*...*/ }
                this.setDialog('Invalid name and password');
            } else {
                this.setDialog('Invalid user name');
            }
        } else if (!email) {
            try {
                // @ts-ignore
                email_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
            flag = true;
            if (!pass) {
                try {
                    // @ts-ignore
                    pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
                } catch(e) { /*...*/ }
                this.setDialog('Invalid password and email address');
            } else {
                this.setDialog('Invalid email address');
            }
        } else if (!pass) {
            try {
                // @ts-ignore
                pass_el.parentNode.style.background = "rgba(255,0,0,0.1)";
            } catch(e) { /*...*/ }
            flag = true;
            this.setDialog('Invalid password');
        }
        if (!flag) {
            this.setLoading(true);
            document["dataSource"].signupUser(name, email, pass, this.setDialog.bind(this)).then(result => {
            if (result) {
                if (!document["initInterval"]) {
                    document["initInterval"] = window.setInterval(() => document["dataSource"].initData(), 30000);
                }
                this.props.history.push('/app/dashboard');
            } else {
                setTimeout(() => {
                    this.setLoading(false);
                }, 1000);
            }
            });
        }
    }
    //------------------------------------------------------------------------------------------------------------------
    render() {
        document["handler"].log("SignupPage.render() №" + (++this.renderId));
        if (!this.isLoaded) {
            this.isLoaded = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 1);
        }
        const { classes, ...rest } = this.props;
        return (
            <div className="signupPage">
                <Header
                    absolute
                    color="transparent"
                    brand={
                        <div className="siteLogo">
                            <i className="pe-7s-gleam siteIcon" />
                            <div className="siteName">
                                loadest
                            </div>
                        </div>
                    }
                    rightLinks={<HeaderLinks />}
                    {...rest}
                />
                <div
                    className={classes.pageHeader + " pageHeader"}
                    style={{ backgroundImage: "url(" + image + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6} style={{ maxWidth: "360px" }}>
                                <Card content={
                                    <form className={classes.form}>
                                        <h1 className={classes.loginCaption}>Sign Up</h1>
                                        {
                                            this.state.loading &&
                                            <div className="loader">
                                                <img src={spinner} height={150} />
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>
                                                <CustomInput
                                                    defaultValue=""
                                                    labelText="Name"
                                                    id="name"
                                                    value={this.state.name}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <People className={classes.inputIconsColor}/>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            name: e.target.value
                                                        });
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Email"
                                                    id="email"
                                                    defaultValue=""
                                                    value={this.state.email}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "email",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Email className={classes.inputIconsColor}/>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            email: e.target.value,
                                                        })
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText="Password"
                                                    id="pass"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    defaultValue=""
                                                    value={this.state.pass}
                                                    inputProps={{
                                                        type: "password",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LockOutlined className={classes.inputIconsColor}/>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            pass: e.target.value
                                                        })
                                                        try {
                                                            e.target.parentNode.style.background = "transparent";
                                                        } catch(e) { /*...*/ }
                                                    }}
                                                />
                                                <p className="conditions">
                                                    By registering you confirm that you are familiar and agree with&nbsp;
                                                    <NavLink
                                                        onClick={() => {
                                                            window.scrollTo(0,0);
                                                        }}
                                                        to="/terms_and_conditions"
                                                        style={{
                                                            textDecoration: "underline"
                                                        }}
                                                    >
                                                        Terms & Conditions
                                                    </NavLink>
                                                </p>
                                                <div className="getStarted">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        onClick={() => this.onSignUp()}
                                                        disabled={
                                                            !this.state.email ||
                                                            !this.state.name ||
                                                            !this.state.pass
                                                        }
                                                    >
                                                        Get started!
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                }
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="h100" />
                    <Footer  cookies={true} linearGradient={false} whiteFont={true} />
                </div>
                {
                    this.state.dialog !== "" &&
                    <CustomDialog
                        open={true}
                        caption="Error"
                        text={this.state.dialog}
                        close={() => this.setDialog("")}
                    />
                }
            </div>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(SignupPage);
