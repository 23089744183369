/**
 * Loadest - Primary reducer.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

import {combineReducers, Reducer} from 'redux';
import {user} from './user';
import {dashboard} from './dashboard';
import {logs} from './logs';
import {testing} from './testing';
import {domains} from './domains';
import {nodes} from './nodes';
import {MainSummaryAction} from "../actions/index";
import {MainTypes} from "../variables/Types";
import {templates} from "./templates";
//----------------------------------------------------------------------------------------------------------------------
interface IWideModalData{
    id: number;
    data: any;
}
//----------------------------------------------------------------------------------------------------------------------
interface IAppState {
    addNotification: any,
    modal: boolean,
    modalData: any,
    rightModal: boolean,
    rightModalData: any,
    domainConfirmation: {
        display: boolean,
        domain: string,
        onOk: () => void,
        onClose: () => void,
    }
}
//----------------------------------------------------------------------------------------------------------------------
const initialState = (): IAppState => ({
    addNotification: null,
    modal: false,
    modalData: null,
    rightModal: false,
    rightModalData: null,
    domainConfirmation: {
        display: false,
        domain: "",
        onOk: null,
        onClose: null
    }
});
//----------------------------------------------------------------------------------------------------------------------
const main = function (state: IAppState = initialState(), action: MainSummaryAction) {
    const payLoad = action.payLoad;
    switch (action.type) {
        case MainTypes.AddNotificationSystem:
            return {
                ...state,
                addNotification: payLoad.addNotification
            };
        case MainTypes.ShowModalAction:
            return {
                ...state,
                modalData: payLoad,
                modal: true
            };
        case MainTypes.HideModalAction:
            return {
                ...state,
                modalData: null,
                modal: false
            };
        case MainTypes.ShowRightModalAction:
            return {
                ...state,
                rightModal: true,
                rightModalData: payLoad,
            };
        case MainTypes.HideRightModalAction:
            return {
                ...state,
                rightModal: false,
                rightModalData: null
            };
        case MainTypes.ShowDomainConfirmationAction:
            return {
                ...state,
                domainConfirmation: action.payLoad
            }
        case MainTypes.HideDomainConfirmationAction:
            return {
                ...state,
                domainConfirmation: {
                    ...state.domainConfirmation,
                    display: false,
                }
            }
        default:
            return state;
    }
};
//----------------------------------------------------------------------------------------------------------------------
const rootReducer: Reducer = combineReducers(
    {
        main,
        user,
        dashboard,
        testing,
        errors: logs,
        templates,
        domains,
        nodes
    }
);
//----------------------------------------------------------------------------------------------------------------------
export {
    rootReducer as default,
    IAppState,
    IWideModalData
};