/**
 * Loadest - Actions and reducers enums.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

const MainTypes = {
    ShowModalAction: "ShowModalAction",
    OnScrollAction: "OnScrollAction",
    AddNotificationSystem: "AddNotificationSystem",
    AddError: "AddError",
    ShowWideModalAction: "ShowWideModalAction",
    HideModalAction: "HideModalAction",
    ShowRightModalAction: "ShowRightModalAction",
    HideRightModalAction: "HideRightModalAction",
    ShowDomainConfirmationAction: "ShowDomainConfirmationAction",
    HideDomainConfirmationAction: "HideDomainConfirmationAction"
};
//----------------------------------------------------------------------------------------------------------------------
const DashboardTypes = {
    UpdateListAction: "UpdateListAction",
    ShowListCountAction: "ShowListCountAction",
    LoadDashboardAction: "LoadDashboardAction"
};
//----------------------------------------------------------------------------------------------------------------------
const TestingTypes = {
    UpdateTestingAction: "UpdateTestingAction"
};
//----------------------------------------------------------------------------------------------------------------------
const TemplatesTypes = {
    UpdateTemplatesAction: "UpdateTemplatesAction",
};
//----------------------------------------------------------------------------------------------------------------------
const DomainsTypes = {
    UpdateDomainsAction: "UpdateDomainsAction",
};
//----------------------------------------------------------------------------------------------------------------------
const UserTypes = {
    LoadUserInfoAction: "LoadUserInfoAction",
    LogoutUserAction: "LogoutUserAction"
};
//----------------------------------------------------------------------------------------------------------------------
const ModalTypes = {
    EmptyState: "EmptyState",
};
//----------------------------------------------------------------------------------------------------------------------
const LogTypes = {
    AddLog: "AddLog",
    InitLogsAction: "InitLogsAction",
    UpdateLogsSearchPhraseAction: "UpdateLogsSearchPhraseAction",
    SortLogsAction: "SortLogsAction",
    getLogsAction: "getLogsAction"
};
//----------------------------------------------------------------------------------------------------------------------
const NodeTypes = {
    UpdateNodesAction: "UpdateNodesAction",
    InitNodesAction: "InitNodesAction",
    getNodesAction: "getLogsAction"
};
//----------------------------------------------------------------------------------------------------------------------
export {
    MainTypes,
    UserTypes,
    DashboardTypes,
    TestingTypes,
    ModalTypes,
    LogTypes,
    TemplatesTypes,
    DomainsTypes,
    NodeTypes
};